import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';
import networkProfile from '../../assets/img/networkProfile.png';

// Define data structure
const data = {
  name: 'Root',
  imageUrl: networkProfile,
  children: [
    {
      name: 'Node 1',
      color: '#D02670',
      children: [
        { name: 'child 1', imageUrl: networkProfile, color: '#D02670' },
        { name: 'child 2', imageUrl: networkProfile, color: '#D02670' },
        { name: 'child 3', imageUrl: networkProfile, color: '#D02670' },
      ],
    },
    {
      name: 'Node 2',
      color: '#009D9A',
      children: [
        { name: 'child 4', imageUrl: networkProfile, color: '#009D9A' },
        { name: 'child 5', imageUrl: networkProfile, color: '#009D9A' },
        { name: 'child 6', imageUrl: networkProfile, color: '#009D9A' },
      ],
    },
    {
      name: 'Node 3',
      color: '#EB6200',
      children: [
        { name: 'child 7', imageUrl: networkProfile, color: '#EB6200' },
        { name: 'child 8', imageUrl: networkProfile, color: '#EB6200' },
        { name: 'child 9', imageUrl: networkProfile, color: '#EB6200' },
      ],
    },
    {
      name: 'Node 4',
      color: '#0E62FE',
      children: [
        { name: 'child 10', imageUrl: networkProfile, color: '#0E62FE' },
        { name: 'child 11', imageUrl: networkProfile, color: '#0E62FE' },
        { name: 'child 12', imageUrl: networkProfile, color: '#0E62FE' },
      ],
    },
    {
      name: 'Node 5',
      color: '#D2A106',
      children: [
        { name: 'child 13', imageUrl: networkProfile, color: '#D2A106' },
        { name: 'child 14', imageUrl: networkProfile, color: '#D2A106' },
        { name: 'child 15', imageUrl: networkProfile, color: '#D2A106' },
      ],
    },
    {
      name: 'Node 6',
      color: '#893FFC',
      children: [
        { name: 'child 16', imageUrl: networkProfile, color: '#893FFC' },
        { name: 'child 17', imageUrl: networkProfile, color: '#893FFC' },
        { name: 'child 18', imageUrl: networkProfile, color: '#893FFC' },
      ],
    },
  ],
};
const RadialTreeChart = () => {
  const svgRef = useRef(null);

  useEffect(() => {
    // Clear any existing SVG content
    d3.select(svgRef.current).selectAll('*').remove();

    // Set up dimensions
    const width = 600;
    const height = 600;
    const radius = Math.min(width, height) / 2 - 70; // Reduced radius to accommodate labels

    // Create SVG container
    const svg = d3
      .select(svgRef.current)
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${width / 2},${height / 2})`);

    const defs = svg.append('defs');

    // Create the tree layout
    const tree = d3
      .tree()
      .size([2 * Math.PI, radius])
      .separation((a, b) => {
        // Increase base separation
        const baseSeparation = a.parent === b.parent ? 2 : 3;
        return baseSeparation / a.depth;
      });

    // Generate the tree data
    const root = d3.hierarchy(data);
    tree(root);

    // Create straight links
    // const link =
    svg
      .append('g')
      .attr('fill', 'none')
      .selectAll('line')
      .data(root.links())
      .join('line')
      .attr('stroke-width', 2)
      .attr('stroke-dasharray', '2,2')
      .attr('stroke', (d) => d?.source?.data?.color || '#000')
      .attr('x1', (d) => Math.cos(d.source.x - Math.PI / 2) * d.source.y)
      .attr('y1', (d) => Math.sin(d.source.x - Math.PI / 2) * d.source.y)
      .attr('x2', (d) => Math.cos(d.target.x - Math.PI / 2) * d.target.y)
      .attr('y2', (d) => Math.sin(d.target.x - Math.PI / 2) * d.target.y);

    // Create nodes
    const node = svg
      .append('g')
      .selectAll('g')
      .data(root.descendants())
      .join('g')
      .attr('transform', (d) => {
        const extraSpacing = d.height === 0 ? 20 : 0;
        return `
        rotate(${(d.x * 180) / Math.PI - 90})
        translate(${d.y + extraSpacing}, 0)
      `;
      });

    // Create a clip path for each node
    node.each(function (d, i) {
      const radius = d.depth === 0 ? 40 : d.depth === 1 ? 40 : 20;
      defs
        .append('clipPath')
        .attr('id', `clip-${i}`)
        .append('circle')
        .attr('r', radius);
    });

    // Add circles to nodes
    node
      .append('circle')
      .attr('fill', (d, i) => d?.data?.color || 'white')
      .attr('stroke', (d, i) => d?.data?.color || 'white')
      .attr('stroke-width', 4)
      .style('opacity', 1)
      .attr('r', (d) => (d.depth === 0 ? 40 : d.depth === 1 ? 40 : 20));

    // Add images and labels
    node.each(function (d, i) {
      const group = d3.select(this);

      if (d.data.imageUrl) {
        // Add image
        group
          .append('image')
          .attr('x', (d) => (d?.depth === 0 ? -40 : d?.depth === 1 ? -40 : -20))
          .attr('y', (d) => (d?.depth === 0 ? -40 : d?.depth === 1 ? -40 : -20))
          .attr('width', (d) =>
            d?.depth === 0 ? 80 : d?.depth === 1 ? 80 : 40
          )
          .attr('height', (d) =>
            d?.depth === 0 ? 80 : d?.depth === 1 ? 80 : 40
          )
          .attr('clip-path', `url(#clip-${i})`)
          .attr('href', d?.data?.imageUrl)
          .attr('preserveAspectRatio', 'xMidYMid slice')
          .attr('transform', (d) => `rotate(${90 - (d?.x * 180) / Math.PI})`);

        // Add text box for leaf nodes (depth 2)
        if (d?.depth === 2) {
          // Add white background rectangle
          // const padding = 4;
          const boxHeight = 20;
          group
            .append('rect')
            .attr('x', -40)
            .attr('y', 15)
            .attr('width', 80)
            .attr('height', boxHeight)
            .attr('fill', (d, i) => d?.data?.color || 'white')
            .attr('stroke', (d, i) => d?.data?.color || 'black')
            .attr('stroke-width', 1)
            .attr('z-index', 0)
            .attr('rx', 5)
            .style('opacity', 0.9)

            .attr('transform', (d) => `rotate(${90 - (d.x * 180) / Math.PI})`);

          // Add text
          group
            .append('text')
            .attr('class', 'node-label')
            .attr('text-anchor', 'middle')
            .attr('x', 0)
            .attr('y', 15 + boxHeight / 2 + 5)
            .attr('transform', (d) => `rotate(${90 - (d?.x * 180) / Math.PI})`)
            .style('font-size', '12px')
            .style('fill', '#fff')
            .text(d?.data?.name);
        }
      } else {
        // Add text for nodes without images
        group
          .append('text')
          .attr('class', 'circle-path-center-label')
          .style('text-anchor', 'middle')
          .style('font-weight', 'bold')
          .style('fill', '#fff')
          .style('font-size', (d) =>
            d?.depth === 0 ? 13 : d?.depth === 1 ? 12 : 9
          )
          .attr('dy', '.35em')
          .attr('transform', (d) => `rotate(${90 - (d?.x * 180) / Math.PI})`)
          .text(d?.data?.name);
      }
    });

    // Add hover effects
    node
      .on('mouseover', function (event, d) {
        d3.select(this).select('circle').attr('stroke-width', 6);
      })
      .on('mouseout', function (event, d) {
        d3.select(this).select('circle').attr('stroke-width', 4);
      });

    // // Add zoom capability
    // const zoom = d3
    //   .zoom()
    //   .scaleExtent([0.5, 2])
    //   .on('zoom', (event) => {
    //     svg.attr(
    //       'transform',
    //       `translate(${width / 2 + event.transform.x}, ${
    //         height / 2 + event.transform.y
    //       }) scale(${event.transform.k})`
    //     );
    //   });

    // d3.select(svgRef.current).call(zoom);
  }, []); // Added networkProfile to dependency array

  return (
    <div className="w-full h-full flex justify-center items-center">
      <svg ref={svgRef} className="w-full max-w-4xl"></svg>
    </div>
  );
};

export default RadialTreeChart;
