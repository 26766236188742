import { useMutation, useQuery } from '@tanstack/react-query';
import { get } from '../service';
import { API } from '../constants';
import { objectToQueryString } from './useSearch';
import { addCountPrefix, calculatePercentage } from '../constants/utils';
import { networkClusterMapData } from './data/advancedDashboardData';
import { sentimentChartMapData as tempSentimentChartMapData } from './data/chartData';

import { topThemeChartMapData as TempTopThemeChartMapData } from './data/advancedSocialCampaign';
import {
  blueColorGradients,
  greenColorGradients,
  yellowColorGradients,
} from '../constants/graph-colors';

const getNetworkClusterData = async (payload) => {
  const queryString = `${objectToQueryString({
    ...payload,
  })}`;

  try {
    const {
      data: response,
      isSuccessful,
      message,
    } = await get(
      `${API}/dashboard-influencer/network-map-chart?${queryString}`,
      {}
    );

    if (!isSuccessful) {
      return {
        data: {
          title: 'NetworkMap',
          shouldShowGraph: false,
          summary: { value: '0' },
        },
      };
    }

    localStorage?.setItem('network', JSON.stringify(response));

    const nodesData = response?.data
      ?.slice(0, 5)
      ?.flatMap((categoryData, categoryIndex) => {
        const clusterName = Object.keys(categoryData)[0];
        const items = categoryData[clusterName];
        const orgNodesData = [];

        orgNodesData.push({
          ...items,
          id: `category_${clusterName}_${categoryIndex}`,
          group: categoryIndex,
          category: clusterName,
          size:
            Math.floor(
              Math.random() *
                10 *
                (items?.length >= 10 ? items?.length % 10 : items?.length)
            ) + 35,
          label: clusterName,
        });

        items.forEach((item, id) => {
          // Add organization node
          orgNodesData.push({
            ...item,
            id: `org_${item?.organization}_${clusterName}_${categoryIndex}_${id}`,
            group: categoryIndex,
            category: clusterName,
            size:
              Math.floor(
                Math.random() *
                  (15 - 10 + 1) *
                  (item?.author_count >= 10
                    ? item?.author_count % 10
                    : item?.author_count)
              ) + 15,
            label: item?.organization,
            orgColor: yellowColorGradients?.yellow40,
          });

          // Add author nodes
          if (item?.authors) {
            item.authors.forEach((authorItem, authorIndex) => {
              orgNodesData.push({
                ...item,
                id: `author_${authorItem?.author}_${authorIndex}_${categoryIndex}_${id}`,
                group: categoryIndex,
                category: clusterName,
                size:
                  authorItem?.mentions?.length > 0
                    ? Math.floor(
                        Math.random() *
                          (15 - 10 + 1) *
                          authorItem?.mentions?.length
                      ) + 12
                    : Math.floor(Math.random() * (15 - 10 + 1)) + 10,
                label: authorItem?.author,
                authorColor: blueColorGradients?.blue50,
              });

              if (authorItem?.mentions) {
                authorItem.mentions.forEach((authorMention, mentionId) => {
                  orgNodesData.push({
                    ...item,
                    ...authorItem,
                    id: `mentions_${authorMention}_${authorItem?.author}_${authorIndex}_${categoryIndex}_${id}`,
                    group: categoryIndex,
                    category: clusterName,
                    size:
                      authorItem?.mentions?.length > 0
                        ? Math.floor(
                            Math.random() *
                              (15 - 10 + 1) *
                              authorItem?.mentions?.length
                          ) + 12
                        : Math.floor(Math.random() * (15 - 10 + 1)) + 10,
                    label: authorMention,
                    mentionColor: greenColorGradients?.green50,
                  });
                });
              }
            });
          }
        });

        return orgNodesData;
      });

    const linkData = response?.data
      ?.slice(0, 5)
      ?.flatMap((categoryData, categoryIndex) => {
        const linkNodes = [];

        const clusterName = Object.keys(categoryData)[0];
        const items = categoryData[clusterName];
        const categoryNodeId = `category_${clusterName}_${categoryIndex}`;

        items.forEach((item, id) => {
          linkNodes.push({
            source: categoryNodeId,
            target: `org_${item?.organization}_${clusterName}_${categoryIndex}_${id}`,
          });

          // Add links from the organization to authors
          if (item?.authors) {
            item.authors.forEach((authorItem, authorIndex) => {
              linkNodes.push({
                source: `org_${item?.organization}_${clusterName}_${categoryIndex}_${id}`,
                target: `author_${authorItem?.author}_${authorIndex}_${categoryIndex}_${id}`,
              });
              if (authorItem?.mentions) {
                authorItem.mentions.forEach((authorMention, mentionId) => {
                  linkNodes.push({
                    source: `author_${authorItem?.author}_${authorIndex}_${categoryIndex}_${id}`,
                    target: `mentions_${authorMention}_${authorItem?.author}_${authorIndex}_${categoryIndex}_${id}`,
                  });
                });
              }
            });
          }
        });

        return linkNodes;
      });

    const legends = response?.data?.slice(0, 10)?.map((item) => {
      const clusterName = Object.keys(item)[0];
      return {
        label: clusterName,
        value: clusterName,
      };
    });
    const networkClusterData = JSON.parse(
      JSON.stringify(networkClusterMapData)
    );

    networkClusterData.data.labels = legends;
    networkClusterData.data.data = {
      nodes: nodesData,
      links: linkData,
    };
    //   if (response) {
    //     data.originalData = response;
    //   }
    networkClusterData.slotType = 'full';
    networkClusterData.shouldShowGraph = true;
    networkClusterData.resetNetworkMapData = {
      nodes: nodesData,
      links: linkData,
    };

    return networkClusterData;
  } catch (error) {
    console.error('Error occurred:', error);
    return {
      data: {
        title: 'NetworkMap',
        shouldShowGraph: false,
        summary: { value: '0' },
      },
    };
  }
};

export const useNetworkClusterData = (filters, isEnabled) => {
  return useQuery({
    queryKey: ['dashboard-advance-network-cluster', filters],
    queryFn: () => getNetworkClusterData(filters),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled,
  });
};

const getNetworkKeyInfluencerData = async (payload) => {
  const queryString = `${objectToQueryString({
    ...payload,
  })}`;
  const { data: response } = await get(
    `${API}/dashboard-influencer/influencer-info?${queryString}`,
    {}
  );

  console.log({ infoResp: response });
  return response;

  // const data = JSON.parse(JSON.stringify(networkClusterMapData));
  // // console.log({ response });
  // //   if (response) {
  // //     data.originalData = response;
  // //   }
  // data.slotType = 'full';
  // data.shouldShowGraph = true;

  // return data;
};

export const useNetworkKeyInfluencerData = () => {
  return useMutation({
    mutationFn: getNetworkKeyInfluencerData,
  });
};

export const getNetworkSentimentChartData = async (payload) => {
  const title = 'Sentiment Breakdown';
  try {
    const queryString = `${objectToQueryString({
      ...payload,
    })}`;
    let { data: response } = await get(
      `${API}/dashboard-influencer/sentiment-breakdown?${queryString}`,
      {}
    );

    if (!response) {
      response = {};
    }

    const sentimentChartMapData = JSON.parse(
      JSON.stringify(tempSentimentChartMapData)
    );
    // // Set Percentage here
    const percentage = calculatePercentage(response);
    const sentimeMentMapData = sentimentChartMapData?.data.data;

    const updatedSentimentMapData = sentimeMentMapData?.map((x) => {
      return {
        ...x,
        value: percentage[x.label],
        labelColor: x?.color,
      };
    });

    const totalArticlesMapData = sentimentChartMapData.data.summary;
    totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
    sentimentChartMapData.data.summary = totalArticlesMapData;
    sentimentChartMapData.data.data = updatedSentimentMapData || [];
    sentimentChartMapData.shouldShowGraph = response?.total_count > 0;
    if (response) {
      sentimentChartMapData.originalData = response;
    }
    sentimentChartMapData.slotType = 'half';
    return sentimentChartMapData;
  } catch (error) {
    console.error('Error occurred:', error);
    return {
      data: {
        title,
        shouldShowGraph: false,
        summary: { value: '0' },
      },
    };
  }
};

export const useNetworkSentimentBreakdownChartData = (filters, isEnabled) => {
  return useQuery({
    queryKey: ['dashboard-network-sentiment-analysis', filters],
    queryFn: () => getNetworkSentimentChartData(filters),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled,
  });
};

export const getNetworkTopThemeChartData = async (payload) => {
  try {
    let { data: response } = await get(
      `${API}/dashboard-influencer/topic-of-discussion?${objectToQueryString(
        payload
      )}`,
      {}
    );

    if (!response) {
      response = {};
    }
    const topThemeChartMapData = JSON.parse(
      JSON.stringify(TempTopThemeChartMapData)
    );

    topThemeChartMapData.title = 'Topics of Discussion';

    const totalArticlesMapData = topThemeChartMapData.data.summary;
    totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
    topThemeChartMapData.data.summary = totalArticlesMapData;

    const topThemeRes = response?.data;

    const updatedTopSourcedata = topThemeRes?.slice(0, 3)?.map((x, i) => {
      return {
        ...x,
        value: x?.article_count === 0 ? 1 : x?.article_count,
        secondaryValue: x?.secondary_theme,
        label: x?.primary_theme,
        thresholdValue: x?.article_count === 0 ? 1 : x?.article_count,
        keyword: x?.keyword,
      };
    });
    topThemeChartMapData.data.data = updatedTopSourcedata || [];
    topThemeChartMapData.shouldShowGraph = topThemeRes?.length > 0;
    topThemeChartMapData.originalData = response;
    topThemeChartMapData.slotType = 'half';
    return topThemeChartMapData;
  } catch (error) {
    console.error('Error occurred:', error);
    return {
      data: {
        title: 'Top Themes',
        shouldShowGraph: false,
        summary: { value: '0' },
      },
    };
  }
};

export const useNetworkTopicsDiscussionChartData = (filters, editMode) => {
  return useQuery({
    queryKey: ['network-top-theme-charts', filters],
    queryFn: () => getNetworkTopThemeChartData(filters),
    refetchOnWindowFocus: false,
    enabled: !!editMode,
  });
};
