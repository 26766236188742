import {
  coolGrayColorGradients,
  greenColorGradients,
  redColorGradients,
} from '../../constants/graph-colors';

export const topAuthorChartMapData = {
  title: 'Top Authors',
  subTitle: '',
  component: 'top_author',
  graphType: 'top_author_bar',
  data: {
    title: 'Top Media Contributors',
    subTitle: '',
    summary: {
      label: 'Total Articles',
      value: '1.8 Million',
      subLabel: '132/237',
    },
    data: [
      {
        label: 'Author 1',
        value: 10000,
        thresholdValue: 10000,
        color: '#22B9FF',
      },
      {
        label: 'Author 2',
        value: 8746,
        thresholdValue: 8500,
        color: '#22B9FF',
      },
      {
        label: 'Author 3',
        value: 7984,
        thresholdValue: 7900,
        color: '#22B9FF',
      },
      {
        label: 'Author 4',
        value: 6000,
        thresholdValue: 6000,
        color: '#22B9FF',
      },
      {
        label: 'Author 5',
        value: 6000,
        thresholdValue: 6000,
        color: '#22B9FF',
      },
      {
        label: 'Author 6',
        value: 6000,
        thresholdValue: 6000,
        color: '#22B9FF',
      },
    ],
    labels: [
      {
        label: 'label',
        value: 'value',
      },
    ],
    info: [],
  },
};

export const sentimentOverTime = {
  title: 'Sentiment Over Time',
  subTitle: '',
  component: 'advanced_campaign_sentiment_over_time',
  graphType: 'stacked_line',
  dataType: ['2d'],
  data: {
    title: '',
    subtitle: '',
    summary: {
      subtext: 'Group Stacked column',
      label: 'Total Articles',
      value: '1.8 Million',
      widgetName: 'Compliance Categories',
    },
    data: [],
    labels: [
      {
        label: 'Positive',
        value: 'POS',
        color: greenColorGradients?.green50,
      },
      {
        label: 'Negative',
        value: 'NEG',
        color: redColorGradients?.red50,
      },
      {
        label: 'Neutral',
        value: 'NEU',
        color: coolGrayColorGradients?.coolGray40,
      },
    ],
    info: [],
  },
};

export const resultOveTime = {
  title: 'Reach Over Time',
  subTitle: '',
  component: 'advanced_campaign_monitor_reach',
  graphType: 'line',
  dataType: ['1d'],
  data: {
    title: 'Grouped Stacked',
    subtitle: '2020-04-17',
    summary: {
      subtext: 'Group Stacked column',
      label: 'Total Articles',
      value: '1.8 Million',
      widgetName: 'Compliance Categories',
    },
    data: [
      {
        label: 'Jan',
        gucci: '100000',
        color: '#7EBDC2',
      },
      {
        label: 'Feb',
        gucci: '700000',
        color: '#7EBDC2',
      },
      {
        label: 'Mar',
        gucci: '1400000',
        color: '#7EBDC2',
      },
      {
        label: 'Apr',
        gucci: '2500000',
        color: '#7EBDC2',
      },
      {
        label: 'May',
        gucci: '1500000',
        color: '#7EBDC2',
      },
      {
        label: 'Jun',
        gucci: '2000000',
        color: '#7EBDC2',
      },
      {
        label: 'Jul',
        gucci: '1500000',
        color: '#7EBDC2',
      },
      {
        label: 'Aug',
        gucci: '2000000',
        color: '#7EBDC2',
      },
      {
        label: 'Sep',
        gucci: '1400000',
        color: '#7EBDC2',
      },
      {
        label: 'Oct',
        gucci: '2200000',
        color: '#7EBDC2',
      },
      {
        label: 'Nov',
        gucci: '1500000',
        color: '#7EBDC2',
      },
      {
        label: 'Dec',
        gucci: '1800000',
        color: '#7EBDC2',
      },
    ],
    labels: [
      {
        label: 'Gucci',
        value: 'gucci',
        colorOpacity: 0.1,
      },
    ],
    info: [],
  },
};

export const topThemeChartMapData = {
  title: 'Popular Topics',
  subTitle: '',
  component: 'top_themes',
  graphType: 'bubble',
  data: {
    title: 'Popular Topics',
    subTitle: '',
    summary: {
      label: 'Total Articles',
      value: '1.9 Million',
      subLabel: 'Total',
    },
    data: [
      {
        label: 'Betting Week',
        value: 91,
        thresholdValue: 95,
        color: '#614CFF',
      },
      {
        label: 'Airica Steed',
        value: 92,
        thresholdValue: 20,
        color: '#8A74FF',
      },
      {
        label: 'Nfl Betting',
        value: 93,
        thresholdValue: 59,
        color: '#D16BFD',
      },
      {
        label: 'Surprising Launches',
        value: 94,
        thresholdValue: 27,
        color: '#9955F4',
      },
      {
        label: 'Boston Globe',
        value: 95,
        thresholdValue: 16,
        color: '#4337A0',
      },
      {
        label: 'Jaguars-titans Report',
        value: 96,
        thresholdValue: 54,
        color: '#85ECE9',
      },
      {
        label: 'Metrohealth Ceo',
        value: 97,
        thresholdValue: 56,
        color: '#32CBC8',
      },
      {
        label: 'Steed Talks',
        value: 98,
        thresholdValue: 47,
        color: '#35C684',
      },
      {
        label: 'Underdogs Multiple',
        value: 99,
        thresholdValue: 93,
        color: '#FA8750',
      },
      {
        label: 'Multiple Ministries',
        value: 100,
        thresholdValue: 62,
        color: '#EFB73E',
      },
      {
        label: 'Mavericks Finally',
        value: 101,
        thresholdValue: 11,
        color: '#FFE566',
      },
      {
        label: 'Damar Hamlin',
        value: 102,
        thresholdValue: 35,
        color: '#EB4A84',
      },
      {
        label: 'Hamlin Update',
        value: 95,
        thresholdValue: 44,
        color: '#6D5E7B',
      },
    ],
    labels: [
      {
        label: 'label',
        value: 'value',
      },
    ],
    info: [],
  },
};

export const topSourceChartMapData = {
  title: 'Popular Websites',
  subTitle: '',
  component: 'top_source',
  graphType: 'top_source_bar_tree_map',
  data: {
    title: 'Top Sourcess',
    subTitle: '',
    summary: {
      label: 'Total Articles',
      subLabel: '132/237',
      value: '1.9 Million',
    },
    data: [
      {
        label: 'WSL',
        value: 10000,
        thresholdValue: 80,
        color: '#893FFC',
      },
      {
        label: 'Huffington Post',
        value: 9000,
        thresholdValue: 20,
        color: '#893FFC',
      },
      {
        label: 'Bloomberg',
        value: 8000,
        thresholdValue: 60,
        color: '#893FFC',
      },
      {
        label: 'NY Times',
        value: 6100,
        thresholdValue: 60,
        color: '#893FFC',
      },
    ],
    labels: [
      {
        label: 'label',
        value: 'value',
      },
    ],
    info: [],
  },
};

export const wordCloudChartMapData = {
  title: 'Word Cloud',
  subTitle: '',
  component: 'word_cloud',
  graphType: 'word_cloud',
  data: {
    title: 'Word Cloud',
    subTitle: '',
    summary: {
      label: 'Total Articles',
      value: 35,
      subLabel: '132/237',
    },
    data: [
      {
        label: 'Damar Hamlin Update',
        value: 90,
        thresholdValue: 13,
        color: '#8A74FF',
      },
      {
        label: 'Airica Steed talks',
        value: 10,
        thresholdValue: 10000,
        color: '#614CFF',
      },
      {
        label: 'NFL Betting Week',
        value: 80,
        thresholdValue: 23,
        color: '#D16BFD',
      },
      {
        label: 'interview NFL Betting',
        value: 75,
        thresholdValue: 92,
        color: '#9955F4',
      },
      {
        label: 'underdogs Multiple ministries',
        value: 66,
        thresholdValue: 40,
        color: '#4337A0',
      },
      {
        label: 'Mavericks finally cover',
        value: 52,
        thresholdValue: 36,
        color: '#85ECE9',
      },
      {
        label: 'USs largest estuary',
        value: 45,
        thresholdValue: 30,
        color: '#32CBC8',
      },
      {
        label: 'Monica Robins',
        value: 45,
        thresholdValue: 80,
        color: '#35C684',
      },
      {
        label: 'Dallas Market',
        value: 40,
        thresholdValue: 79,
        color: '#FA8750',
      },
      {
        label: 'CEO Dr. Airica',
        value: 25,
        thresholdValue: 42,
        color: '#EFB73E',
      },
      {
        label: 'Airica Steed',
        value: 25,
        thresholdValue: 27,
        color: '#FFE566',
      },
      {
        label: 'NFL Betting',
        value: 25,
        thresholdValue: 29,
        color: '#EB4A84',
      },
      {
        label: 'Surprising Launches',
        value: 24,
        thresholdValue: 84,
        color: '#6D5E7B',
      },
      {
        label: 'Launches at Dallas',
        value: 23,
        thresholdValue: 62,
        color: '#656B8A',
      },
      {
        label: 'School Chesapeake Bay',
        value: 22,
        thresholdValue: 39,
        color: '#B4BAD9',
      },
      {
        label: 'Busch Gardens offers',
        value: 21,
        thresholdValue: 19,
        color: '#614CFF',
      },
      {
        label: 'Boston Globe',
        value: 20,
        thresholdValue: 82,
        color: '#8A74FF',
      },
      {
        label: 'roof in Denver',
        value: 20,
        thresholdValue: 71,
        color: '#D16BFD',
      },
      {
        label: 'Jaguars-Titans Report',
        value: 20,
        thresholdValue: 44,
        color: '#9955F4',
      },
      {
        label: 'MetroHealth CEO',
        value: 20,
        thresholdValue: 18,
        color: '#4337A0',
      },
    ],
    labels: [
      {
        label: 'label',
        value: 'value',
      },
    ],
    info: [],
  },
};

export const reachByCampaign = {
  title: 'Reach by Campaign',
  subTitle: '',
  component: 'advanced_campaign_comparison_reach_by_campaign',
  graphType: 'stacked_line',
  dataType: ['2d'],
  data: {
    title: '',
    subtitle: '',
    summary: {
      subtext: 'Group Stacked column',
      label: 'Total Articles',
      value: '1.8 Million',
      widgetName: 'Compliance Categories',
    },
    data: [],
    labels: [],
    info: [],
  },
};

export const compaignMapData = {
  title: 'Campaign Analysis',
  subtitle: '2020-04-17',
  component: 'advanced_campaign_comparison_campaign_analysis',
  graphType: 'campaign_analysis',
  data: {
    summary: {
      label: 'Total Articles',
      value: '1.8 Million',
      subLabel: '132/237',
    },
    data: [
      {
        label: 'campaign 1',
        xValue: 22,
        yValue: 125,
        radius: '.2',
        color: '#FFB45C',
      },
      {
        label: 'campaign 2',
        xValue: 43,
        yValue: 250,
        radius: '.5',
        color: '#E369A9',
      },
      {
        label: 'campaign 3',
        xValue: 64,
        yValue: 150,
        radius: '.3',
        color: '#7BCCFF',
      },
      {
        label: 'campaign 4',
        xValue: 85,
        yValue: 225,
        radius: '.15',
        color: '#4DE0C4',
      },
    ],
    labels: [
      {
        label: 'label',
        value: 'xValue',
      },
      {
        label: 'label',
        value: 'yValue',
      },
      {
        label: 'label',
        value: 'radius',
      },
    ],
    info: [],
  },
};

export const volumeDistByChannel = {
  title: 'Media Breakout',
  subTitle: '',
  component: 'top_source',
  graphType: 'top_source_bar_tree_map',
  data: {
    title: 'Top Sourcess',
    subTitle: '',
    summary: {
      label: 'Total Articles',
      subLabel: '132/237',
      value: '1.9 Million',
    },
    data: [
      {
        label: 'WSL',
        value: 10000,
        thresholdValue: 80,
        color: '#893FFC',
      },
      {
        label: 'Huffington Post',
        value: 9000,
        thresholdValue: 20,
        color: '#893FFC',
      },
      {
        label: 'Bloomberg',
        value: 8000,
        thresholdValue: 60,
        color: '#893FFC',
      },
      {
        label: 'NY Times',
        value: 6100,
        thresholdValue: 60,
        color: '#893FFC',
      },
    ],
    labels: [
      {
        label: 'label',
        value: 'value',
      },
    ],
    info: [],
  },
};

export const campaignSentiment = {
  title: 'Sentiment',
  subTitle: '',
  component: 'campaign_compare_sentiment',
  // graphType: 'stacked_column',
  graphType: 'stacked_bar',
  dataType: ['1d'],
  data: {
    title: 'Sentiment',
    subTitle: 'Total Articles',
    summary: {
      subtext: '',
      label: 'Total Articles',
      value: '1.8 Million',
    },
    data: [
      {
        label: 'Source 1',
        neg: '190000',
        pos: '360000',
        neu: '310000',
      },
      {
        label: 'Source 2',
        neg: '280000',
        pos: '290000',
        neu: '420000',
      },
      {
        label: 'Source 3',
        neg: '390000',
        pos: '350000',
        neu: '480000',
      },
      {
        label: 'Source 4',
        neg: '250000',
        pos: '280000',
        neu: '350000',
      },
      {
        label: 'Source 5',
        neg: '250000',
        pos: '150000',
        neu: '290000',
      },
    ],
    labels: [
      {
        label: 'Positive',
        value: 'pos',
        color: '#24A148',
      },
      {
        label: 'Negative',
        value: 'neg',
        color: '#FA4D56',
      },
      {
        label: 'Neutral',
        value: 'neu',
        color: '#A9ADBE',
      },
    ],
    info: [],
  },
};
