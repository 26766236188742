import styled from 'styled-components';

const SlotBodyHeight = 'calc(100% - 5rem)';
const SlotDetailsWrpGap = 0.5;

export const Loaderwrp = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 70vh;
  margin-top: auto;
  margin-bottom: auto;
`;

export const SlotWrpAuthor = styled.div`
  position: relative;
  margin: 0rem 0rem 4rem 0rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;

  .y.axis > .domain {
    display: none;
  }

  .y.axis > .tick > .y-axis-line {
    display: block;
  }

  .y.axis > .tick > text,
  .x.axis > .tick > text {
    fill: #585858;
    font-size: 0.6875rem;
    font-family: ${({ theme }) => theme.fontFamily};
    text-transform: capitalize;
  }
  .x.axis > .domain {
    stroke: ${({ theme }) => theme.graphColors.coolGray30};
  }
`;

export const HalfSlotAuthor = styled.div`
  height: 25rem;
  background-color: #ffffff;
  border-radius: ${({ theme }) => theme.primaryBorderRadius};
  padding: 1.25rem;
  width: calc(50% - 0.375rem);
  /* cursor: pointer; */
  border: 2px solid ${({ selected }) => (selected ? 'blue' : 'none')};
  z-index: ${({ selected }) => (selected ? 1 : null)};
`;

export const FullSlot = styled.div`
  width: 100%;
  height: ${({ networkCluster, padding = true }) =>
    networkCluster ? '46.25rem' : !padding ? 'fit-content' : '30rem'};
  border-radius: ${({ theme, padding = true }) =>
    padding ? theme.primaryBorderRadius : '1.5rem'};
  padding: ${({ padding = true }) => padding && '1.25rem'};
  background-color: #ffffff;
  cursor: pointer;
  border: 2px solid ${({ selected }) => (selected ? 'blue' : 'none')};
  z-index: ${({ selected }) => (selected ? 1 : null)};
  margin-bottom: ${({ dashboardType }) => (dashboardType ? '0rem' : '4rem')};
`;

export const SlotDetailsMainWrp = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  padding-bottom: 0.5rem;
  box-sizing: border-box;
`;
export const IconBox = styled.div`
  cursor: auto;
  /* position: absolute; */
  /* width: 5.75rem; */
  display: flex;
  /* z-index: 1; */
  justify-content: space-between;
  align-items: start;
  top: 0rem;
  right: 0;
  gap: 0.5rem;
`;
export const Iconwpr = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ width = '2.25rem', height = '2.25rem' }) => `
  height: ${height};
  width: ${width};
  `}
  cursor: pointer;
  border-radius: 0.25rem;
  position: relative;
`;
export const SlotDetailsWrp = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${SlotDetailsWrpGap}rem;
`;
export const SlotHeader = styled.div`
  height: 1rem;
  display: flex;
  justify-content: space-between;
`;

export const SlotHeaderLeft = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  /* width: 100%; */
`;
export const SlotTitle = styled.div`
  font-size: 0.9rem;
  font-weight: 600;
  color: ${({ theme }) => theme.text};
  width: fit-content;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: ${({ noOfArticleText }) => !noOfArticleText && 'capitalize'};
`;

export const SlotTypeTitle = styled.span`
  padding: 4px 8px 4px 8px;
  border-radius: 5px;
  margin-left: 10px;
  background: ${({ type, theme }) =>
    (type === 'brand' || type === 'competition') && theme.customBrandBckgrnd};
  font-family: Inter;
  font-size: 11px;
  font-weight: 600;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: left;
  color: ${({ type, theme }) =>
    (type === 'brand' || type === 'competition') &&
    theme.customBrandLabelColor};
`;

export const SlotSubTitle = styled.div`
  font-size: 0.8rem;
  color: #585858;
`;
export const SlotBody = styled.div`
  position: relative;
  height: ${SlotBodyHeight};
  width: 100%;
  &.legend,
  &.commentary {
    height: calc(${SlotBodyHeight} - 1rem);
    z-index: 0;
  }

  .selected {
    transition: all 400ms ease;
    opacity: 1 !important;
  }

  .unselected {
    transition: all 400ms ease;
    opacity: 0.2 !important;
  }

  .hover-selected {
    transition: all 400ms ease;
    opacity: 1 !important;
  }
  .hover-unselected {
    transition: all 400ms ease;
    opacity: 0.2 !important;
  }
  .word-cloud-text {
    font-weight: 700;
  }
`;
export const SlotBodyMain = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
`;
export const SlotGraphItem = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* margin-bottom: 1rem; */
`;

export const NoDataTxtWrp = styled.div`
  font-size: 1rem;
  font-weight: 700;
  color: ${({ theme }) => theme.disabledBtnColor};
  width: 100%;
  height: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  justify-content: center;
  align-items: center !important;
`;

export const ChartName = styled.div`
  font-size: 0.9rem;
  font-weight: 600;
  color: ${({ theme }) => theme.text};
  text-transform: capitalize;
`;
export const SlotFooter = styled.div`
  width: 100%;
  height: 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  justify-content: flex-end;
`;

export const LegendSectionAuthor = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: ${({ justify }) => justify ?? 'flex-start'};
  margin-top: 2rem;
  overflow-x: auto;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const SlotFooterBrand = styled.div`
  width: 100%;
  height: 4rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  justify-content: flex-end;
`;

export const GraphBrandCompNoDataText = styled.h1`
  font-size: 1rem;
  font-weight: 700;
  color: ${({ theme }) => theme.disabledBtnColor};
  width: 100%;
  height: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  justify-content: center;
  align-items: center !important;
`;
