import { useQuery } from '@tanstack/react-query';
import { get } from '../service';
import { API } from '../constants';
import { objectToQueryString } from './useSearch';
import { congruenceMapData } from './data/advancedDashboardData';

import { addCountPrefix } from '../constants/utils';
import {
  topAdvocatesChartMapData as tempTopAdvocatesChartMapData,
  topDetractorsChartMapData as tempTopDetractorsChartMapData,
  contentType as tempContentType,
  sentimeOverTime,
  resultOveTime,
  topThemeChartMapData as TempTopThemeChartMapData,
  totalOverviewSocialDetailsMap as TempTotalOverviewDetails,
} from './data/advancedSocialCampaign';
import twitterLogo from '../assets/img/twitterLogo.png';
import redditLogo from '../assets/img/reddit.png';
import {
  blueColorGradients,
  purpleColorGradients,
} from '../constants/graph-colors';
import { getFormattedDate } from './useCharts';
import { concentricPie } from '../graphs/utils/graphConst';

export function updateArrayToObject(
  arr,
  keyProperty,
  docCountProperty,
  labels
) {
  const resultObject = {};

  labels.forEach((label) => {
    const value = label?.value;
    const docCount =
      arr.find((item) => item[keyProperty] === value)?.[docCountProperty] || 0;
    resultObject[value] = docCount;
  });

  return resultObject;
}

export const transformDocCountData = (apiData) => {
  const daysOfWeek = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  const timeLabels = [
    '00 AM',
    '1 AM',
    '2 AM',
    '3 AM',
    '4 AM',
    '5 AM',
    '6 AM',
    '7 AM',
    '8 AM',
    '9 AM',
    '10 AM',
    '11 AM',
    '12 PM',
    '1 PM',
    '2 PM',
    '3 PM',
    '4 PM',
    '5 PM',
    '6 PM',
    '7 PM',
    '8 PM',
    '9 PM',
    '10 PM',
    '11 PM',
  ];

  const transformedData = {
    data: daysOfWeek.map((day) => {
      const dayData = { label: day };
      timeLabels.forEach((time) => {
        const hour =
          time.includes('AM') && time !== '12 PM' && time !== '00 AM'
            ? parseInt(time)
            : time === '00 AM'
            ? 12
            : parseInt(time);
        const apiTime = `${hour.toString().padStart(2, '0')}:00 ${
          time.includes('PM') ? 'PM' : 'AM'
        }`;

        dayData[time] = apiData[day][apiTime]?.doc_count.toString() || '0';
      });
      const keys = Object.keys(apiData?.[day]);
      const firstKey = keys[0];
      dayData.date = apiData[day][firstKey]?.date;
      return dayData;
    }),
    summary: {
      value: Object.values(apiData).reduce(
        (sum, day) =>
          sum +
          Object.values(day).reduce(
            (daySum, hour) => daySum + hour.doc_count,
            0
          ),
        0
      ),
    },
    info: [],
    labels: timeLabels.map((label) => ({ label, value: label })),
  };

  return transformedData;
};

//  top detractors and advocates

const getTopAdvocates = async (filters, communicationData) => {
  const queryString = `${objectToQueryString({
    ...filters,
    campaign_name: JSON.stringify(
      communicationData
        ?.map((x) => {
          return {
            name: x.name,
            keyword: x.keywords,
          };
        })
        .filter((x) => x.keyword)
    ),
    sentiments: ['POS'],
  })}`;
  const { data: response } = await get(
    `${API}/dashboard-advanced/social-campaign/top-advocates-detractors?${queryString}`,
    {}
  );
  if (!response) {
    response = {};
  }

  const advocatesChartMapData = JSON.parse(
    JSON.stringify(tempTopAdvocatesChartMapData)
  );

  const totalArticlesMapData = advocatesChartMapData.data.summary;
  totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
  advocatesChartMapData.data.summary = totalArticlesMapData;

  const updatedTopAdvocateData = response?.data?.slice(0, 10)?.map((x, i) => {
    return {
      author_id: x?.id,
      value: x?.total_posts,
      name: x?.name,
      color: '#DEFBE6',
      total_posts: x?.total_posts,
      total_engagements: x?.total_engagements,
      media_type: x?.media_type,
      total_reach: x?.total_reach,
      logoUrl:
        x?.media_type === 'Reddit'
          ? redditLogo
          : x?.media_type === 'X (Twitter)'
          ? twitterLogo
          : '',
    };
  });

  advocatesChartMapData.data.data = updatedTopAdvocateData || [];
  advocatesChartMapData.shouldShowGraph = response?.data?.length > 0;
  if (response) {
    advocatesChartMapData.originalData = response;
  }
  return advocatesChartMapData;
};

export const useAdvocates = (filters, advancedOptions, isEnabled) => {
  return useQuery({
    queryKey: ['dashboard-advanced_advocates', filters, advancedOptions],
    queryFn: () => getTopAdvocates(filters, advancedOptions),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled,
  });
};

const getTopDetractors = async (filters, communicationData) => {
  const queryString = `${objectToQueryString({
    ...filters,
    campaign_name: JSON.stringify(
      communicationData
        ?.map((x) => {
          return {
            name: x.name,
            keyword: x.keywords,
          };
        })
        .filter((x) => x.keyword)
    ),
    sentiments: ['NEG'],
  })}`;
  const { data: response } = await get(
    `${API}/dashboard-advanced/social-campaign/top-advocates-detractors?${queryString}`,
    {}
  );

  if (!response) {
    response = {};
  }

  const detractorsChartMapData = JSON.parse(
    JSON.stringify(tempTopDetractorsChartMapData)
  );

  const totalArticlesMapData = detractorsChartMapData.data.summary;
  totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
  detractorsChartMapData.data.summary = totalArticlesMapData;

  const updatedTopDetractorData = response?.data?.slice(0, 10)?.map((x, i) => {
    return {
      author_id: x?.id,
      color: '#FFF1F1',
      value: x?.total_posts,
      name: x?.name,
      total_posts: x?.total_posts,
      total_engagements: x?.total_engagements,
      media_type: x?.media_type,
      total_reach: x?.total_reach,
      logoUrl:
        x?.media_type === 'Reddit'
          ? redditLogo
          : x?.media_type === 'X (Twitter)'
          ? twitterLogo
          : '',
    };
  });

  detractorsChartMapData.data.data = updatedTopDetractorData || [];
  detractorsChartMapData.shouldShowGraph = response?.data?.length > 0;
  if (response) {
    detractorsChartMapData.originalData = response;
  }
  return detractorsChartMapData;
};

export const useDetractors = (filters, advancedOptions, isEnabled) => {
  return useQuery({
    queryKey: ['dashboard-advanced_detractors', filters, advancedOptions],
    queryFn: () => getTopDetractors(filters, advancedOptions),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled,
  });
};

// result over time
const getResultOverTime = async (filters, communicationData) => {
  const queryString = `${objectToQueryString({
    ...filters,
    campaign_name: JSON.stringify(
      communicationData
        ?.map((x) => {
          return {
            name: x.name,
            keyword: x.keywords,
          };
        })
        .filter((x) => x.keyword)
    ),
  })}`;
  const { data: response } = await get(
    `${API}/dashboard-advanced/social-campaign/social-date-wise-count?${queryString}`,
    {}
  );
  if (!response) {
    response = [];
  }

  const mapData = JSON.parse(JSON.stringify(resultOveTime));

  const totalArticlesMapData = mapData.data.summary;

  totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
  mapData.data.summary = totalArticlesMapData;
  mapData.data.data = response?.data?.map((x) => {
    const formattedDate = getFormattedDate(x?.date);
    return {
      ...x,
      label: formattedDate,
      gucci: x.doc_count,
      date: x?.date,
      color: purpleColorGradients?.purple60,
      timeline: x.label,
    };
  });
  mapData.data.summary.value = String(addCountPrefix(response?.total_count));
  mapData.shouldShowGraph = response?.data?.some((items) => items.value !== 0);
  if (response) {
    mapData.originalData = response;
  }
  return mapData;
};

export const useAdvancedResultOverTime = (
  filters,
  advancedOptions,
  isEnabled
) => {
  return useQuery({
    queryKey: ['dashboard-advanced_result_over_time', filters, advancedOptions],
    queryFn: () => getResultOverTime(filters, advancedOptions),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled,
  });
};

// top themes
const getTopThemes = async (filters, communicationData) => {
  const queryString = `${objectToQueryString({
    ...filters,
    campaign_name: JSON.stringify(
      communicationData
        ?.map((x) => {
          return {
            name: x.name,
            keyword: x.keywords,
          };
        })
        .filter((x) => x.keyword)
    ),
  })}`;
  const { data: response } = await get(
    `${API}/dashboard-advanced/social-campaign/top-themes-by-dates?${queryString}`,
    {}
  );
  if (!response) {
    response = {};
  }
  const topThemeChartMapData = JSON.parse(
    JSON.stringify(TempTopThemeChartMapData)
  );

  const totalArticlesMapData = topThemeChartMapData.data.summary;
  totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
  topThemeChartMapData.data.summary = totalArticlesMapData;

  const topThemeRes = response?.data;

  const updatedTopSourcedata = topThemeRes?.slice(0, 3)?.map((x, i) => {
    const colorIndex = i % concentricPie.length;
    return {
      ...concentricPie[colorIndex],
      value: x?.article_count === 0 ? 1 : x?.article_count,
      secondaryValue: x.secondary_theme,
      label: x?.primary_theme,
      thresholdValue: x?.article_count === 0 ? 1 : x?.article_count,
      keyword: x?.keyword,
      labelColor: concentricPie[colorIndex],
    };
  });
  topThemeChartMapData.data.data = updatedTopSourcedata || [];
  topThemeChartMapData.shouldShowGraph = topThemeRes?.length > 0;
  topThemeChartMapData.originalData = response;
  // topThemeChartMapData.shouldShowGraph = true;
  return topThemeChartMapData;
};

export const useAdvancedTopThemes = (filters, advancedOptions, isEnabled) => {
  return useQuery({
    queryKey: ['dashboard-advanced_themes', filters, advancedOptions],
    queryFn: () => getTopThemes(filters, advancedOptions),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled,
  });
};

// top themes pre

const getTopThemesPre = async (filters, communicationData) => {
  const queryString = `${objectToQueryString({
    ...filters,
    campaign_name: JSON.stringify(
      communicationData
        ?.map((x) => {
          return {
            name: x.name,
            keyword: x.keywords,
          };
        })
        .filter((x) => x.keyword)
    ),
  })}`;
  const { data: response } = await get(
    `${API}/dashboard-advanced/social-campaign/top-themes-by-dates?${queryString}`,
    {}
  );
  if (!response) {
    response = {};
  }
  const topThemeChartMapData = JSON.parse(
    JSON.stringify(TempTopThemeChartMapData)
  );

  const totalArticlesMapData = topThemeChartMapData.data.summary;
  totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
  topThemeChartMapData.data.summary = totalArticlesMapData;

  const topThemeRes = response?.data;

  const updatedTopSourcedata = topThemeRes?.slice(0, 3)?.map((x, i) => {
    const colorIndex = i % concentricPie.length;
    return {
      ...concentricPie[colorIndex],
      value: x?.article_count === 0 ? 1 : x?.article_count,
      secondaryValue: x.secondary_theme,
      label: x?.primary_theme,
      thresholdValue: x?.article_count === 0 ? 1 : x?.article_count,
      keyword: x?.keyword,
      labelColor: concentricPie[colorIndex],
    };
  });
  topThemeChartMapData.data.data = updatedTopSourcedata || [];
  topThemeChartMapData.shouldShowGraph = topThemeRes?.length > 0;
  topThemeChartMapData.originalData = response;
  // topThemeChartMapData.shouldShowGraph = true;
  return topThemeChartMapData;
};

export const useAdvancedTopThemesPre = (
  filters,
  advancedOptions,
  isEnabled
) => {
  return useQuery({
    queryKey: ['dashboard-advanced_themes_pre', filters, advancedOptions],
    queryFn: () => getTopThemesPre(filters, advancedOptions),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled,
  });
};

// get top  themes during
const getTopThemesDuring = async (filters, communicationData) => {
  const queryString = `${objectToQueryString({
    ...filters,
    campaign_name: JSON.stringify(
      communicationData
        ?.map((x) => {
          return {
            name: x.name,
            keyword: x.keywords,
          };
        })
        .filter((x) => x.keyword)
    ),
  })}`;
  const { data: response } = await get(
    `${API}/dashboard-advanced/social-campaign/top-themes-by-dates?${queryString}`,
    {}
  );
  if (!response) {
    response = {};
  }
  const topThemeChartMapData = JSON.parse(
    JSON.stringify(TempTopThemeChartMapData)
  );

  const totalArticlesMapData = topThemeChartMapData.data.summary;
  totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
  topThemeChartMapData.data.summary = totalArticlesMapData;

  const topThemeRes = response?.data;

  const updatedTopSourcedata = topThemeRes?.slice(0, 3)?.map((x, i) => {
    const colorIndex = i % concentricPie.length;
    return {
      ...concentricPie[colorIndex],
      value: x?.article_count === 0 ? 1 : x?.article_count,
      secondaryValue: x.secondary_theme,
      label: x?.primary_theme,
      thresholdValue: x?.article_count === 0 ? 1 : x?.article_count,
      keyword: x?.keyword,
      labelColor: concentricPie[colorIndex],
    };
  });
  topThemeChartMapData.data.data = updatedTopSourcedata || [];
  topThemeChartMapData.shouldShowGraph = topThemeRes?.length > 0;
  topThemeChartMapData.originalData = response;
  // topThemeChartMapData.shouldShowGraph = true;
  return topThemeChartMapData;
};

export const useAdvancedTopThemesDuring = (
  filters,
  advancedOptions,
  isEnabled
) => {
  return useQuery({
    queryKey: ['dashboard-advanced_themes_during', filters, advancedOptions],
    queryFn: () => getTopThemesDuring(filters, advancedOptions),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled,
  });
};

// top themes post
const getTopThemesPost = async (filters, communicationData) => {
  const queryString = `${objectToQueryString({
    ...filters,
    campaign_name: JSON.stringify(
      communicationData
        ?.map((x) => {
          return {
            name: x.name,
            keyword: x.keywords,
          };
        })
        .filter((x) => x.keyword)
    ),
  })}`;
  const { data: response } = await get(
    `${API}/dashboard-advanced/social-campaign/top-themes-by-dates?${queryString}`,
    {}
  );
  if (!response) {
    response = {};
  }
  const topThemeChartMapData = JSON.parse(
    JSON.stringify(TempTopThemeChartMapData)
  );

  const totalArticlesMapData = topThemeChartMapData.data.summary;
  totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
  topThemeChartMapData.data.summary = totalArticlesMapData;

  const topThemeRes = response?.data;

  const updatedTopSourcedata = topThemeRes?.slice(0, 3)?.map((x, i) => {
    const colorIndex = i % concentricPie.length;
    return {
      ...concentricPie[colorIndex],
      value: x?.article_count === 0 ? 1 : x?.article_count,
      secondaryValue: x.secondary_theme,
      label: x?.primary_theme,
      thresholdValue: x?.article_count === 0 ? 1 : x?.article_count,
      keyword: x?.keyword,
      labelColor: concentricPie[colorIndex],
    };
  });
  topThemeChartMapData.data.data = updatedTopSourcedata || [];
  topThemeChartMapData.shouldShowGraph = topThemeRes?.length > 0;
  topThemeChartMapData.originalData = response;
  // topThemeChartMapData.shouldShowGraph = true;
  return topThemeChartMapData;
};

export const useAdvancedTopThemesPost = (
  filters,
  advancedOptions,
  isEnabled
) => {
  return useQuery({
    queryKey: ['dashboard-advanced_themes_post', filters, advancedOptions],
    queryFn: () => getTopThemesPost(filters, advancedOptions),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled,
  });
};

// sentiments trend
const getSentimentOverTime = async (filters, communicationData) => {
  const queryString = `${objectToQueryString({
    ...filters,
    campaign_name: JSON.stringify(
      communicationData
        ?.map((x) => {
          return {
            name: x.name,
            keyword: x.keywords,
          };
        })
        .filter((x) => x.keyword)
    ),
  })}`;
  const { data: response } = await get(
    `${API}/dashboard-advanced/social-campaign/sentiments-over-time?${queryString}`,
    {}
  );
  const mapData = JSON.parse(JSON.stringify(sentimeOverTime));

  const dataFromRes = response?.data;
  const labels = mapData.data.labels;
  const udpatedDataFromRes = dataFromRes?.map((x) => {
    const formattedDate = getFormattedDate(x?.date ?? x.label);
    const data = updateArrayToObject(
      x?.sentiment_count,
      'key',
      'doc_count',
      labels
    );

    return {
      label: formattedDate,
      ...data,
      date: x?.date ?? x.label,
      timeline: x.label,
    };
  });

  const totalArticlesMapData = mapData.data.summary;
  totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
  mapData.data.summary = totalArticlesMapData;
  mapData.data.data = udpatedDataFromRes || [];
  mapData.shouldShowGraph = response?.total_count;
  if (response) {
    mapData.originalData = response;
  }

  return mapData;
};

export const UseAdvancedSentimentOverTime = (
  filters,
  advancedOptions,
  isEnabled
) => {
  return useQuery({
    queryKey: [
      'dashboard-advanced_sentiment_overtime',
      filters,
      advancedOptions,
    ],
    queryFn: () => getSentimentOverTime(filters, advancedOptions),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled,
  });
};

// engagement by weektime

const getEngagement = async (filters, communicationData) => {
  const queryString = `${objectToQueryString({
    ...filters,
    campaign_name: JSON.stringify(
      communicationData
        ?.map((x) => {
          return {
            name: x.name,
            keyword: x.keywords,
          };
        })
        .filter((x) => x.keyword)
    ),
  })}`;
  const { data: response } = await get(
    `${API}/dashboard-advanced/content-dashboard/engagements-by-weektime?${queryString}`,
    {}
  );

  if (!response) {
    response = [];
  }
  const transformedData = transformDocCountData(response?.data);
  const finalData = {
    data: transformedData,
    title: 'Engagement by Time and Weekday',
    summary: { value: String(addCountPrefix(response?.total_count)) },
    originalData: response,
    keyword: communicationData?.[0]?.keywords,
  };
  return finalData;
};

export const UseAdvancedCampaignEngagement = (
  filters,
  advancedOptions,
  isEnabled
) => {
  return useQuery({
    queryKey: ['dashboard-advanced_engagement', filters, advancedOptions],
    queryFn: () => getEngagement(filters, advancedOptions),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled,
  });
};

// content type

const getContentType = async (filters, communicationData) => {
  const queryString = `${objectToQueryString({
    ...filters,
    campaign_name: JSON.stringify(
      communicationData
        ?.map((x) => {
          return {
            name: x.name,
            keyword: x.keywords,
          };
        })
        .filter((x) => x.keyword)
    ),
  })}`;
  const { data: response } = await get(
    `${API}/dashboard-advanced/content-dashboard/content-type?${queryString}`,
    {}
  );
  if (!response) {
    response = [];
  }

  const contentTypeData = JSON.parse(JSON.stringify(tempContentType));

  contentTypeData.data.data = [
    {
      label: 'Photos',
      value: response?.data?.images,
      labelColor: blueColorGradients.blue60,
    },
    {
      label: 'Links',
      value: response?.data?.link,
      labelColor: blueColorGradients.blue60,
    },
    {
      label: 'Text',
      value: response?.data?.text,
      labelColor: blueColorGradients.blue60,
    },
    {
      label: 'Video',
      value: response?.data?.video,
      labelColor: blueColorGradients.blue60,
    },
  ].filter((item) => item.value > 0);

  contentTypeData.data.summary.value = String(
    addCountPrefix(response?.total_count)
  );
  contentTypeData.shouldShowGraph = response.total_count > 0;
  if (response) {
    contentTypeData.originalData = response;
  }

  return contentTypeData;
};

export const UseAdvancedCampaignContentType = (
  filters,
  advancedOptions,
  isEnabled
) => {
  return useQuery({
    queryKey: ['dashboard-advanced_content', filters, advancedOptions],
    queryFn: () => getContentType(filters, advancedOptions),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled,
  });
};

const getOverviewDetails = async (filters, communicationData) => {
  const queryString = `${objectToQueryString({
    ...filters,
    campaign_name: JSON.stringify(
      communicationData
        ?.map((x) => {
          return {
            name: x.name,
            keyword: x.keywords,
          };
        })
        .filter((x) => x.keyword)
    ),
  })}`;
  const { data: response } = await get(
    `${API}/dashboard-advanced/social-campaign/overview-details?${queryString}`,
    {}
  );

  const totalOverviewDetailsMap = JSON.parse(
    JSON.stringify(TempTotalOverviewDetails)
  );

  if (!response) {
    return totalOverviewDetailsMap;
  }

  const mappedData = response?.map((x) => {
    return {
      title: x?.label,
      data:
        x?.label === 'Traditional Reach'
          ? parseInt(x?.current_count)
          : x?.current_count,
      change: x?.previous_count !== 0 ? parseInt(x?.changes_percent) : 100,
      isIncreased: x?.changes_percent >= 0,
      previousCount: x?.previous_count,
    };
  });

  return mappedData || totalOverviewDetailsMap;
};

export const useSocialOverviewDetails = (
  filters,
  advancedOptions,
  isEnabled
) => {
  return useQuery({
    queryKey: ['top-social-overview-details', filters, advancedOptions],
    queryFn: () => getOverviewDetails(filters, advancedOptions),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled,
  });
};
