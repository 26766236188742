import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import SlotDetails from '../../components/search-result/slot-details';
import CircularLoading from '../../assets/icons/loading/circularLoading';
import { getGraphData } from '../../utils/graph-widget/index';
import { useGetChartTemplate } from '../../hooks/useSaveNewsLetter';
import TopInfluencer from '../../components/top-influencer';
import JournalistAndSourceGraph from '../../components/advanced-dashboard/author-impact';
import PopularTopics from '../../pages/new-dashboard/popular-topics';
import CampaignMonitor from '../../components/advanced-dashboard/campaign-monitor-2';
import PeopleCampaign from '../../components/advanced-dashboard/campaign-monitor';
import Congruence from '../../components/advanced-dashboard/congruence';
import SocialCampaignMonitor from '../../components/advanced-dashboard/social-campaign';
import ContentDashboards from '../../components/advanced-dashboard/content-dashboard';

export const renderGraph = (data, graphType) => {
  const footerMarginTop = graphType === 'outlet_breakdown' ? '2rem' : '0rem';
  if (
    graphType === 'industry_top_influencers' ||
    graphType === 'people_top_score_comparison' ||
    graphType === 'brand_top_performing_posts'
  ) {
    return (
      <TopInfluencer
        widget={data}
        widgetClassName={data?.customClassName}
        key={data?.customClassName}
        helperText={data?.text}
        dashboardType={'industry'}
        isNewsletter={true}
      />
    );
  }

  if (
    graphType === 'coverage_by_source' ||
    graphType === 'coverage_by_journalist'
  ) {
    return (
      <JournalistAndSourceGraph
        widget={data}
        widgetClassName={data?.customClassName}
        helperText={data?.text}
        legend={true}
        widgetClassNameTwo={data?.customClassNameTwo}
        dashboardType={'brand'}
        isNewsletter={true}
      />
    );
  }

  if (graphType === 'people-impact') {
    return (
      <PeopleCampaign
        widget={data}
        customClassName={data?.customClassName}
        dashboardType={'people'}
        helperText={data?.text}
        title={data?.title}
        isNewsletter={true}
      />
    );
  }

  if (graphType === 'people_popular_topic_themes') {
    let widgetData = data;
    if (graphType === 'people_popular_topic_themes') {
      widgetData = {
        data,
        customClassName: 'people_popular_topic_themes',
        title: 'Popular Topics',
      };
    }
    return (
      <PopularTopics
        dashboardType={'grid-dashboard'}
        widget={widgetData}
        customClassName={data?.customClassName}
        widgetClassName={data?.customClassName}
        selected={1}
        idx={0}
        isNewsletter={true}
      />
    );
  }

  if (data?.dashboardType === 'campaign') {
    return <CampaignMonitor widget={data} isNewsletter={true} />;
  }

  if (graphType === 'congruence') {
    return (
      <Congruence
        widget={data}
        dashboardType={data?.dashboardType}
        customClassName={graphType}
      />
    );
  }

  if (data?.dashboardType === 'socialCampaign') {
    return <SocialCampaignMonitor widget={data} isNewsletter={true} />;
  }

  if (data?.dashboardType === 'contentDashboard') {
    const filteredwidget = Object.keys(data).reduce((acc, key) => {
      if (key === graphType || key === 'topEngagingPosts') {
        acc[key] = data[key];
      }
      return acc;
    }, {});
    return (
      <ContentDashboards
        widget={filteredwidget}
        dashboardType={'contentDashboard'}
        isNewsletter={true}
      />
    );
  }
  return (
    <SlotDetails
      widget={data}
      footerMarginTop={footerMarginTop}
      legend={true}
      dashboardType={'grid-dashboard'}
      // themeComponent={data.component === 'top_themes' ?? false}
    />
  );
};

const DashboardGraphs = () => {
  const [data, setData] = useState(null);

  const { graphType, searchId, dashboardId } = useParams();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const accessToken = query.get('accessToken');
  const tabIdx = query.get('tabIdx');

  const { mutateAsync: getChartDataFunc } = useGetChartTemplate();

  const getchartData = async () => {
    const headers = {
      Authorization: 'Bearer ' + accessToken,
    };

    let payload = {
      searchid: parseInt(searchId),
      dashboard_id: parseInt(dashboardId),
      chart_type: graphType,
    };

    if (tabIdx) {
      payload = {
        ...payload,
        tab_idx: parseInt(tabIdx),
      };
    }

    const response = await getChartDataFunc({ payload, headers });
    const resData = response?.data;
    const mapedData = getGraphData(resData, graphType);
    setData(mapedData);
  };

  useEffect(() => {
    getchartData();
    window?.$zoho?.salesiq?.floatbutton?.visible('hide');
    window?.$zohosq?.floatbutton?.visible('hide');
  }, []);

  const styles =
    graphType === 'outlet_breakdown'
      ? {
          maxWidth: '800px',
          maxHeight: '500px',
          margin: 'auto',
          marginTop: '50px',
          marginBottom: '50px',
        }
      : {
          maxWidth: '800px',
          height: '500px',
          margin: 'auto',
          marginTop: '30px',
          marginBottom: '50px',
        };

  return data ? (
    <div style={styles} id="graph-loaded" className="graph-rendered">
      {renderGraph(data, graphType)}
    </div>
  ) : (
    <CircularLoading size="0.25rem" width="1.875rem" height="1.875rem" />
  );
};

export default DashboardGraphs;
