import { useQuery } from '@tanstack/react-query';
import { axiosPost, get } from '../service';
import { API } from '../constants';
import { objectToQueryString } from './useSearch';
import { congruenceMapData } from './data/advancedDashboardData';

import { addCountPrefix, colorCodeObjects } from '../constants/utils';
import {
  sentimentOverTime,
  resultOveTime,
  topAuthorChartMapData as tempTopAuthorChartMapData,
  topThemeChartMapData as TempTopThemeChartMapData,
  topSourceChartMapData as TempTopSourceChartMapData,
  wordCloudChartMapData as TempWordCloudChartMapData,
  reachByCampaign,
  compaignMapData,
  volumeDistByChannel,
  campaignSentiment,
} from './data/advancedCampaign';
import {
  blueColorGradients,
  purpleColorGradients,
  magentaColorGradients,
  tealColorGradients,
  orangeColorGradients,
  yellowColorGradients,
  cyanColorGradients,
} from '../constants/graph-colors';
import { getFormattedDate } from './useCharts';
import { topThemeColors, CampaignMonitor } from '../graphs/utils/graphConst';
import { colors, WordCloudColors } from './data/colors';
import { filter, map } from 'd3';

const convertArraysToStrings = (obj) => {
  if (!obj) {
    return;
  }
  return Object.keys(obj).reduce((acc, key) => {
    acc[key] = Array.isArray(obj[key]) ? obj[key].join(',') : obj[key];
    return acc;
  }, {});
};

export function updateArrayToObject(
  arr,
  keyProperty,
  docCountProperty,
  labels
) {
  const resultObject = {};

  labels.forEach((label) => {
    const value = label?.value;
    const docCount =
      arr.find((item) => item[keyProperty] === value)?.[docCountProperty] || 0;
    resultObject[value] = docCount;
  });

  return resultObject;
}

// reach over time
const getReachOverTime = async (filters, communicationData) => {
  const updatedFilters = convertArraysToStrings(filters);
  const updatedData = {
    campaign1: {
      ...updatedFilters,
      campaign_name: communicationData
        ?.map(({ name, keywords }) => ({ name, keyword: keywords }))
        .filter(({ keyword }) => keyword),
    },
  };
  const { data: response } = await axiosPost(
    `${API}/dashboard-advanced/campaign-monitor/reach-over-time`,
    [updatedData]
  );
  if (!response) {
    response = [];
  }

  const mapData = JSON.parse(JSON.stringify(resultOveTime));

  const totalArticlesMapData = mapData.data.summary;

  totalArticlesMapData.value = String(
    addCountPrefix(response?.overall_total_count)
  );
  mapData.data.summary = totalArticlesMapData;
  mapData.data.data = response?.campaigns[0]?.campaign1?.data?.map((x) => {
    const formattedDate = getFormattedDate(x?.label);
    return {
      ...x,
      label: formattedDate,
      gucci: x.sum_reach ? parseFloat(x.sum_reach) : 0,
      date: x?.label,
      color: purpleColorGradients?.purple60,
    };
  });
  mapData.data.summary.value = String(
    addCountPrefix(response?.overall_total_count)
  );
  if (response) {
    mapData.originalData = response;
  }
  return mapData;
};

export const useAdvancedCampaignReachOverTime = (
  filters,
  advancedOptions,
  isEnabled
) => {
  return useQuery({
    queryKey: [
      'dashboard-advanced_campaign_reach_over_time',
      filters,
      advancedOptions,
    ],
    queryFn: () => getReachOverTime(filters, advancedOptions),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled,
  });
};

// top themes
const getTopThemes = async (filters, communicationData) => {
  const queryString = `${objectToQueryString({
    ...filters,
    keyword: communicationData.keywords,
  })}`;
  let { data: response } = await get(
    `${API}/search-visuals/top-themes-count?${queryString}`,
    {}
  );
  if (!response) {
    response = {};
  }
  const topThemeChartMapData = JSON.parse(
    JSON.stringify(TempTopThemeChartMapData)
  );

  const totalArticlesMapData = topThemeChartMapData.data.summary;
  totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
  topThemeChartMapData.data.summary = totalArticlesMapData;

  const topThemeRes = response?.data;

  const updatedTopSourcedata = topThemeRes?.slice(0, 3)?.map((x, i) => {
    const colorIndex = i % topThemeColors.length;
    return {
      ...topThemeColors[colorIndex],
      value: x?.article_count === 0 ? 1 : x?.article_count,
      secondaryValue: x.secondary_theme,
      label: x?.primary_theme,
      thresholdValue: x?.article_count === 0 ? 1 : x?.article_count,
      keyword: x?.keyword,
      labelColor: topThemeColors[colorIndex],
    };
  });
  topThemeChartMapData.data.data = updatedTopSourcedata || [];
  topThemeChartMapData.shouldShowGraph = topThemeRes?.length > 0;
  topThemeChartMapData.originalData = response;
  // topThemeChartMapData.shouldShowGraph = true;

  if (response) {
    topThemeChartMapData.originalData = response;
  }

  return topThemeChartMapData;
};

export const useAdvancedCampaignTopThemes = (
  filters,
  advancedOptions,
  isEnabled
) => {
  return useQuery({
    queryKey: ['dashboard-advanced_themes', filters, advancedOptions],
    queryFn: () => getTopThemes(filters, advancedOptions),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled,
  });
};

// sentiments trend
const getSentimentOverTime = async (filters, communicationData) => {
  const queryString = `${objectToQueryString({
    ...filters,
    keyword: communicationData.keywords,
  })}`;
  const { data: response } = await get(
    `${API}/dashboard-advanced/campaign-monitor/sentiment-over-time?${queryString}`,
    {}
  );
  const mapData = JSON.parse(JSON.stringify(sentimentOverTime));

  const dataFromRes = response?.data;
  const labels = mapData.data.labels;
  const udpatedDataFromRes = dataFromRes?.map((x) => {
    const formattedDate = getFormattedDate(x?.date ?? x.label);
    const data = updateArrayToObject(
      x?.sentiment_count,
      'key',
      'doc_count',
      labels
    );

    return {
      label: formattedDate,
      ...data,
      date: x?.date ?? x.label,
      timeline: x.label,
    };
  });

  const totalArticlesMapData = mapData.data.summary;
  totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
  mapData.data.summary = totalArticlesMapData;
  mapData.data.data = udpatedDataFromRes || [];
  mapData.shouldShowGraph = response?.total_count;
  if (response) {
    mapData.originalData = response;
  }

  return mapData;
};

export const UseAdvancedCampaignSentimentOverTime = (
  filters,
  advancedOptions,
  isEnabled
) => {
  return useQuery({
    queryKey: [
      'dashboard-advanced_campaign_sentiment_overtime',
      filters,
      advancedOptions,
    ],
    queryFn: () => getSentimentOverTime(filters, advancedOptions),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled,
  });
};

// top authors

const getTopAuthorChartData = async (filters, communicationData) => {
  const queryString = `${objectToQueryString({
    ...filters,
    keyword: communicationData.keywords,
  })}`;
  let { data: response } = await get(
    `${API}/dashboard-advanced/campaign-monitor/top-authors?${queryString}`,
    {}
  );

  if (!response) {
    response = {};
  }

  const topAuthorChartMapData = JSON.parse(
    JSON.stringify(tempTopAuthorChartMapData)
  );

  const totalArticlesMapData = topAuthorChartMapData.data.summary;
  totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
  topAuthorChartMapData.data.summary = totalArticlesMapData;

  const topAuthorRes = response?.authors;

  const updatedTopSourcedata = topAuthorRes
    ?.slice(0, 10)
    ?.filter((x) => Number(x?.count) !== 0)
    ?.map((x, i) => {
      return {
        author_id: x?.author_id,
        value: x?.count,
        label: x?.author,
        thresholdValue: x?.count,
        color: '#22B9FF',
        labelColor: blueColorGradients.blue60,
      };
    });

  topAuthorChartMapData.data.data = updatedTopSourcedata || [];
  topAuthorChartMapData.shouldShowGraph = topAuthorRes?.length > 0;
  if (response) {
    topAuthorChartMapData.originalData = response;
  }
  return topAuthorChartMapData;
};

export const useAdvancedCampaignTopAuthorChartData = (
  filters,
  advancedOptions,
  isEnabled
) => {
  return useQuery({
    queryKey: ['top-author-charts-campaign', filters, advancedOptions],
    queryFn: () => getTopAuthorChartData(filters, advancedOptions),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled,
  });
};

export const getTopSourceChartData = async (filters, communicationData) => {
  const updatedFilters = convertArraysToStrings(filters);
  const updatedData = {
    campaign1: {
      ...updatedFilters,
      campaign_name: communicationData
        ?.map(({ name, keywords }) => ({ name, keyword: keywords }))
        .filter(({ keyword }) => keyword),
    },
  };
  let { data: response } = await axiosPost(
    `${API}/dashboard-advanced/campaign-monitor/breakdown-media-type`,
    [updatedData]
  );

  if (!response) {
    response = {};
  }

  const topSourceChartMapData = JSON.parse(
    JSON.stringify(TempTopSourceChartMapData)
  );

  const totalArticlesMapData = topSourceChartMapData.data.summary;
  totalArticlesMapData.value = String(
    addCountPrefix(response?.overall_total_count)
  );
  topSourceChartMapData.data.summary = totalArticlesMapData;

  const topSourceRes = response?.campaigns[0]?.campaign1?.data;

  const updatedTopSourceData = topSourceRes?.slice(0, 10)?.map((x, i) => {
    return {
      value: x?.doc_count,
      label: x?.media_type,
      color: purpleColorGradients.purple60,
      labelColor: purpleColorGradients.purple60,
    };
  });

  // Apply the gradient colors after sorting
  const gradientColors = [
    purpleColorGradients.purple70,
    purpleColorGradients.purple60,
    purpleColorGradients.purple50,
    purpleColorGradients.purple40,
  ];

  const maxGradientIndex = gradientColors.length - 1;

  const colorCodedData = colorCodeObjects(updatedTopSourceData)?.map(
    (item, index) => {
      // Use gradient based on the index, default to purple40 for remaining items
      const gradientColor =
        index <= maxGradientIndex
          ? gradientColors[index]
          : purpleColorGradients.purple40;
      return {
        ...item,
        color: gradientColor,
      };
    }
  );

  topSourceChartMapData.data.data = colorCodedData || [];
  topSourceChartMapData.shouldShowGraph = topSourceRes?.length > 0;
  if (response) {
    topSourceChartMapData.originalData = response;
  }
  return topSourceChartMapData;
};

export const useAdvancedCampaignOutletChartData = (
  filters,
  advancedOptions,
  isEnabled
) => {
  return useQuery({
    queryKey: ['outlet-advanced-campaign', filters, advancedOptions],
    queryFn: () => getTopSourceChartData(filters, advancedOptions),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled,
  });
};

export const getWordCloudChartData = async (filters, communicationData) => {
  const queryString = `${objectToQueryString({
    ...filters,
    keyword: communicationData.keywords,
  })}`;
  let { data: response } = await get(
    `${API}/search-visuals/wordcloud-count?${queryString}`,
    {}
  );

  if (!response) {
    response = {};
  }

  const wordCloudChartMapData = JSON.parse(
    JSON.stringify(TempWordCloudChartMapData)
  );

  const totalArticlesMapData = wordCloudChartMapData.data.summary;
  totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
  wordCloudChartMapData.data.summary = totalArticlesMapData;

  const worldCloudFromRes = response?.data;
  const counts = {
    PERSONS: 0,
    COMPANIES: 0,
    LOCATIONS: 0,
    HASHTAGS: 0,
    OTHERS: 0,
  };
  const legendsConfig = {
    PERSONS: { label: 'People', color: magentaColorGradients.magenta60 },
    COMPANIES: { label: 'Organizations', color: purpleColorGradients.purple60 },
    LOCATIONS: { label: 'Locations', color: orangeColorGradients.orange60 },
    HASHTAGS: { label: 'Hashtags', color: cyanColorGradients.cyan50 },
    OTHERS: { label: 'Others', color: tealColorGradients.teal50 },
  };
  const updatedWordCloudData = worldCloudFromRes
    ?.filter((x) => {
      return x?.type !== 'LOCATIONS';
    })
    ?.map((x, i) => {
      if (x?.type in counts) {
        counts[x.type] += 1;
      }
      return {
        value: x?.article_count,
        thresholdValue: x?.count,
        label: x?.label,
        labelColor: WordCloudColors[x?.type],
        article_count: x?.article_count,
        type: x?.type,
        color: WordCloudColors[x?.type],
      };
    });
  const legends = Object.keys(counts)
    .filter((key) => counts[key] > 0)
    .map((key) => legendsConfig[key]);

  wordCloudChartMapData.legends = legends;
  wordCloudChartMapData.data.data = updatedWordCloudData || [];
  wordCloudChartMapData.shouldShowGraph = response?.data?.length > 0;
  if (response) {
    wordCloudChartMapData.originalData = response;
  }
  return wordCloudChartMapData;
};

export const useAdvancedCampaignWordCloudChartData = (
  filters,
  advancedOptions,
  isEnabled
) => {
  return useQuery({
    queryKey: ['word-cloud-advanced-campaign', filters, advancedOptions],
    queryFn: () => getWordCloudChartData(filters, advancedOptions),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled,
  });
};

export const getAdvanceComparisonReachByCompaign = async (
  filters,
  communicationData
) => {
  const n = communicationData.length;
  const updatedData = [];
  for (let i = 0; i < n; i++) {
    const commKeyword = communicationData[i]?.name;

    // Ensure communicationData[i] is an array before using map
    const campaignNameData = communicationData[i];

    updatedData.push({
      [commKeyword]: {
        ...convertArraysToStrings(filters[i]),
        campaign_name: [
          { name: campaignNameData?.name, keyword: campaignNameData?.keywords },
        ],
      },
    });
  }
  const { data: response } = await axiosPost(
    `${API}/dashboard-advanced/campaign-monitor/reach-over-time`,
    updatedData
  );

  const mapData = JSON.parse(JSON.stringify(reachByCampaign));

  const dataFromRes = response?.campaigns;
  const labels = mapData.data.labels;
  const resdata = [];
  let coloridx = 0;
  const mergedCampaigns = dataFromRes.reduce((acc, campaignObj) => {
    const campaignKey = Object.keys(campaignObj)[0];
    const campaignData = campaignObj[campaignKey].data;
    mapData?.data?.labels.push({
      label: campaignKey,
      value: String(campaignKey),
      color: CampaignMonitor[coloridx++],
    });
    campaignData.forEach(({ label, sum_reach }) => {
      if (!acc[label]) {
        acc[label] = { label };
      }
      acc[label][campaignKey] = sum_reach;
    });

    return acc;
  }, {});

  const totalArticlesMapData = mapData.data.summary;
  totalArticlesMapData.value = String(
    addCountPrefix(response?.overall_total_count)
  );
  mapData.data.summary = totalArticlesMapData;
  mapData.data.data = Object.values(mergedCampaigns) || [];
  mapData.shouldShowGraph = response?.total_count;
  if (response) {
    mapData.originalData = response;
  }

  return mapData;
};

export const UseAdvancedCampaignComparisonReachByCampaign = (
  filters,
  advancedOptions,
  isEnabled
) => {
  return useQuery({
    queryKey: ['reach-by-campaign-advanced-campaign', filters, advancedOptions],
    queryFn: () =>
      getAdvanceComparisonReachByCompaign(filters, advancedOptions),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled,
  });
};

export const getAdvanceComparisonCampaignAnalysis = async (
  filters,
  communicationData
) => {
  const n = communicationData.length;
  const updatedData = [];
  for (let i = 0; i < n; i++) {
    const commKeyword = communicationData[i]?.name;

    // Ensure communicationData[i] is an array before using map
    const campaignNameData = communicationData[i];

    updatedData.push({
      [commKeyword]: {
        ...convertArraysToStrings(filters[i]),
        campaign_name: [
          { name: campaignNameData?.name, keyword: campaignNameData?.keywords },
        ],
      },
    });
  }
  const { data: response } = await axiosPost(
    `${API}/dashboard-advanced/campaign-monitor-v2`,
    updatedData
  );

  const mapData = JSON.parse(JSON.stringify(compaignMapData));
  const newArray = [];
  const campaigns = response?.campaigns;
  const total_count = response?.overall_total_count;
  let idx = 0;
  campaigns.forEach((campaignObj, camp_idx) => {
    const campaignName = Object.keys(campaignObj)[0];
    const campaignData = campaignObj[campaignName].data;

    const totalCountCampaign = campaignData.reduce(
      (a, c) => a + c.campaign_count,
      0
    );

    const colorIndex = idx % CampaignMonitor.length;

    campaignData.forEach((dataPoint, index) => {
      const xValue = dataPoint.net_sentiment;
      const yValue = dataPoint.campaign_reach;
      const radius = Number(
        (dataPoint.campaign_count / total_count).toFixed(2)
      );
      const articleCount = dataPoint.campaign_count;

      const newObject = {
        label: dataPoint.label,
        xValue: parseFloat(xValue.toFixed(2)),
        yValue,
        radius: (radius / 2).toFixed(2).toString(),
        color: CampaignMonitor[colorIndex],
        keyword: dataPoint.keyword,
        articleCount,
        recentSearchId: filters[camp_idx]?.recent_search_id,
      };

      newArray.push(newObject);
    });
    idx++;
  });

  const totalArticlesMapData = mapData.data.summary;
  totalArticlesMapData.value = String(
    addCountPrefix(response?.overall_total_count)
  );

  mapData.data.summary = totalArticlesMapData;
  mapData.data.data = newArray || [];
  if (response) {
    mapData.originalData = response;
  }

  return mapData;
};

export const UseAdvancedCampaignComparisonCampaignAnalysis = (
  filters,
  advancedOptions,
  isEnabled
) => {
  return useQuery({
    queryKey: ['campaign-analysis-advanced-campaign', filters, advancedOptions],
    queryFn: () =>
      getAdvanceComparisonCampaignAnalysis(filters, advancedOptions),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled,
  });
};

export const getAdvanceComparisonVolDistributionByChannel = async (
  filters,
  communicationData
) => {
  const n = communicationData.length;

  const updatedData = [];
  for (let i = 0; i < n; i++) {
    const commKeyword = communicationData[i]?.name;

    // Ensure communicationData[i] is an array before using map
    const campaignNameData = communicationData[i];

    updatedData.push({
      [commKeyword]: {
        ...convertArraysToStrings(filters[i]),
        campaign_name: [
          { name: campaignNameData?.name, keyword: campaignNameData?.keywords },
        ],
      },
    });
  }
  const { data: response } = await axiosPost(
    `${API}/dashboard-advanced/campaign-monitor/breakdown-media-type`,
    updatedData
  );

  const mapData = JSON.parse(JSON.stringify(volumeDistByChannel));

  const totalArticlesMapData = mapData.data.summary;
  totalArticlesMapData.value = String(
    addCountPrefix(response?.overall_total_count)
  );
  mapData.data.summary = totalArticlesMapData;
  const processed_arr = [];
  const sumArr = [];
  let totalSum = 0;
  response?.campaigns.forEach((campaignObj, camp_idx) => {
    const campaignName = Object.keys(campaignObj)[0];
    const campaignData = campaignObj[campaignName].data;
    let valueSum = 0;
    const updatedCampaignData = campaignData?.slice(0, 10)?.map((x, i) => {
      valueSum += x?.doc_count;
      return {
        value: x?.doc_count,
        label: x?.media_type,
        color: purpleColorGradients.purple60,
        labelColor: purpleColorGradients.purple60,
        recentSearchId: filters[camp_idx]?.recent_search_id,
      };
    });

    processed_arr.push({
      [campaignName]: updatedCampaignData,
    });
    sumArr.push(valueSum);
    totalSum += valueSum;
  });

  // Apply the gradient colors after sorting
  const gradientColors = [
    [
      purpleColorGradients.purple70,
      purpleColorGradients.purple60,
      purpleColorGradients.purple50,
      purpleColorGradients.purple40,
    ],
    [
      magentaColorGradients.magenta70,
      magentaColorGradients.magenta60,
      magentaColorGradients.magenta50,
      magentaColorGradients.magenta40,
    ],

    [
      tealColorGradients.teal70,
      tealColorGradients.teal60,
      tealColorGradients.teal50,
      tealColorGradients.teal40,
    ],

    [
      yellowColorGradients.yellow70,
      yellowColorGradients.yellow60,
      yellowColorGradients.yellow50,
      yellowColorGradients.yellow40,
    ],

    [
      orangeColorGradients.orange70,
      orangeColorGradients.orange60,
      orangeColorGradients.orange50,
      orangeColorGradients.orange40,
    ],
  ];
  let clrIdx = 0;
  const colorProcessedArr = [];
  const maxGradientIndex = gradientColors[0].length - 1;
  processed_arr.map((camp, idx) => {
    const campaignName = Object.keys(camp)[0];
    const arr = camp[campaignName];

    const colorProccedData = colorCodeObjects(arr)?.map((item, index) => {
      // Use gradient based on the index, default to purple40 for remaining items
      const gradientColor =
        index <= maxGradientIndex
          ? gradientColors[clrIdx][index]
          : gradientColors[clrIdx][maxGradientIndex];
      return {
        ...item,
        color: gradientColor,
        labelColor: gradientColor,
      };
    });

    colorProcessedArr.push({
      [campaignName]: colorProccedData,
    });
    clrIdx++;
  });

  mapData.data.data = colorProcessedArr || [];
  mapData.data.sumArr = sumArr;
  mapData.data.total_articles = totalSum;
  mapData.shouldShowGraph = colorProcessedArr?.length > 0;
  if (response) {
    mapData.originalData = response;
  }
  return mapData;
};

export const useAdvancedCampaignVolDistributionByChannelChartData = (
  filters,
  advancedOptions,
  isEnabled
) => {
  return useQuery({
    queryKey: [
      'campaign-analysis-advanced-volume-distribution-by-channel',
      filters,
      advancedOptions,
    ],
    queryFn: () =>
      getAdvanceComparisonVolDistributionByChannel(filters, advancedOptions),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled,
  });
};

export const getAdvanceComparisonSentiment = async (
  filters,
  communicationData
) => {
  const n = communicationData.length;

  const updatedData = [];
  for (let i = 0; i < n; i++) {
    const commKeyword = communicationData[i]?.name;

    // Ensure communicationData[i] is an array before using map
    const campaignNameData = communicationData[i];

    updatedData.push({
      [commKeyword]: {
        ...convertArraysToStrings(filters[i]),
        campaign_name: [
          { name: campaignNameData?.name, keyword: campaignNameData?.keywords },
        ],
      },
    });
  }
  const { data: response } = await axiosPost(
    `${API}/dashboard-advanced/campaign-monitor/breakdown-by-sentiment`,
    updatedData
  );

  const mapData = JSON.parse(JSON.stringify(campaignSentiment));

  const newArr = [];
  response?.campaigns.forEach((campaignObj, camp_idx) => {
    const campaignName = Object.keys(campaignObj)[0];
    const item = campaignObj[campaignName];

    const obj = {
      label: campaignName,
      neg: item.sentiment_negative.toString(),
      pos: item.sentiment_positive.toString(),
      neu: item.sentiment_neutral.toString(),
      recentSearchId: filters[camp_idx]?.recent_search_id,
    };

    newArr.push(obj);
  });

  mapData.data.summary.value = String(
    addCountPrefix(response?.overall_total_count)
  );
  mapData.data.data = newArr || [];

  if (response) {
    mapData.originalData = response;
  }

  return mapData;
};

export const useAdvancedCampaignSentimentChartData = (
  filters,
  advancedOptions,
  isEnabled
) => {
  return useQuery({
    queryKey: [
      'campaign-analysis-advanced-sentiment',
      filters,
      advancedOptions,
    ],
    queryFn: () => getAdvanceComparisonSentiment(filters, advancedOptions),
    refetchOnWindowFocus: false,
    enabled: !!isEnabled,
  });
};
