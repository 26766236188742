import {
  topAdvocatesChartMapData as tempTopAdvocatesChartMapData,
  topDetractorsChartMapData as tempTopDetractorsChartMapData,
  contentType as tempContentType,
  sentimeOverTime,
  resultOveTime,
  topThemeChartMapData as TempTopThemeChartMapData,
  totalOverviewSocialDetailsMap as TempTotalOverviewDetails,
} from '../../hooks/data/advancedSocialCampaign';
import { addCountPrefix } from '../../constants/utils';
import twitterLogo from '../../assets/img/twitterLogo.png';
import redditLogo from '../../assets/img/reddit.png';
import {
  blueColorGradients,
  purpleColorGradients,
} from '../../constants/graph-colors';
import { getFormattedDate } from '../../hooks/useCharts';
import { concentricPie } from '../../graphs/utils/graphConst';
import {
  transformDocCountData,
  updateArrayToObject,
} from '../../hooks/useAdvancedSocialCampaign';

export function social_campaign_mapping(response, graphType) {
  if (graphType === 'advocates') {
    const advocatesChartMapData = JSON.parse(
      JSON.stringify(tempTopAdvocatesChartMapData)
    );

    const totalArticlesMapData = advocatesChartMapData.data.summary;
    totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
    advocatesChartMapData.data.summary = totalArticlesMapData;

    const updatedTopAdvocateData = response?.data?.slice(0, 10)?.map((x, i) => {
      return {
        author_id: x?.id,
        value: x?.total_posts,
        name: x?.name,
        color: '#DEFBE6',
        total_posts: x?.total_posts,
        total_engagements: x?.total_engagements,
        media_type: x?.media_type,
        total_reach: x?.total_reach,
        logoUrl:
          x?.media_type === 'Reddit'
            ? redditLogo
            : x?.media_type === 'X (Twitter)'
            ? twitterLogo
            : '',
      };
    });

    advocatesChartMapData.data.data = updatedTopAdvocateData || [];
    advocatesChartMapData.shouldShowGraph = response?.data?.length > 0;
    return advocatesChartMapData;
  } else if (graphType === 'detractors') {
    const detractorsChartMapData = JSON.parse(
      JSON.stringify(tempTopDetractorsChartMapData)
    );

    const totalArticlesMapData = detractorsChartMapData.data.summary;
    totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
    detractorsChartMapData.data.summary = totalArticlesMapData;

    const updatedTopDetractorData = response?.data
      ?.slice(0, 10)
      ?.map((x, i) => {
        return {
          author_id: x?.id,
          color: '#FFF1F1',
          value: x?.total_posts,
          name: x?.name,
          total_posts: x?.total_posts,
          total_engagements: x?.total_engagements,
          media_type: x?.media_type,
          total_reach: x?.total_reach,
          logoUrl:
            x?.media_type === 'Reddit'
              ? redditLogo
              : x?.media_type === 'X (Twitter)'
              ? twitterLogo
              : '',
        };
      });

    detractorsChartMapData.data.data = updatedTopDetractorData || [];
    detractorsChartMapData.shouldShowGraph = response?.data?.length > 0;
    return detractorsChartMapData;
  } else if (graphType === 'advanced_result_over_time') {
    const mapData = JSON.parse(JSON.stringify(resultOveTime));

    const totalArticlesMapData = mapData.data.summary;

    totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
    mapData.data.summary = totalArticlesMapData;
    mapData.data.data = response?.data?.map((x) => {
      const formattedDate = getFormattedDate(x?.date);
      return {
        ...x,
        label: formattedDate,
        gucci: x.doc_count,
        date: x?.date,
        color: purpleColorGradients?.purple60,
        timeline: x.label,
      };
    });
    mapData.data.summary.value = String(addCountPrefix(response?.total_count));
    mapData.shouldShowGraph = response?.data?.some(
      (items) => items.value !== 0
    );
    return mapData;
  } else if (graphType === 'advanced_top_themes') {
    const topThemeChartMapData = JSON.parse(
      JSON.stringify(TempTopThemeChartMapData)
    );

    const totalArticlesMapData = topThemeChartMapData.data.summary;
    totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
    topThemeChartMapData.data.summary = totalArticlesMapData;

    const topThemeRes = response?.data;

    const updatedTopSourcedata = topThemeRes?.slice(0, 3)?.map((x, i) => {
      const colorIndex = i % concentricPie.length;
      return {
        ...concentricPie[colorIndex],
        value: x?.article_count === 0 ? 1 : x?.article_count,
        secondaryValue: x.secondary_theme,
        label: x?.primary_theme,
        thresholdValue: x?.article_count === 0 ? 1 : x?.article_count,
        keyword: x?.keyword,
        labelColor: concentricPie[colorIndex],
      };
    });
    topThemeChartMapData.data.data = updatedTopSourcedata || [];
    topThemeChartMapData.shouldShowGraph = topThemeRes?.length > 0;
    topThemeChartMapData.originalData = response;
    // topThemeChartMapData.shouldShowGraph = true;
    return topThemeChartMapData;
  } else if (graphType === 'advanced_campaign_engagement') {
    const transformedData = transformDocCountData(response?.data);
    const finalData = {
      data: transformedData,
      title: 'Engagement by Time and Weekday',
      summary: { value: String(addCountPrefix(response?.total_count)) },
      //   keyword: communicationData?.[0]?.keywords,
    };
    return finalData;
  } else if (graphType === 'advanced_campaign_content_type') {
    const contentTypeData = JSON.parse(JSON.stringify(tempContentType));

    contentTypeData.data.data = [
      {
        label: 'Photos',
        value: response?.data?.images,
        labelColor: blueColorGradients.blue60,
      },
      {
        label: 'Links',
        value: response?.data?.link,
        labelColor: blueColorGradients.blue60,
      },
      {
        label: 'Text',
        value: response?.data?.text,
        labelColor: blueColorGradients.blue60,
      },
      {
        label: 'Video',
        value: response?.data?.video,
        labelColor: blueColorGradients.blue60,
      },
    ].filter((item) => item.value > 0);

    contentTypeData.data.summary.value = String(
      addCountPrefix(response?.total_count)
    );
    contentTypeData.shouldShowGraph = response.total_count > 0;

    return contentTypeData;
  } else if (graphType === 'advanced_campaign_sentiment_over_time') {
    const mapData = JSON.parse(JSON.stringify(sentimeOverTime));

    const dataFromRes = response?.data;
    const labels = mapData.data.labels;
    const udpatedDataFromRes = dataFromRes?.map((x) => {
      const formattedDate = getFormattedDate(x?.date ?? x.label);
      const data = updateArrayToObject(
        x?.sentiment_count,
        'key',
        'doc_count',
        labels
      );

      return {
        label: formattedDate,
        ...data,
        date: x?.date ?? x.label,
        timeline: x.label,
      };
    });

    const totalArticlesMapData = mapData.data.summary;
    totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
    mapData.data.summary = totalArticlesMapData;
    mapData.data.data = udpatedDataFromRes || [];
    mapData.shouldShowGraph = response?.total_count;

    return mapData;
  }
}
