/* eslint-disable array-callback-return */
// import React from 'react';
// import { ArticleComponent } from 'article-component';
// import ArticleImg from './assets/img2.jpg';
// import {
//   SectionSubContent,
//   SentimentContainer,
// } from 'article-component/src/index.sc';

// const title =
//   'Few Parents intend to have very Young Children Vaccinated Against Covid';

// const content =
//   'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Deleniti magnam aspernatur perspiciatis, minus ex est quis. Consequatur dolor earum unde ex sit labore iste, praesentium mollitia. Ullam non possimus totam? Eum sapiente vel nisi minima itaque! Consequatur laborum odio fugiat nisi, quas at non impedit. Deleniti, fugiat ea, ex, sint omnis culpa alias esse quidem natus ducimus maiores. Ducimus, enim? Doloribus adipisci soluta earum labore ut quis aliquid quas eos esse. Minus quod quidem obcaecati, cum, tempora ad ex expedita quis in voluptatibus sit sequi deleniti? Consectetur qui veniam aperiam Omnis, culpa. Eum aperiam modi consequuntur? Quae nulla rerum sit commodi. Ad commodi doloremque suscipit praesentium, soluta ullam, consequatur tempore, ut animi natus officia dolore hic voluptate sit odio reiciendis';

// const footerDescription = [
//   {
//     name: 'www.wsj.com',
//     url: 'http://www.wsj.com',
//   },
//   {
//     name: 'Publised on 05 June 2021',
//   },
//   {
//     name: 'New York, US',
//   },
//   {
//     name: '12 Matching Keywords',
//   },
//   {
//     name: 'Online News',
//   },
// ];
// // const footerDescription =
// //   "www.wsj.com | Published on 05 June 2021 | New York, US | 12 Matching Keywords | Online News";
// const info = [
//   { heading: 'Matches', value: 'Covid Vaccine' },
//   { heading: 'Reach', value: '1.6M' },
//   { heading: 'AVE', value: '~ $1.5M' },
//   { heading: 'Syndication', value: '1.6M' },
// ];
// const menuOptions = [
//   'Option1',
//   'Option2',
//   'Option3',
//   'Option4',
//   'Option5',
//   'Option6',
//   'Option7',
// ];

// const FooterComponent = ({ footerContent = [] }) => {
//   return (
//     <SectionSubContent>
//       {footerContent.map((content, i) => {
//         return (
//           <span key={i}>
//             {content.name} {' | '}
//           </span>
//         );
//       })}
//     </SectionSubContent>
//   );
// };

// const handleAction = (value) => {
//   console.log(value, ' -- value');
// };

// const ArticleComponentViewer = () => {
//   return (
//     <div style={{ width: '42.93rem', height: '220px' }}>
//       <ArticleComponent
//         title={title}
//         content={content}
//         footer={<FooterComponent footerContent={footerDescription} />}
//         articleImg={ArticleImg}
//         info={info}
//         menuOptions={menuOptions}
//         handleAction={handleAction}
//         sentiment={<SentimentComponent icon="😊" sentiment="Positive" />}
//       />
//     </div>
//   );
// };
// export default ArticleComponentViewer;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components/macro';
import { Link, useLocation, useParams } from 'react-router-dom';
import Comment from '../../assets/icons/Comment';
import { Bottomkeys, bottomDetails, eventNames, reader } from '../../constants';
import SentimentComponent from '../sentiment';
import TagIcon from '../../assets/icons/TagIcon';
// import ThemeIcon from '../../assets/icons/ThemeIcon';
import { useSelector } from 'react-redux';
import { theme } from '../../constants/theme';
// import GrowthIcon from '../../assets/icons/GrowthIcon';
import CommentBox from './hover-poovers/CommentBox';
import Popover from './hover-poovers';
import TagBox from './hover-poovers/TagBox';
// import CheckboxIcon from '../../assets/icons/CheckboxIcon';
// import CheckboxV2 from '../../assets/icons/CheckboxV2';
import KeywordPopover from '../keyword-popover';
import CheckboxIcon from '../../assets/icons/CheckboxIcon';
import { capitalizeFirstWord, formatNumber, logAwsRumEvent } from '../../utils';
import PortalTooltip from '../portal-tooltip';
import Tooltip from '../icon-tooltip';
import BookMarkIcon2 from '../../assets/icons/BookMarkIcon2';
import toast from 'react-hot-toast';
import { axiosPostRequestAPI, axiosDeleteAPI } from '../../service';
import { useMutation } from '@tanstack/react-query';
import ArticleImageNAImg from '../../assets/img/NA for no image.svg';
import { VerticleDots } from '../../assets/icons/VerticleDots';
import SimpleReusableDropDown from '../simple-dropdown';
import { debounce } from '../../constants/debounce';
import ThreeDotsLoader from '../three-dots-loader';
import AiLoaderV2 from '../ai-loaderv2';
// import AiLoader from '../ai-loader';
// import AiIcon from '../../assets/icons/AiIcon';
import AiIcon from '../../assets/icons/AI';
import Markdown from 'react-markdown';
import {
  useCreateTagsBySearchId,
  useDeleteArticleTagBySearchId,
  useGetArticleSummary,
  // useGetCreateCommentBySearchId,
  // useGetCreateTagBySearchId,
  useHiddenArticlesByArticleId,
  useUnHideArticlesById,
} from '../../hooks/useSearch';
// import { getTokenData } from '../../constants/validateToken';
import {
  List,
  InfiniteLoader,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  WindowScroller,
} from 'react-virtualized';
import { addCountPrefix } from '../../constants/utils';
import TagCloseIcon from '../../assets/icons/TagCloseIcon';
import infoIcon from '../../assets/img/infoIcon.svg';
import ReachTooltip from '../tooltip';
import AllMatchIcon from '../../assets/icons/AllMatchIcon';
import RedditIcon from '../../assets/icons/reddit.svg';
import TwitterIcon from '../../assets/icons/twitter.svg';
import { getTokenData } from '../../constants/validateToken';
import * as Sentry from '@sentry/react';
import { coolGrayColorGradients } from '../../constants/graph-colors';
import DefaultProfileImage from '../../assets/icons/defaultSMProfilePic.svg';
import DefaultSMIcon from '../../assets/icons/defaultSMIcon.svg';
import Loader from '../loader';
import Spinner from '../spinner';
import { LoadingWrapper } from '../uber-tiles/index.sc';
import CircularLoading from '../../assets/icons/loading/circularLoading';
import SocialAnalysisIcon from '../../assets/icons/SocialAnalysisIcon';
import { trackEvent } from '../../utils/mixPanel';

const ArticlesWrp = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: ${({ storyAnalysis }) => (storyAnalysis ? '15rem' : '106vh')};
  overflow: auto;
  padding-bottom: ${({ isPopup }) => (isPopup ? '1.3rem' : '0rem')};
  justify-content: space-between;
  ${({ articleView }) =>
    articleView &&
    css`
      width: 100%;
      gap: 1rem;
      flex-direction: row;
      flex-wrap: wrap;
    `}

  &::-webkit-scrollbar {
    display: none;
  }
`;

const ArticleMainWrp = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  /* border-bottom: 1px solid #ccc; */
  /* padding-bottom: 0.25rem; */
  ${({ articleView }) =>
    articleView &&
    css`
      display: flex;
      gap: 1rem;
      width: calc(50% - 1rem);
      min-height: 13rem;
    `}/* &:hover > .article-bottom {
    opacity: 1;
  } */
`;
const ArticleWrp = styled.div`
  display: flex;
  gap: 1rem;
  position: relative;
  justify-content: space-between;
  width: 100%;
`;

const ArticlewrpBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 0.75rem;
  padding: 0.5rem 1rem 0rem 1rem;
`;

export const ArticleAndIconWrp = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const ArticleLinkSection = styled.div`
  display: flex;
  gap: 0.6rem;
  padding: 0 0 0.8rem 0;
`;

const ArticleWrpL = styled.div`
  margin-left: auto;
  width: 6.8125rem;
  height: 6.8125rem;
  ${({ articleView }) =>
    articleView &&
    css`
      width: 7rem;
      height: 7rem;
    `}
`;
const ArticleWrpR = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  /* ${({ articleView }) =>
    articleView &&
    css`
      width: calc(100% - 8rem);
    `} */
`;

const ArticlewprZ = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-bottom: 0.6rem;
  border-bottom: 1px solid #ccc;
`;

// const ArticleImg = styled.div`
//   width: 100%;
//   height: 100%;
//   background-image: ${({ src }) => `url(${src})`};
//   background-size: cover;
//   background-position: inherit;
// `;
const ArticleTextImgWrp = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
`;

const ArticleTitletWrp = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
`;

const ArticleTitle = styled(Link)`
  font-weight: 600;
  display: -webkit-box;
  font-size: ${({ mainTitle }) =>
    mainTitle ? '11px' : '1rem'}; /* Conditional font size */

  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Inter;
  font-style: normal;
  line-height: 1.5rem;
  letter-spacing: -0.32px;
  text-decoration: none;
  color: black;
  font-weight: 700;
  &:hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.primary};
    cursor: pointer;
  }
`;
const ArticleBody = styled.div`
  font-size: 0.8rem;
  color: ${coolGrayColorGradients.coolGray70};
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: 1.1875rem;
  letter-spacing: -0.24px;
`;

// Animation for dots
const dotAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(0);
  }
  50% {
    opacity: 1;
    transform: translateY(-5px);
  }
  100% {
    opacity: 0;
    transform: translateY(0);
  }
`;

const DotsContainer = styled.div`
  display: flex;
  position: absolute;
  bottom: 0; // Adjust position if necessary
  left: 0;
  right: 0;
  text-align: center;
  pointer-events: none; // Ensure that the dots don't interfere with clicking
`;

const Dot = styled.span`
  display: inline-block;
  margin: 0 2px;
  width: 6px;
  height: 6px;
  background-color: ${coolGrayColorGradients.blue};
  border-radius: 50%;
  animation: ${dotAnimation} 1s infinite;

  &:nth-child(1) {
    animation-delay: 0s;
  }
  &:nth-child(2) {
    animation-delay: 0.2s;
  }
  &:nth-child(3) {
    animation-delay: 0.4s;
  }
`;

const ArticleLinkWrp = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.3rem;
  width: 100%;
  flex-wrap: wrap;
`;

const Articlekeytxt = styled.div`
  text-decoration: none;
  margin-right: 0.1rem;
  color: ${({ theme }) => theme.closeButton};
  font-weight: 500;
  font-size: 11px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 8rem;
  font-family: Inter;
  font-style: normal;
  line-height: 1rem;
  letter-spacing: -0.24px;
`;

const ArticleIcon = styled.img`
  width: 2rem;
  height: 2rem;
  object-fit: cover;
  border-radius: 50%;
`;

const IconWrapper = styled.div`
  position: relative;
  width: 2rem;
  height: 2rem;
`;

const BadgeIcon = styled.img`
  position: absolute;
  top: -0.2rem;
  left: -0.2rem;
  width: 1rem;
  height: 1rem;
`;

const ArticleDefaultIcon = styled.div`
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #c3cce8; */
  background: linear-gradient(180deg, #b5c1e5 0%, #a8afce 100%);
  color: white;
  border-radius: 6px;
  text-transform: uppercase;
`;

const BoxArticlewpr = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const ArticlePressTitle = styled.h1`
  font-weight: 600;
  display: -webkit-box;
  font-size: 1rem;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.secondaryText};
  font-family: Inter;
  font-style: normal;
  line-height: 1.5rem;
  letter-spacing: -0.32px;
  text-decoration: none;
  margin: 0;
`;

const ArticleLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.closeButton};
  font-weight: 500;
  font-size: 0.75rem;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 10rem;
  font-family: Inter;
  font-style: normal;
  line-height: 1rem;
  letter-spacing: -0.24px;
  display: block;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const ArticleAuthor = styled.div`
  text-decoration: none;
  margin-right: 0.1rem;
  color: ${({ theme }) => theme.closeButton};
  font-weight: 500;
  font-size: 11px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 8rem;
  font-family: Inter;
  font-style: normal;
  line-height: 1rem;
  letter-spacing: -0.24px;
`;

const ArticleBottomWrp = styled.div`
  /* opacity: ${({ articleView }) => (articleView ? '1' : '0')}; */
  /* position: ${({ articleView }) =>
    articleView ? 'relative' : 'absolute'}; */
  /* width: 1.25rem; */
  /* height: 100%; */
  /* padding: 0.5rem; */
  background: #ffffff;
  ${({ articleView }) =>
    !articleView &&
    css`
      /* bottom: 0; */
      /* border-top: 1px solid #ccc; */
      /* border-bottom: 1px solid #ccc; */
    `}

  display: flex;
  /* flex-direction: column; */
  /* gap: 0.5rem; */
  transition: all 400ms ease-in-out;
  /* position: relative; */
`;
const ArticleTextDetailsWrp = styled.div`
  display: flex;
  gap: 0.25rem 0.5rem;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  /* overflow-x: scroll; */
  &::-webkit-scrollbar {
    display: none;
  }

  ${({ articleView }) =>
    articleView &&
    css`
      flex-wrap: wrap;
      gap: 1rem;
    `}
`;
const IconBoxwpr = styled.div`
  align-items: center;
  gap: 0.125rem;
  display: flex;
  background-color: #fff;
  border-radius: 0.3rem;
  min-width: fit-content;
  width: fit-content;
  overflow: hidden;
  cursor: pointer;
`;
const ArticleActionDetailsWrp = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.75rem;
  justify-content: center;
`;

const SocialIconWrp = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 0.25rem;
  :hover {
    background-color: ${({ theme }) => theme?.primary};
  }
`;

const ArticleTextWrp = styled.div`
  display: flex;
  font-size: 0.75rem;
  align-items: center;
  gap: 0.25rem;

  &:hover {
    cursor: ${({ itemType }) =>
      (itemType === 'syndication' || itemType === 'matches') && 'pointer'};
  }
  /* cursor: pointer;
  &:hover {
    color: #5f39f8;
  }
  &:hover div {
    color: inherit !important; // Inherits the hover color from the parent 
  }
  &:hover span {
    color: inherit !important;  // Inherits the hover color from the parent 
  } */
`;
const AiInsightBtn = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: ${({ theme }) => theme.aiText};
  background: ${({ theme }) => theme.background};
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.greyColor};
  width: fit-content;
  padding: 6px 8px 6px 8px;
  cursor: pointer;
`;
const ArticleTextLabel = styled.div`
  color: ${({ theme, matchingKeyword }) => matchingKeyword || theme.text};
  font-family: Inter;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.26px;
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  /* &:hover {
    color: ${({ matchingKeyword }) => matchingKeyword};
  } */
`;
const ArticleTextValue = styled.div`
  position: relative;
  color: ${({ theme, itemType, matchingKeyword }) =>
    itemType === 'syndication' ? theme.text : matchingKeyword || theme.text};
  font-size: 0.8rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  /* cursor: pointer; */
  /* &:hover {
    color: ${({ matchingKeyword }) => matchingKeyword};
  } */
`;

const SyndicationButton = styled.button`
  color: ${({ theme, isZero }) =>
    isZero ? theme.disabledBtnBackground : theme.primary};
  background: transparent;
  border: none;
  outline: none;
  font-weight: 500;
  padding: 0;
  &:hover {
    cursor: pointer;
    color: #5f39f8;
  }
`;

const KeywordPopoverWrap = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

const ArticleIconWrp = styled.div`
  position: relative;
  cursor: pointer;
  svg {
    width: 100%;
  }
`;
const IconMarker = styled.div`
  position: absolute;
  top: -5px;
  right: 0;
  background-color: ${({ theme }) => theme.primary};
  border-radius: 50%;
  height: 0.4rem;
  width: 0.4rem;
`;

const CheckBoxWrp = styled.div`
  padding-top: 0.2rem;
  &:hover {
    cursor: pointer;
  }
  position: relative;
  &:hover::after {
    content: 'Select';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: ${({ variant }) =>
      variant === 'dark' ? '#000' : '#fff'};
    color: ${({ variant }) => (variant === 'dark' ? '#fff' : '#000')};
    padding: 0.38rem 0.62rem;
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 1.0625rem;
    text-align: left;
    white-space: nowrap;
    border-radius: 0.25rem;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    opacity: 1;
    pointer-events: none;
    overflow: visible;
    background-clip: padding-box;
    transform-origin: center top;
    transition: transform 0.1s ease, opacity 0.1s ease;
  }

  /* Animation for the tooltip appearance */
`;

// const ArticleWrpR = styled.div``;

const ArticleCmtWrp = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0.5rem;
  gap: ${({ bg }) => (bg ? '0.5rem' : '1.5rem')};
  background-color: ${({ bg }) => bg || '#fffeee'};
`;

const ArticleCmt = styled.div`
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 12px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: -0.02em;
  text-align: left;
`;
const ArticleTagWrp = styled.div`
  padding: 0.25rem 0.625rem 0.2rem;
  color: #fff;
  border-radius: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: nowrap;
  /* border: 1px solid; */
  background-color: ${({ theme }) => theme.tagsBorderColor};
  gap: 0.25rem;
  display: flex;
  flex-direction: row;
  height: 1.75rem;
  /* overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: nowrap; */
  align-items: center;
`;
const ArticleTags = styled.div`
  cursor: pointer;
  display: -webkit-box;
  /* padding: 0.25rem 0.625rem 0.2rem; */
  align-items: center;
  font-size: 0.8rem;
  /* height: 1.75rem; */
  font-weight: 500;
  font-family: ${({ theme }) => theme.fontFamily};
  /* color: ${({ theme }) => theme.secondaryText}; */
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  color: #fff;
  border-radius: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: nowrap;
  /* border: 1px solid; */
  background-color: ${({ theme }) => theme.tagsBorderColor};
  gap: 0.25rem;
  span {
    margin-top: 0.2rem;
  }
`;

const TagClosespan = styled.div``;

const AITextWrp = styled.div`
  width: 100%;
  padding: 1rem 1.25rem;
  gap: 0.375;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
`;

const AIText = styled(Markdown)`
  height: auto;
  width: 100%;
  font-family: Inter;
  font-size: 0.75rem;
  font-weight: ${({ ai }) => (!ai ? '500' : '600')};
  line-height: 1.3rem;
  text-align: left;
  color: ${({ ai }) => (!ai ? '#323232' : '#D83665')};
`;

const AIIconWrp = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  align-items: center;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`;

const IconWrp = styled.div`
  font-family: Inter;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.2rem;
  letter-spacing: -0.02em;
  text-align: left;
  gap: 0.25rem;
  cursor: pointer;
`;

const AiScrollWrp = styled.div`
  height: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const TagWrap = styled.div`
  position: relative;

  width: 1.75rem;
  height: 1.75rem;

  &:hover::after {
    content: 'Tag';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: ${({ variant }) =>
      variant === 'dark' ? '#000' : '#fff'};
    color: ${({ variant }) => (variant === 'dark' ? '#fff' : '#000')};
    padding: 0.38rem 0.62rem;
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 1.0625rem;
    text-align: left;
    white-space: nowrap;
    border-radius: 0.25rem;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    opacity: 1;
    pointer-events: none;
    overflow: visible;
    background-clip: padding-box;
  }

  /* Animation for the tooltip appearance */
  &:hover::after {
    opacity: 1;
    visibility: visible;
  }
`;

const IconsPop = ({
  Icon,
  PopContent,
  data,
  width,
  height,
  tooltipText,
  mark,
  setTriggerFetchUseEffect,
  storeArticleCommentsTags,
  setStoreTags,
  setStoreComments,
}) => {
  const textData = () => {
    if (data?.ele?.comment) {
      return data?.ele?.comment;
    } else if (data?.storeComments) {
      const filteredComment = data?.storeComments.filter(
        (comment) => comment?.article_id === data?.article_id
      );
      return filteredComment?.content;
    }
    return null;
  };
  const [show, setShow] = useState(false);
  const [text, setText] = useState('');
  const iconPopRef = useRef(null);

  useEffect(() => {
    setText(textData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.ele]);

  // const selectedTheme = useSelector((store) => {
  //   return store?.theme.theme || {};
  // });

  const handleClickOutside = (event) => {
    if (iconPopRef.current && !iconPopRef.current.contains(event.target)) {
      setShow(false);
      document.removeEventListener('click', handleClickOutside);
    }
  };
  document.addEventListener('click', handleClickOutside);
  const handleShow = (e) => {
    e.stopPropagation();
    setShow(!show);
  };

  return (
    <>
      <ArticleIconWrp onClick={handleShow} ref={iconPopRef}>
        {show && (
          <Popover
            InnerChild={PopContent}
            data={data || {}}
            setShow={setShow}
            setText={setText}
            text={text}
            setTriggerFetchUseEffect={setTriggerFetchUseEffect}
            storeArticleCommentsTags={storeArticleCommentsTags}
            setStoreTags={setStoreTags}
            setStoreComments={setStoreComments}
          />
        )}
        <Tooltip content={tooltipText}>
          <Icon
            color={data?.tagData?.length > 0 || show ? '#cf326b' : '#fff'}
            width={width}
            height={height}
            istrue={data?.commentData || show}
            fill={data?.tagData?.length > 0 || show ? '#cf326b' : '#585858'}
            fill2={data?.tagData?.length > 0 || show ? '#fff' : '#585858'}
          />
        </Tooltip>

        {mark && <IconMarker />}
      </ArticleIconWrp>
    </>
  );
};

IconsPop.propTypes = {
  Icon: PropTypes.any,
  PopContent: PropTypes.any,
  data: PropTypes.any,
  width: PropTypes.string,
  height: PropTypes.string,
  tooltipText: PropTypes.string,
  mark: PropTypes.bool,
  setTriggerFetchUseEffect: PropTypes?.func,
  storeArticleCommentsTags: PropTypes?.func,
  setStoreTags: PropTypes.func,
  setStoreComments: PropTypes.func,
  hiddenArticleTigger: PropTypes.bool,
};

const Articles = ({
  setScrollHeight = () => {},
  syndicationClickData = [],
  articles,
  articleView = false,
  page = 0,
  type = '',
  showTab = false,
  floatingPagination = false,
  handleChecked = () => {},
  checked = false,
  savedSearchId,
  allChecked = false,
  setSydicationActive,
  setSydicationArticles,
  sydicationActive,
  sydicationArticles,
  allHiddenArticles = [],
  articlesRecentSearchId = '',
  setSyndicationClick = () => {},
  setClickedPosition = () => {},
  setTriggerFetchUseEffect = () => {},
  storeArticleCommentsTags = () => {},
  storeComments = [],
  setStoreComments = () => {},
  storeTags = [],
  hiddenArticleTigger = false,
  setHiddenArticle,
  hiddenArticle = [],
  setStoreTags,
  name = '',
  refetchHiddleArticles = () => {},
  showFullTitle = true,
  bookmarksLocal = [],
  hiddenArticlesLocal = [],
  handlePage = () => {},
  setInsearchPageNum = () => {},
  isPopup = false,
  searchKey = '',
  summaryData = {},
  tagActionType,
  showAiSummaryBtn = false,
  fetchAiSummary = false,
  setFetchAiSummary = () => {},
  articleType = {},
  setSelectedArticle = () => {},
  articlePagingInfo = [],
  isLoading = false,
  advanceArticleType = {},
  setStoryAnalysisPopup = () => {},
  setStoryAnalysisData = () => {},
  setSimilarStoriesIds = () => {},
  getStoryAnalysisData = () => {},
  setErrorMessage = () => {},
  storyAnalysis = false,
  topTrendingChart = false,
  customData = {},
  onSentimentUpdate,
  sentimentsLocal,
}) => {
  // const queryClient = useQueryClient();
  // const userReqDetails = getTokenData();
  const selectedTheme = useSelector((store) => {
    return store?.theme.theme || {};
  });

  const [showPopover, setShowPopover] = useState(false);
  const [popoverPosition, setPopoverPosition] = useState({ top: 0, left: 0 });
  const [openActionDropdown, setOpenActionDropDown] = useState(false);
  const [openedArticleData, setOpenedArticleData] = useState({});
  const [hoveredArticle, setHoveredArticle] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const [loadingIds, setLoadingIds] = useState(new Set());
  const [sentIds, setSentIds] = useState(new Set());
  const [summaries, setSummaries] = useState({});

  const { mutateAsync: getArticleSummary } = useGetArticleSummary();

  const [storyAnalysisHover, setStoryAnalysisHover] = useState(null);

  const { searchId, dashboardType, dashboardId } = useParams();

  const { tabsData, activeTab, comparisonFilter } = useSelector((state) => {
    if (dashboardType === 'primpact') {
      return state.prImpact;
    } else if (dashboardType === 'campaign') {
      return state.campaignMonitor;
    } else if (dashboardType === 'people') {
      return state.people;
    } else if (dashboardType === 'storyAnalysis') {
      return state.storyAnalysis;
    } else {
      return state.advancedDashboardTab;
    }
  });

  // const handlePageChange = (pageNum) => {
  //   setCurrentPage(pageNum);
  //   if (name === 'INSEARCH') {
  //     setInsearchPageNum(pageNum);
  //   } else {
  //     handlePage(pageNum);
  //   }
  // };
  const debouncedHandleScroll = debounce(() => {
    setCurrentPage((prev) => prev + 1);
  }, 1000);

  const handleScroll = ({ scrollTop, scrollHeight, clientHeight }) => {
    // Calculate the maximum scroll position
    if (scrollTop > 5) {
      setScrollHeight(100);
    } else if (scrollTop === 0) {
      setScrollHeight(0);
    }

    if (
      !hideArticleLoading &&
      scrollTop + clientHeight >= scrollHeight - 1 &&
      (allHiddenArticles?.data?.data?.length >= 50 ||
        (articles?.length >= 50 &&
          !isLoading &&
          articles?.length !== articlePagingInfo?.unique))
    ) {
      debouncedHandleScroll();
    }
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [hiddenArticleTigger]);

  useEffect(() => {
    if (hiddenArticleTigger) {
      handlePage(currentPage - 1);
    } else if (name === 'INSEARCH' && currentPage > 1) {
      setInsearchPageNum(currentPage - 1);
    } else if (currentPage > 1) {
      handlePage(currentPage - 1);
    }
  }, [currentPage]);

  const showOptRef = useRef();

  const { state } = useLocation();
  const role = useSelector((state) => state?.user?.role);

  const handlePopoverEnter = (event, articleIndex) => {
    const { top, left } = event?.target?.getBoundingClientRect();
    setShowPopover(true);
    setHoveredArticle(articleIndex);
    setPopoverPosition({
      left: left + 50,
      top: top + 5,
    });
  };

  const cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: topTrendingChart ? 132 : 210,
    minHeight: topTrendingChart ? 132 : 210,
  });

  //   const updateBookmark = (payload) => {
  //   return axiosPutRequest('/articles', {}, payload);
  // };

  // const { mutate: addBookmark } = useMutation({ mutationFn: updateBookmark });

  // const handleBookmark = (ele) => {
  //   const { bookmark, id } = ele;
  //   const payload = {
  //     bookmark: !bookmark,
  //     id,
  //   };
  //   addBookmark(payload);
  // };
  // const { mutate: addBookmark } = useMutation({ updateBookmark, { onSuccess: () => { queryClient.invalidateQueries('getArticleData')}} });

  const socialMediaIcons = [
    'X (Twitter)',
    'Reddit',
    'Facebook',
    'Trust Radius',
    'Android Authority',
    'Stack Overflow',
    'Forums',
    'Blogs',
    'Reviews',
    'YouTube',
  ];
  const handleImageError = (event) => {
    event.target.src = DefaultProfileImage; // Default image
  };

  const updateBookmark = (payload) => {
    trackEvent('Result - Bookmarked', {
      result_id: payload?.article_id,
    });

    return axiosPostRequestAPI('/search-articles/bookmark', {}, payload);
  };

  const removeBookmark = (payload) => {
    return axiosDeleteAPI('/search-articles/bookmark', {}, payload);
  };

  const { mutateAsync: addBookmark } = useMutation({
    mutationFn: updateBookmark,
  });
  const { mutateAsync: deleteBookmark } = useMutation({
    mutationFn: removeBookmark,
  });

  const handleBookmark = async (ele) => {
    try {
      const { articleId } = ele;
      const bookmarked = getIsBookmarked(ele);
      const payload = {
        recent_search_id:
          activeTab === 999
            ? articlesRecentSearchId ||
              customData?.allFilters[0]?.recent_searchId
            : customData?.allFilters[activeTab]?.recent_searchId ||
              recentSearchId ||
              articlesRecentSearchId,
        article_id: articleId,
      };
      if (bookmarked) {
        storeArticleCommentsTags &&
          storeArticleCommentsTags(
            { ...payload, bookmarked: false },
            'add',
            'bookmarks'
          );
        deleteBookmark(payload)
          .then(() => {
            toast.success('Bookmark removed successfully.');
          })
          .catch((error) => {
            console.log(error);
            toast.error('Error while bookmarking the article');
          });
      } else {
        storeArticleCommentsTags &&
          storeArticleCommentsTags(
            { ...payload, bookmarked: true },
            'add',
            'bookmarks'
          );
        addBookmark(payload)
          .then(() => {
            toast.success('Bookmarked the article.');
          })
          .catch((error) => {
            console.log(error);
            toast.error('Error while bookmarking the article');
          });
      }
      setTriggerFetchUseEffect((old) => !old);
    } catch (error) {
      console.error('An error occurred:', error);
      toast.error('An error occurred while handling the bookmark.');
      const tokenData = getTokenData();
      logAwsRumEvent(eventNames.function_error_handled_log, {
        error: error?.stack,
        type: 'crash detected',
        id: tokenData?.user_id || 'not logged in',
        name: error.name,
      });
      Sentry.captureException(error);
    }
  };

  const handlePopoverLeave = () => {
    setShowPopover(false);
    setHoveredArticle(null);
  };

  const handleFormatNumber = (num) => {
    const { value, suffix } = formatNumber(num, true, true);
    return value + suffix;
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(openedArticleData?.link);
    toast.success('Copied to clipboard!');

    trackEvent('Result - More Options Used', {
      result_id: openedArticleData?.articleId,
      option_selected: 'Copy Link',
    });
  };

  const { mutateAsync: hideArticle, isLoading: hideArticleLoading } =
    useHiddenArticlesByArticleId();
  const { mutateAsync: unhideArticle } = useUnHideArticlesById();

  const handleHiddenArticle = (triggerMode) => {
    const payload = {
      recent_search_id: recentSearchId || articlesRecentSearchId,
      article_id: openedArticleData?.articleId,
    };
    if (triggerMode) {
      try {
        storeArticleCommentsTags &&
          storeArticleCommentsTags(
            { ...payload, hidden: false },
            'add',
            'hidden-articles'
          );
        unhideArticle(payload)
          .then(() => {
            toast.success('Article successfully removed from the hidden list!');
            setTriggerFetchUseEffect &&
              setTriggerFetchUseEffect((prev) => !prev);
            refetchHiddleArticles && refetchHiddleArticles();
          })
          .catch((error) => {
            console.log(error);
            toast.error('Unable to Hide Article at this moment');
          });
      } catch (error) {
        console.log(error);
        toast.error('An error occurred while handling the bookmark.');
        const tokenData = getTokenData();
        logAwsRumEvent(eventNames.function_error_handled_log, {
          error: error?.stack,
          type: 'crash detected',
          id: tokenData?.user_id || 'not logged in',
          name: error.name,
        });
      }
    } else {
      try {
        storeArticleCommentsTags &&
          storeArticleCommentsTags(
            { ...payload, hidden: true },
            'add',
            'hidden-articles'
          );
        hideArticle(payload, {
          onError: () => {},
        })
          .then(() => {
            toast.success('The article was hidden successfully.');
            // refetchHiddleArticles && refetchHiddleArticles();

            trackEvent('Result - More Options Used', {
              result_id: openedArticleData?.articleId,
              option_selected: 'Hide',
            });
            setTriggerFetchUseEffect &&
              setTriggerFetchUseEffect((prev) => !prev);
          })
          .catch((error) => {
            console.log(error);
            toast.error('Unable to Hide Article at this moment');
          });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const actionDropDownOptions = [
    {
      label: 'Copy Link',
      clickFunction: () => handleCopyLink(),
    },
    {
      label: hiddenArticleTigger ? 'Unhide Article' : 'Hide Article',
      clickFunction: () => handleHiddenArticle(hiddenArticleTigger),
    },
  ];

  const handleClickOutside = (event) => {
    if (showOptRef.current && !showOptRef.current.contains(event.target)) {
      setOpenActionDropDown(false);
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleOptionIcon = (e, content) => {
    e.stopPropagation();
    // setSelectedComponent(componentName);
    setOpenActionDropDown((prev) => {
      if (prev && openedArticleData === content) {
        setOpenedArticleData({});
        return false;
      } else {
        setOpenedArticleData(content);
        return true;
      }
    });
  };

  // const handleSydication = (value) => {
  //   setSydicationActive(true);
  //   setSydicationArticles(value);
  // };

  const recentSearchId = state?.savedSearchData?.recent_search_id;
  const articlesToMap = hiddenArticleTigger
    ? allHiddenArticles?.data?.data
    : savedSearchId === 'custom-search'
    ? hiddenArticle
    : articles;

  const getOnlyValues = (Bottomkeys, ele) => {
    return Bottomkeys.map(
      (item, i) => (item?.func && item.func(ele)) || ele[item.value]
    )?.filter((x) => !!x);
  };
  // const animations = {
  //   initial: { scale: 0, opacity: 0 },
  //   animate: { scale: 1, opacity: 1 },
  //   exit: { scale: 0, opacity: 0 },
  //   transition: { type: 'spring', stiffness: 900, damping: 40 },
  // };

  const animations = {
    initial: { x: 100, opacity: 0 },
    animate: { x: 0, opacity: 1 },
    exit: { x: -150, opacity: 0 },
    transition: {
      type: 'spring',
      stiffness: 900,
      damping: 40,
      duration: 0.7,
    },
  };

  const getComment = (ele) => {
    const comment = storeComments?.find(
      (x) => x?.article_id === ele?.articleId
    );
    if (comment) {
      return comment?.content;
    }
    return ele?.comment || '';
  };

  const getSentiment = (ele) => {
    const sentimentData = sentimentsLocal?.find(
      (x) => x?.articleId === ele?.articleId
    );
    if (sentimentData) {
      return sentimentData?.newSentiment;
    }
    return '';
  };

  const showIcon = (item, ele) => {
    if (item?.value === 'syndication' && ele[item?.value] === 0) {
      return false;
    }
    return showFullTitle !== 'Syndication';
  };

  const getTags = (ele) => {
    const tagLocalInfo = storeTags?.find(
      (x) => x?.article_id === ele?.articleId
    );
    if (tagLocalInfo) {
      const uniqueTags = [...new Set(tagLocalInfo?.tags)];
      return uniqueTags;
    }
    return ele?.tags || [];
  };

  const getIsBookmarked = (ele) => {
    const bookMarksData = bookmarksLocal?.find(
      (x) => x?.article_id === ele?.articleId
    );
    if (bookMarksData) {
      return bookMarksData?.bookmarked;
    }
    return ele?.bookmarked;
  };
  const getArticlesLocal = (articles) => {
    if (!hiddenArticleTigger) {
      const filteredArticles = articles?.filter(
        (x) => !hiddenArticlesLocal?.some((y) => y?.article_id === x?.articleId)
      );
      return filteredArticles;
    } else {
      return articles;
    }
  };

  const { mutateAsync: addTag } = useCreateTagsBySearchId();
  const { mutateAsync: deleteTag } = useDeleteArticleTagBySearchId();

  const getMainTitle = (ele) => {
    if (ele?.newsType === 'Online' || ele?.newsType === 'Print') {
      return ele?.publication
        ? `${ele?.publication.charAt(0).toUpperCase()}${ele?.publication.slice(
            1
          )}, `
        : '';
    }
    return ele.author
      ? `${ele?.author.charAt(0).toUpperCase()}${ele?.author.slice(1)}${
          getSubtitle(ele) ? ',' : ''
        }`
      : '';
  };

  const getSubtitle = (ele) => {
    if (ele?.newsType === 'Online' || ele?.newsType === 'Print') {
      return ele.author
        ? `${ele?.author.charAt(0).toUpperCase()}${ele?.author.slice(1)}${
            ele.publication !== 'X (Twitter)' && ele?.publication !== 'reddit'
              ? ''
              : ''
          }`
        : '';
    }
    if (ele?.newsType === 'X (Twitter)' && ele?.username) {
      return `@${ele?.username}`;
    }
    return '';
  };

  function removeTagAndReturnUpdatedArticles(
    tagToRemove,
    checked,
    storeTags,
    articleInfo
  ) {
    // Helper function to remove the tag from a given tags array
    function removeTag(tags) {
      return tags.filter((tag) => tag !== tagToRemove);
    }

    // Array to store the updated articles
    const updatedArticles = [];

    // Iterate over each articleId in the checked array
    checked.forEach((articleId) => {
      let updatedTags = [];

      // Find and update the storeTags object if it exists
      const storeTagObj = storeTags.find(
        (store) => store.article_id === articleId
      );
      if (storeTagObj) {
        storeTagObj.tags = removeTag(storeTagObj.tags);
        updatedTags = storeTagObj.tags;
      } else {
        // If not found in storeTags, update the articleInfo object
        const articleInfoObj = articleInfo.find(
          (article) => article.articleId === articleId
        );
        if (articleInfoObj) {
          articleInfoObj.tags = removeTag(articleInfoObj.tags);
          updatedTags = articleInfoObj.tags;
        }
      }

      // Push the updated object to the array
      updatedArticles.push({ articleId, tags: updatedTags });
    });

    return updatedArticles;
  }

  const handleRemoveTag = (ele, tag) => {
    try {
      const articleId = ele?.articleId;
      const filterTag =
        // storeTags?.filter((stored) => !stored?.tag?.includes(tag)) ||
        getTags(ele)?.filter((tagName) => tagName !== tag);
      const payload = {
        tags: [tag],
        recent_search_id: articlesRecentSearchId,
        article_id: [articleId],
      };

      deleteTag(payload).then((data) => {
        if (data.isSuccessful) {
          toast.success('Tag Removed');
          setTriggerFetchUseEffect((prev) => !prev);
          const updatedData = removeTagAndReturnUpdatedArticles(
            tag,
            [ele?.articleId],
            storeTags,
            articles
          );
          storeArticleCommentsTags(updatedData, 'AddOrDelete', 'tags', []);
        } else {
          toast.error('Error while adding Tag');
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getBottomData = (bottomDetails, ele) => {
    return bottomDetails?.filter((x) => x?.show(ele));
  };
  // check if the component should be rendered
  const shouldRenderComponent = (componentName) => {
    const excludedComponents = [
      'word_cloud',
      'industry_companies_mentioned',
      'brand_companies_mentioned',
      'competition_companies_mentioned',
      'people_popular_topics',
      'top_hashtags',
      'top_subreddit',
      'top_influencers',
      'advanced_campaign_monitor_wordcloud',
    ];

    return !excludedComponents.includes(componentName);
  };

  const handleSummary = () => {
    try {
      const formattedSummary = summaryData?.data?.data?.summary.replace(
        /\n/g,
        '  \n'
      );
      return formattedSummary;
    } catch (err) {
      return summaryData?.data?.data?.summary;
    }
  };

  const handleStoryAnalysis = async (articleEle) => {
    try {
      setStoryAnalysisPopup(true);
      const storyAnalysisPayload = {
        recent_search_id: recentSearchId || articlesRecentSearchId,
        article_id: articleEle?.articleId,
      };
      const storyRes = await getStoryAnalysisData(
        storyAnalysisPayload,
        (!!recentSearchId || !!articlesRecentSearchId) && articleEle?.articleId
      );
      if (storyRes?.data?.articleData?.length > 0) {
        storyRes?.similarArticles?.length === 0 &&
          setErrorMessage('No Similar Stories');
        setSimilarStoriesIds([...storyRes?.similarArticles]);
        setStoryAnalysisData({ ...storyRes?.data });
      } else {
        setErrorMessage('Failed to fetch story. Please try again later');
      }
    } catch (err) {
      console.log({ err });
      toast.error('Something went wrong. Please try again later.');
      setStoryAnalysisPopup(false);
    }
  };

  const renderRow = ({ index: i, key, style, parent }) => {
    const ele = getArticlesLocal(articlesToMap)[i] ?? {};
    const renderSummary =
      i === 0 && isPopup && !searchKey && showFullTitle !== 'Syndication';
    return (
      <CellMeasurer
        key={key}
        cache={cache}
        parent={parent}
        columnIndex={0}
        rowIndex={i}
      >
        {({ registerChild }) => (
          <ArticleMainWrp
            articleView={articleView}
            key={key}
            style={style}
            ref={registerChild}
          >
            {!hiddenArticleTigger && renderSummary && fetchAiSummary && (
              <AITextWrp>
                <AiScrollWrp className="markdown-styles">
                  <AIText style={{ marginBottom: '.5rem' }}>
                    {handleSummary()}
                  </AIText>
                </AiScrollWrp>
                <AIIconWrp>
                  <IconWrp>
                    <AiIcon />
                  </IconWrp>
                  <AIText ai={true}>Powered by AI</AIText>
                </AIIconWrp>
                {summaryData?.isLoading && <ThreeDotsLoader />}
                {summaryData?.isLoading && <AiLoaderV2 margin={true} />}
              </AITextWrp>
            )}
            {!hiddenArticleTigger &&
              renderSummary &&
              !showAiSummaryBtn &&
              !fetchAiSummary &&
              shouldRenderComponent(
                articleType.component || advanceArticleType?.widget
              ) && (
                <AITextWrp>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <div>
                      <AIIconWrp>
                        <AIText ai={false}>
                          Click to generate AI insights
                        </AIText>
                      </AIIconWrp>
                      <AiInsightBtn
                        onClick={() => {
                          setFetchAiSummary(true);
                          // mixpanel event
                          trackEvent('Insights - Button Clicked', {});
                        }}
                      >
                        View Insights
                      </AiInsightBtn>
                    </div>
                    <IconWrp>
                      <AiIcon height="40" width="40" />
                    </IconWrp>
                  </div>
                </AITextWrp>
              )}
            <ArticlewrpBox>
              <ArticleWrp articleView={articleView} key={`${ele.title}-i`}>
                <ArticleWrpR articleView={articleView}>
                  <ArticleAndIconWrp>
                    <ArticleLinkSection>
                      {(ele?.icon || ele?.profile_photo) && (
                        <IconWrapper>
                          <ArticleIcon
                            key={i}
                            src={ele.icon || ele?.profile_photo}
                            onError={handleImageError}
                          />
                          {ele?.publication === 'reddit' && (
                            <BadgeIcon src={RedditIcon} />
                          )}
                          {ele.publication === 'X (Twitter)' && (
                            <BadgeIcon src={TwitterIcon} />
                          )}
                          {(ele.publication === undefined ||
                            ele.publication === null) && (
                            <BadgeIcon src={DefaultSMIcon} />
                          )}
                        </IconWrapper>
                      )}
                      {!ele?.icon &&
                        // !socialMediaIcons?.includes(ele?.publication) &&
                        !socialMediaIcons?.includes(ele?.newsType) &&
                        !ele?.profile_photo && (
                          <ArticleDefaultIcon>
                            {ele?.publication?.[0]}
                          </ArticleDefaultIcon>
                        )}
                      {!ele?.icon &&
                        // socialMediaIcons?.includes(ele?.publication) &&
                        socialMediaIcons?.includes(ele?.newsType) &&
                        !ele?.profile_photo && (
                          <IconWrapper>
                            <ArticleIcon key={i} src={DefaultProfileImage} />
                            {ele?.newsType === 'Reddit' && (
                              <BadgeIcon src={RedditIcon} />
                            )}
                            {ele.newsType === 'X (Twitter)' && (
                              <BadgeIcon src={TwitterIcon} />
                            )}
                            {ele?.newsType !== 'Reddit' &&
                              ele.newsType !== 'X (Twitter)' && (
                                <BadgeIcon src={DefaultSMIcon} />
                              )}
                          </IconWrapper>
                        )}
                      <div>
                        <ArticleLinkWrp articleView={articleView}>
                          {!!ele?.author && (
                            <BoxArticlewpr>
                              <ArticleAuthor
                                style={{
                                  fontSize: '12px',
                                  color: 'black',
                                  fontWeight: '600',
                                }}
                              >
                                {ele?.title === '' &&
                                (ele?.link || ele?.url) ? (
                                  <ArticleTitle
                                    to={ele?.link || ele?.url}
                                    mainTitle={true}
                                    target="_blank"
                                    rel={ele.title}
                                    onClick={() => {
                                      trackEvent(
                                        'Result - External Link Redirection',
                                        {
                                          result_id: ele?.articleId,
                                        }
                                      );
                                    }}
                                  >
                                    {' '}
                                    {getMainTitle(ele)}
                                  </ArticleTitle>
                                ) : (
                                  getMainTitle(ele)
                                )}
                              </ArticleAuthor>
                            </BoxArticlewpr>
                          )}
                          <BoxArticlewpr>
                            <ArticleAuthor>{getSubtitle(ele)}</ArticleAuthor>
                          </BoxArticlewpr>
                        </ArticleLinkWrp>
                        <ArticleLinkWrp articleView={articleView}>
                          {getOnlyValues(
                            Bottomkeys.filter((item) => item.value !== 'link'),
                            ele
                          )?.map((item, i, array) => (
                            <BoxArticlewpr key={i}>
                              <Articlekeytxt>
                                {item[0] ? capitalizeFirstWord(item) : item}
                                {i !== array.length - 1 && (item ? ',' : '')}
                              </Articlekeytxt>
                            </BoxArticlewpr>
                          ))}
                        </ArticleLinkWrp>
                      </div>
                    </ArticleLinkSection>
                    {showTab && (
                      <ArticleBottomWrp
                        articleView={articleView}
                        className="article-bottom"
                      >
                        <ArticleActionDetailsWrp>
                          <SocialIconWrp
                            onClick={() => handleStoryAnalysis(ele)}
                            onMouseEnter={() => setStoryAnalysisHover(i)}
                            onMouseLeave={() => setStoryAnalysisHover(null)}
                          >
                            <SocialAnalysisIcon
                              hoverColor={storyAnalysisHover === i}
                            />
                          </SocialIconWrp>
                          {!hiddenArticleTigger && role !== reader && (
                            <IconsPop
                              Icon={Comment}
                              PopContent={CommentBox}
                              data={{
                                ele,
                                type,
                                page,
                                commentData: ele?.comment,
                                articlesRecentSearchId,
                                savedSearchId,
                                storeComments,
                              }}
                              setTriggerFetchUseEffect={
                                setTriggerFetchUseEffect
                              }
                              storeArticleCommentsTags={
                                storeArticleCommentsTags
                              }
                              setStoreComments={setStoreComments}
                              // data={{ ele, type, page }}
                              width="1.75rem"
                              height="1.75rem"
                              tooltipText="Comment"
                              // mark={ele.comment !== ''}
                            />
                          )}
                          {!hiddenArticleTigger && role !== reader && (
                            <TagWrap variant="dark">
                              <TagIcon
                                color={
                                  getTags(ele)?.length > 0 ? '#cf326b' : '#fff'
                                }
                                width="1.75rem"
                                height="1.75rem"
                                istrue={false}
                                fill={
                                  getTags(ele)?.length > 0
                                    ? '#cf326b'
                                    : '#585858'
                                }
                                fill2={
                                  getTags(ele)?.length > 0 ? '#fff' : '#585858'
                                }
                                onClickIcon={() => {
                                  setSelectedArticle(ele);
                                }}
                              />
                            </TagWrap>
                            // <IconsPop
                            //   key={ele?.articleId}
                            //   Icon={TagIcon}
                            //   PopContent={TagBox}
                            //   data={{
                            //     ele,
                            //     type,
                            //     page,
                            //     tagData: ele?.tags,
                            //     articlesRecentSearchId,
                            //     savedSearchId,
                            //     tags: getTags(ele),
                            //   }}
                            //   setTriggerFetchUseEffect={
                            //     setTriggerFetchUseEffect
                            //   }
                            //   storeArticleCommentsTags={
                            //     storeArticleCommentsTags
                            //   }
                            //   setStoreTags={setStoreTags}
                            //   tooltipText="Tags"
                            // />
                          )}

                          {!hiddenArticleTigger && role !== reader && (
                            <ArticleIconWrp
                              onClick={() => {
                                handleBookmark(ele);
                              }}
                            >
                              <Tooltip content="Bookmark">
                                <BookMarkIcon2
                                  color={
                                    getIsBookmarked(ele) ? '#675ef2' : 'white'
                                  }
                                  stroke={
                                    getIsBookmarked(ele) ? '#675ef2' : '#585858'
                                  }
                                  width="1.5rem"
                                  height="1.5rem"
                                />
                              </Tooltip>
                            </ArticleIconWrp>
                          )}

                          {role !== reader && (
                            <CheckBoxWrp
                              variant="dark"
                              onClick={() => {
                                handleChecked(ele?.articleId);
                                trackEvent('Result - Selected', {
                                  result_id: ele?.articleId,
                                });
                              }}
                            >
                              <CheckboxIcon
                                width="1.5rem"
                                height="1.5rem"
                                checked={allChecked}
                                color={
                                  checked.includes(ele?.articleId)
                                    ? theme[selectedTheme].primary
                                    : 'white'
                                }
                                borderColor={
                                  checked.includes(ele?.articleId)
                                    ? theme[selectedTheme].primary
                                    : '#5C5E60'
                                }
                              />
                            </CheckBoxWrp>
                          )}

                          {role !== reader && (
                            <IconBoxwpr
                              width={'1.5rem'}
                              height={'1.5rem'}
                              onClick={(e) => handleOptionIcon(e, ele)}
                              ref={showOptRef}
                              className="hide-downloading"
                            >
                              <VerticleDots
                                color={
                                  openActionDropdown ? '#675ef2' : '#5C5E60'
                                }
                              />
                              <SimpleReusableDropDown
                                isOpen={
                                  openActionDropdown &&
                                  openedArticleData === ele
                                }
                                options={actionDropDownOptions}
                                setIsOpen={setOpenActionDropDown}
                              />
                            </IconBoxwpr>
                          )}
                        </ArticleActionDetailsWrp>
                      </ArticleBottomWrp>
                    )}
                  </ArticleAndIconWrp>
                  <ArticleTextImgWrp>
                    <ArticleTitletWrp>
                      {(ele?.link || ele?.url) && (
                        <ArticleTitle
                          to={ele?.link || ele?.url}
                          target="_blank"
                          rel={ele.title}
                          onClick={() => {
                            trackEvent('Result - External Link Redirection', {
                              result_id: ele?.articleId,
                            });
                          }}
                        >
                          {ele.title}
                        </ArticleTitle>
                      )}
                      {!ele?.link && !ele?.url && (
                        <ArticlePressTitle>{ele.title}</ArticlePressTitle>
                      )}
                      {!topTrendingChart && getTags(ele)?.length > 0 && (
                        <ArticleCmtWrp
                          bg="white"
                          key={ele.tag_id}
                          style={{ paddingLeft: 0, flexWrap: 'wrap' }}
                        >
                          {getTags(ele)?.map((tag, index) => (
                            <ArticleTagWrp key={index}>
                              <ArticleTags key={index} bgc={' #CF326B'}>
                                {tag || ''}
                              </ArticleTags>
                              <TagClosespan
                                key={index}
                                onClick={() => handleRemoveTag(ele, tag)}
                              >
                                <TagCloseIcon />
                              </TagClosespan>
                            </ArticleTagWrp>
                          ))}
                        </ArticleCmtWrp>
                      )}
                      <ArticleBody>{ele.content}</ArticleBody>
                      {/* {(summaries[String(ele?.articleId)] ||
                        !(
                          ele?.type === 'Online' ||
                          ele?.type === 'Print' ||
                          ele?.type === 'Blogs'
                        )) &&
                        !topTrendingChart && (
                          <ArticleBody>
                            {summaries[String(ele?.articleId)]?.summary ||
                              ele.content}
                          </ArticleBody>
                        )}
                      {loadingIds.has(String(ele?.articleId)) &&
                        !summaries[String(ele?.articleId)] &&
                        !topTrendingChart &&
                        (ele?.type === 'Online' ||
                          ele?.type === 'Print' ||
                          ele?.type === 'Blogs') && (
                          <>
                            <ArticleBody
                              style={{ color: theme[selectedTheme].primary }}
                            >
                              Summarizing content...
                            </ArticleBody>
                          </>
                        )} */}
                    </ArticleTitletWrp>
                    {!topTrendingChart && (
                      <ArticleWrpL articleView={articleView}>
                        <RenderImage url={ele?.image} />
                      </ArticleWrpL>
                    )}
                  </ArticleTextImgWrp>
                </ArticleWrpR>
              </ArticleWrp>
              <ArticlewprZ>
                <ArticleTextDetailsWrp
                  articleView={articleView}
                  floatingPagination={floatingPagination}
                >
                  {getBottomData(bottomDetails, ele)?.map((item, j) =>
                    item.value === 'reach' &&
                    ele?.newsType !== 'X (Twitter)' ? (
                      <ReachTooltip
                        content="Reach estimates the potential viewership of any particular article based on the number of visitors to the specific source on both desktop and mobile"
                        key={j}
                        direction="bottom"
                        articleCount={articlesToMap?.length > 2}
                        hideTooltip={item.hideTooltip}
                      >
                        <ArticleTextWrp
                          itemType={item.value}
                          onClick={() => {
                            if (
                              item.value === 'syndication' &&
                              ele.syndication > 0 &&
                              syndicationClickData.graphSelection &&
                              !hiddenArticleTigger
                            ) {
                              !hiddenArticleTigger &&
                                setClickedPosition &&
                                setClickedPosition(1);
                              setSyndicationClick &&
                                setSyndicationClick({
                                  widget: 'Syndication',
                                  graphSelection: 'Syndication',
                                  rawData: ele,
                                  inSyndication: true,
                                  otherInfo: { uniqueId: 'Syndication' },
                                  name,
                                });
                            }
                          }}
                          onMouseEnter={
                            item?.value === 'matches'
                              ? (e) => handlePopoverEnter(e, i)
                              : () => {}
                          }
                          onMouseLeave={
                            item?.value === 'matches'
                              ? (e) => handlePopoverLeave(e, i)
                              : () => {}
                          }
                          key={j}
                          articleView={articleView}
                        >
                          <ArticleTextLabel>
                            {showFullTitle !== 'Syndication' && item?.icon}
                          </ArticleTextLabel>
                          <ArticleTextValue
                            articleView={articleView}
                            itemType={item.value}
                          >
                            {item.value === 'matches' && (
                              <KeywordPopoverWrap
                                matchingKeyword={
                                  showPopover &&
                                  hoveredArticle === i &&
                                  theme[selectedTheme]?.graphColors?.purple70
                                }
                              >
                                {ele[item.value].length || ''}
                              </KeywordPopoverWrap>
                            )}
                            {item.value === 'sentiment' && (
                              <SentimentComponent sentiment={ele[item.value]} />
                            )}
                            {item.value === 'syndication' &&
                              showFullTitle !== 'Syndication' &&
                              ele[item.value] !== 0 && (
                                <SyndicationButton
                                  isZero={
                                    ele[item.value] === 0 ||
                                    syndicationClickData?.graphSelection
                                  }
                                  onClick={() => {
                                    if (
                                      setSyndicationClick &&
                                      ele.syndication > 0 &&
                                      !syndicationClickData.graphSelection
                                    ) {
                                      setSyndicationClick &&
                                        setSyndicationClick({
                                          widget: 'Syndication',
                                          graphSelection: 'Syndication',
                                          rawData: ele,
                                          inSyndication: true,
                                          otherInfo: {
                                            uniqueId: 'Syndication',
                                          },
                                          name,
                                        });
                                      !hiddenArticleTigger &&
                                        setClickedPosition &&
                                        setClickedPosition(1);

                                      if (name === 'INSEARCH' || isPopup) {
                                        setInsearchPageNum(0);
                                      }
                                    }
                                  }}
                                  disabled={
                                    syndicationClickData?.graphSelection
                                  }
                                >
                                  <span
                                    style={{
                                      color:
                                        ele[item.value] === 0 ||
                                        syndicationClickData?.graphSelection
                                          ? theme[selectedTheme]?.text
                                          : theme[selectedTheme]?.text,
                                      // opacity:
                                      //   ele[item.value] === 0 ||
                                      //   syndicationClickData?.graphSelection
                                      //     ? '0.5'
                                      //     : 1,
                                    }}
                                  >
                                    {String(addCountPrefix(ele[item.value]))}{' '}
                                  </span>
                                  <ArticleTextLabel
                                    style={{ display: 'inline-block' }}
                                    // style={{ color: theme[selectedTheme].closeButton }}
                                  >
                                    {item.label}
                                  </ArticleTextLabel>
                                </SyndicationButton>
                              )}
                            {item.value !== 'matches' &&
                              item.value !== 'sentiment' &&
                              item.value !== 'syndication' &&
                              addCountPrefix(ele[item.value])}
                            {item?.value === 'matches' ? (
                              <PortalTooltip
                                isOpen={showPopover && hoveredArticle === i}
                                pos={popoverPosition}
                                align={
                                  popoverPosition?.left > window?.innerWidth / 2
                                    ? 'left'
                                    : 'right'
                                }
                                vAlign={
                                  popoverPosition?.top > window?.innerHeight / 2
                                    ? 'top'
                                    : 'bottom'
                                }
                                boxShadow="0px 0px 0px rgba(0, 0, 0, 0.20)"
                                borderRadius="0.3125rem"
                              >
                                <KeywordPopover items={ele[item.value]} />
                              </PortalTooltip>
                            ) : (
                              <></>
                            )}
                          </ArticleTextValue>
                          {item.value !== 'sentiment' &&
                            item.value !== 'syndication' && (
                              <ArticleTextLabel>{item.label}</ArticleTextLabel>
                            )}
                        </ArticleTextWrp>
                      </ReachTooltip>
                    ) : (
                      <ArticleTextWrp
                        onClick={() => {
                          if (
                            item.value === 'syndication' &&
                            ele.syndication > 0 &&
                            syndicationClickData.graphSelection &&
                            !hiddenArticleTigger
                          ) {
                            !hiddenArticleTigger &&
                              setClickedPosition &&
                              setClickedPosition(1);
                            setSyndicationClick &&
                              setSyndicationClick({
                                widget: 'Syndication',
                                graphSelection: 'Syndication',
                                rawData: ele,
                                inSyndication: true,
                                otherInfo: { uniqueId: 'Syndication' },
                                name,
                              });
                          }
                        }}
                        onMouseEnter={
                          item?.value === 'matches'
                            ? (e) => handlePopoverEnter(e, i)
                            : () => {}
                        }
                        onMouseLeave={
                          item?.value === 'matches'
                            ? (e) => handlePopoverLeave(e, i)
                            : () => {}
                        }
                        key={j}
                        articleView={articleView}
                        itemType={item.value}
                      >
                        {item.value === 'syndication' &&
                          ele[item.value] > 0 && (
                            <ArticleTextLabel>{item?.icon}</ArticleTextLabel>
                          )}
                        <ArticleTextLabel>
                          {item.value !== 'syndication' &&
                            item.value !== 'matches' &&
                            item?.icon}
                          {item.value !== 'syndication' &&
                            item.value === 'matches' &&
                            item?.icon && (
                              <AllMatchIcon
                                color={
                                  showPopover &&
                                  item.value === 'matches' &&
                                  hoveredArticle === i
                                    ? theme[selectedTheme].graphColors.purple70
                                    : '#585858'
                                }
                              />
                            )}
                        </ArticleTextLabel>
                        <ArticleTextValue
                          articleView={articleView}
                          itemType={item.value}
                          matchingKeyword={
                            showPopover &&
                            item.value === 'matches' &&
                            hoveredArticle === i &&
                            theme[selectedTheme]?.graphColors?.purple70
                          }
                        >
                          {item.value === 'matches' && (
                            <KeywordPopoverWrap>
                              {ele?.[item?.value]?.length || ''}
                            </KeywordPopoverWrap>
                          )}
                          {item.value === 'sentiment' && (
                            <SentimentComponent
                              sentiment={
                                getSentiment(ele) !== ''
                                  ? getSentiment(ele)
                                  : ele[item.value]
                              }
                              onChange={(value) => {
                                onSentimentUpdate(ele?.articleId, value);
                              }}
                            />
                          )}
                          {item.value === 'syndication' &&
                            showFullTitle !== 'Syndication' &&
                            ele[item.value] !== 0 && (
                              <SyndicationButton
                                isZero={
                                  ele[item.value] === 0 ||
                                  syndicationClickData?.graphSelection
                                }
                                onClick={() => {
                                  if (
                                    setSyndicationClick &&
                                    ele.syndication > 0 &&
                                    !syndicationClickData.graphSelection
                                  ) {
                                    setSyndicationClick &&
                                      setSyndicationClick({
                                        widget: 'Syndication',
                                        graphSelection: 'Syndication',
                                        rawData: ele,
                                        inSyndication: true,
                                        otherInfo: {
                                          uniqueId: 'Syndication',
                                        },
                                        name,
                                      });
                                    !hiddenArticleTigger &&
                                      setClickedPosition &&
                                      setClickedPosition(1);

                                    if (name === 'INSEARCH' || isPopup) {
                                      setInsearchPageNum(0);
                                    }
                                  }
                                }}
                                disabled={syndicationClickData?.graphSelection}
                              >
                                <span
                                  style={{
                                    color:
                                      ele[item.value] === 0 ||
                                      syndicationClickData?.graphSelection
                                        ? theme[selectedTheme]?.text
                                        : theme[selectedTheme]?.text,
                                    // opacity:
                                    //   ele[item.value] === 0 ||
                                    //   syndicationClickData?.graphSelection
                                    //     ? '0.5'
                                    //     : 1,
                                  }}
                                >
                                  {String(addCountPrefix(ele[item.value]))}{' '}
                                </span>
                                <ArticleTextLabel
                                  style={{ display: 'inline-block' }}
                                  // style={{ color: theme[selectedTheme].closeButton }}
                                >
                                  {item.label}
                                </ArticleTextLabel>
                              </SyndicationButton>
                            )}
                          {item.value !== 'matches' &&
                            item.value !== 'sentiment' &&
                            item.value !== 'syndication' &&
                            addCountPrefix(ele[item.value])}
                          {item?.value === 'matches' ? (
                            <PortalTooltip
                              isOpen={showPopover && hoveredArticle === i}
                              pos={popoverPosition}
                              align={
                                popoverPosition?.left > window?.innerWidth / 2
                                  ? 'left'
                                  : 'right'
                              }
                              vAlign={
                                popoverPosition?.top > window?.innerHeight / 2
                                  ? 'top'
                                  : 'bottom'
                              }
                              boxShadow="0px 16px 24px 0px rgba(0, 0, 0, 0.12)"
                              borderRadius="0.5rem"
                            >
                              {ele?.[item?.value] && (
                                <KeywordPopover items={ele[item.value]} />
                              )}
                            </PortalTooltip>
                          ) : (
                            <></>
                          )}
                        </ArticleTextValue>
                        {item.value !== 'sentiment' &&
                          item.value !== 'syndication' && (
                            <ArticleTextLabel
                              matchingKeyword={
                                item.value === 'matches' &&
                                showPopover &&
                                hoveredArticle === i &&
                                theme[selectedTheme]?.graphColors?.purple70
                              }
                            >
                              {item.label}
                            </ArticleTextLabel>
                          )}
                      </ArticleTextWrp>
                    )
                  )}
                </ArticleTextDetailsWrp>
                {getComment(ele) && (
                  <ArticleCmtWrp>
                    <Comment istrue={true} />
                    <ArticleCmt key={ele.comment_id}>
                      {getComment(ele)}
                    </ArticleCmt>
                  </ArticleCmtWrp>
                )}
              </ArticlewprZ>
              {/* <ArticleHR /> */}
            </ArticlewrpBox>
          </ArticleMainWrp>
        )}
      </CellMeasurer>
    );
  };

  const isRowLoaded = ({ index }) => !!getArticlesLocal(articlesToMap)?.[index];

  const handleRowsRendered = useCallback(
    debounce(async ({ startIndex, stopIndex }) => {
      return;
      const newlyVisibleArticles = getArticlesLocal(articlesToMap).slice(
        startIndex,
        stopIndex + 1
      );

      const articleIds = [];
      const printType = [];

      newlyVisibleArticles.forEach((article) => {
        if (article?.type === 'Print') {
          printType.push(article?.id);
        } else if (article?.type === 'Online' || article?.type === 'Blogs') {
          articleIds.push(article?.id);
        }
        console.log(`Article ${article.id} is now visible`);
      });

      // Filter out IDs that have already been sent
      const filteredArticleIds = articleIds.filter((id) => !sentIds.has(id));
      const filteredPrintType = printType.filter((id) => !sentIds.has(id));

      if (filteredArticleIds.length === 0 && filteredPrintType.length === 0) {
        return; // No new IDs to send
      }

      // Update loading state for new IDs
      const newLoadingIds = new Set(loadingIds);
      filteredArticleIds.forEach((id) => newLoadingIds.add(id));
      filteredPrintType.forEach((id) => newLoadingIds.add(id));
      setLoadingIds(newLoadingIds);

      const resp = await getArticleSummary({
        recent_search_id: recentSearchId || articlesRecentSearchId,
        article_ids: [
          {
            opensearch: filteredArticleIds,
            print: filteredPrintType,
          },
        ],
      });

      // If the response is successful, update summaries and sentIds
      if (resp.status === 'success' && resp.isSuccessful) {
        const newSummaries = resp.data;

        // Store summaries and mark IDs as sent
        setSummaries((prev) => ({ ...prev, ...newSummaries }));

        const newSentIds = new Set(sentIds);
        Object.keys(newSummaries).forEach((id) => newSentIds.add(id));
        setSentIds(newSentIds);
      }

      const updatedLoadingIds = new Set(loadingIds);

      // Remove IDs from loadingIds that are present in the summaries object
      Object.keys(summaries).forEach((id) => updatedLoadingIds.delete(id));
      // If you have filteredPrintType as well, keep that as is
      filteredPrintType.forEach((id) => updatedLoadingIds.delete(id));
      // Update the state with the new loadingIds
      setLoadingIds(updatedLoadingIds);
    }, 400), // Adjust the delay as needed (in milliseconds)
    [articlesToMap, sentIds, loadingIds] // Include sentIds and loadingIds in dependencies
  );

  return (
    <ArticlesWrp
      storyAnalysis={storyAnalysis}
      articleView={articleView}
      isPopup={isPopup}
    >
      <WindowScroller>
        {({ isScrolling, scrollTop }) => (
          <InfiniteLoader isRowLoaded={isRowLoaded}>
            {({ onRowsRendered, registerChild }) => (
              <AutoSizer>
                {({ width, height }) => (
                  <List
                    ref={registerChild}
                    width={width}
                    height={height}
                    rowCount={getArticlesLocal(articlesToMap)?.length ?? 0}
                    rowHeight={cache.rowHeight}
                    rowRenderer={renderRow}
                    onRowsRendered={(params) => {
                      handleRowsRendered(params);
                      onRowsRendered(params);
                    }}
                    overscanRowCount={3}
                    isScrolling={isScrolling}
                    onScroll={handleScroll}
                    className="scroll-hidden"
                  />
                )}
              </AutoSizer>
            )}
          </InfiniteLoader>
        )}
      </WindowScroller>
      {getArticlesLocal(articlesToMap)?.map((ele, i) => {})}
    </ArticlesWrp>
  );
};

Articles.propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.string,
      link: PropTypes.string,
      icon: PropTypes.string,
      image: PropTypes.string,
      matches: PropTypes.arrayOf(PropTypes.string),
      reach: PropTypes.number,
      syndication: PropTypes.number,
      ave: PropTypes.number,
      sentiment: PropTypes.string,
    })
  ).isRequired,
  articleView: PropTypes.bool,
  page: PropTypes.number,
  type: PropTypes.string,
  showTab: PropTypes.bool,
  floatingPagination: PropTypes.bool,
  tooltipText: PropTypes.string,
  handleChecked: PropTypes.func,
  checked: PropTypes.func,
  savedSearchId: PropTypes.number,
  allChecked: PropTypes.bool,
  setSydicationActive: PropTypes.func,
  setSydicationArticles: PropTypes.func,
  sydicationActive: PropTypes.bool,
  sydicationArticles: PropTypes.array,
  allHiddenArticles: PropTypes.any,
  articlesRecentSearchId: PropTypes.string,
  setSyndicationClick: PropTypes.func,
  setClickedPosition: PropTypes.func,
  setTriggerFetchUseEffect: PropTypes.func,
  storeArticleCommentsTags: PropTypes.func,
  storeComments: PropTypes.array,
  setStoreComments: PropTypes.func,
  storeTags: PropTypes.array,
  hiddenArticleTigger: PropTypes.bool,
  setHiddenArticle: PropTypes.func,
  hiddenArticle: PropTypes.array,
  setStoreTags: PropTypes.func,
  syndicationClickData: PropTypes.object,
  name: PropTypes.string,
  refetchHiddleArticles: PropTypes.func,
  showFullTitle: PropTypes.any,
  bookmarksLocal: PropTypes.array,
  hiddenArticlesLocal: PropTypes.array,
  handlePage: PropTypes.func,
  setInsearchPageNum: PropTypes.func,
  isPopup: PropTypes.bool,
  searchKey: PropTypes.string,
  showFullTitle: PropTypes.string,
  summaryData: PropTypes.object,
  tagActionType: PropTypes.string,
  showAiSummaryBtn: PropTypes.bool,
  setFetchAiSummary: PropTypes.func,
  fetchAiSummary: PropTypes.bool,
  setScrollHeight: PropTypes.func,
  articleType: PropTypes.object,
  setSelectedArticle: PropTypes.func,
  articlePagingInfo: PropTypes.array,
  isLoading: PropTypes.bool,
  advanceArticleType: PropTypes.object,
  setStoryAnalysisPopup: PropTypes.func,
  setStoryAnalysisData: PropTypes.func,
  setSimilarStoriesIds: PropTypes.func,
  getStoryAnalysisData: PropTypes.func,
  setErrorMessage: PropTypes.func,
  storyAnalysis: PropTypes.bool,
  topTrendingChart: PropTypes.bool,
  customData: PropTypes.object,
  onSentimentUpdate: PropTypes.func,
  sentimentsLocal: PropTypes.array,
};

const RenderImage = ({ url }) => {
  // eslint-disable-next-line no-unused-vars
  const [_isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const handleImageLoad = () => setIsLoading(false);
  const handleImageError = () => {
    setIsLoading(false);
    setHasError(true);
  };
  return (
    <img
      src={`${hasError ? ArticleImageNAImg : url}`}
      alt="article thumbnail"
      onLoad={handleImageLoad}
      onError={handleImageError}
      style={{
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      }}
      loading="lazy"
    />
  );
};

RenderImage.propTypes = {
  url: PropTypes.string,
};

export default Articles;
