import {
  brandCharts,
  peopleCharts,
  industryCharts,
  competitionCharts,
  campaignMonitorCharts,
  socialCampaignCharts,
  contentDashboardCharts,
} from '../../pages/new-dashboard/utils';
import { brand_mapping } from './brand-mapping';
import { people_mapping } from './people-mapping';
import { industry_mapping } from './industry-mapping';
import { competition_mapping } from './competition-mapping';
import { campaign_mapping } from './campaign-mapping';
import { message_congruence_mapping } from './message-congruence-mapping';
import { social_campaign_mapping } from './social-campaign-mapping';
import { content_dashboard_mapping } from './content-dashboard-mapping';

export const getGraphData = (resData, graphType) => {
  let resp;

  if (brandCharts.find((x) => x?.chartId === graphType)) {
    resp = brand_mapping(resData, graphType);
    resp.dashboardType = 'brand';
    resp.customClassName = graphType;
  } else if (competitionCharts.find((x) => x?.chartId === graphType)) {
    resp = competition_mapping(resData, graphType);
    resp.dashboardType = 'brand';
    resp.customClassName = graphType;
  } else if (peopleCharts.find((x) => x?.chartId === graphType)) {
    resp = people_mapping(resData, graphType);
    resp.dashboardType = 'people';
    resp.customClassName = graphType;
  } else if (industryCharts.find((x) => x?.chartId === graphType)) {
    resp = industry_mapping(resData, graphType);
    resp.dashboardType = 'industry';
    resp.customClassName = graphType;
  } else if (campaignMonitorCharts.find((x) => x?.chartId === graphType)) {
    resp = campaign_mapping(resData, graphType);
    resp.dashboardType = 'campaign';
    resp.customClassName = graphType;
  } else if (graphType === 'congruence') {
    resp = message_congruence_mapping(resData, graphType);
    resp.customClassName = graphType;
    resp.dashboardType = 'congruence';
  } else if (socialCampaignCharts.find((x) => x?.chartId === graphType)) {
    resp = social_campaign_mapping(resData, graphType);
    resp = {
      dashboardType: 'socialCampaign',
      [graphType]: {
        data: resp,
        show: true,
        customClassName: graphType,
      },
    };
  } else if (contentDashboardCharts.find((x) => x?.chartId === graphType)) {
    resp = content_dashboard_mapping(resData, graphType);
    if (graphType === 'advanced_content_engagement') {
      resp = {
        dashboardType: 'contentDashboard',
        customClassName: graphType,
        [graphType]: {
          ...resp,
          show: true,
          customClassName: graphType,
          component: graphType,
        },
      };
    } else if (graphType === 'advanced_content_top_posts') {
      resp = {
        dashboardType: 'contentDashboard',
        customClassName: graphType,
        topEngagingPosts: {
          data: resp,
          show: true,
          customClassName: graphType,
          component: graphType,
        },
      };
    } else {
      resp = {
        dashboardType: 'contentDashboard',
        customClassName: graphType,
        [graphType]: {
          data: resp,
          show: true,
          customClassName: graphType,
          component: graphType,
        },
      };
    }
  }
  return resp;
};
