import { useQuery } from '@tanstack/react-query';
import { API } from '../constants';
import { get } from '../service';
import { objectToQueryString } from './useSearch';
import {
  addCountPrefix,
  calculatePercentage,
  calculatePercentageIncrease,
  colorCodeObjects,
  getSelectedTypesForSocialGraph,
  getSelectedTypesForTraditionalGraph,
} from '../constants/utils';
import { topThemeColors } from '../graphs/utils/graphConst';
import {
  geographicalMapData as TempGeoGraphicalMapData,
  mediaTypeChartMapData as TempMediaTypeChartMapData,
  outletBreakdownMapData as TempOutletBreakdownMapData,
  resultOverTimeMapData as TempResultOverTimeMapData,
  sentimentChartMapData as tempSentimentChartMapData,
  topAuthorChartMapData as tempTopAuthorChartMapData,
  topSourceChartMapData as TempTopSourceChartMapData,
  totalOverviewDetailsMap as tempOverviewDetailsMap,
  wordCloudChartMapData as TempWordCloudChartMapData,
  mediaTabData,
  geographicalWorldMapData,
  topSourceSocialChartMapData,
  topInfluencerChartMapData as TempTopInfluencerChartMapData,
  topSubredditChartMapData as TempTopSubredditChartMapData,
  topHashtagsChartMapData as TempTopHashtagsChartMapData,
} from './data/chartData';
import { format, parseISO } from 'date-fns';
import { StatesWithMapData } from './data/states';
import { WordCloudColors } from './data/colors';
import {
  blueColorGradients,
  coolGrayColorGradients,
  magentaColorGradients,
  purpleColorGradients,
  tealColorGradients,
  cyanColorGradients,
  orangeColorGradients,
} from '../constants/graph-colors';
import twitterLogo from '../assets/img/twitterLogo.png';
import redditLogo from '../assets/img/reddit.png';
import { topThemeChartMapData as TempTopThemeChartMapData } from './data/advancedSocialCampaign';

const social = [
  'X (Twitter)',
  'Blogs',
  'Forums',
  'Reviews',
  'Reddit',
  'YouTube',
];
const traditional = ['Online', 'Print'];

const getSelectedTypes = (filters) => {
  if (Array.isArray(filters?.media_types)) {
    const isSocial = filters?.media_types?.some((x) => social?.includes(x));
    const isTraditional = filters?.media_types?.some((x) =>
      traditional?.includes(x)
    );
    if (isSocial && isTraditional) {
      return 'all';
    }
    if (isSocial) {
      return 'social';
    }
    if (isTraditional) {
      return 'traditional';
    }
  }
  return 'all';
};

const getMediaTypes = (filters) => {
  if (Array.isArray(filters?.media_types)) {
    return filters?.media_types;
  }
  return [
    'Online',
    'Print',
    'Forums',
    'Blogs',
    'Reviews',
    'X (Twitter)',
    'Reddit',
    'YouTube',
  ];
};

export const getSentimentChartData = async (payload) => {
  let { data: response } = await get(
    `${API}/search-visuals/sentiments-average?${objectToQueryString(payload)}`,
    {}
  );

  if (!response) {
    response = {};
  }

  const sentimentChartMapData = JSON.parse(
    JSON.stringify(tempSentimentChartMapData)
  );
  // Set Percentage here
  const percentage = calculatePercentage(response);
  const sentimeMentMapData = sentimentChartMapData?.data.data;
  const updatedSentimentMapData = sentimeMentMapData?.map((x) => {
    return {
      ...x,
      value: percentage[x.label],
      labelColor: x?.color,
    };
  });

  const totalArticlesMapData = sentimentChartMapData.data.summary;
  totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
  sentimentChartMapData.data.summary = totalArticlesMapData;
  sentimentChartMapData.data.data = updatedSentimentMapData || [];
  sentimentChartMapData.shouldShowGraph = response?.total_count > 0;
  return sentimentChartMapData;
};

export const useSentimentChartData = (filters, payload, editMode) => {
  return useQuery({
    queryKey: ['sentiment-charts', filters, payload],
    queryFn: () => getSentimentChartData(payload),
    refetchOnWindowFocus: false,
    enabled: !editMode,
  });
};

export const getMediaCountAPI = async (payload) => {
  const refinedPayload = {
    ...payload,
  };
  const { data: response } = await get(
    `${API}/search-visuals/media-type-average?${objectToQueryString(
      refinedPayload
    )}`,
    {}
  );
  const mediaTypesFromRes = response?.media_types || [];
  const updatedMediaCountData = mediaTabData?.map((x) => {
    const mdDataForMap = mediaTypesFromRes.find((mdData) =>
      mdData?.type?.toLowerCase().includes(x.label?.toLowerCase())
    );
    if (mdDataForMap) {
      return {
        ...x,
        count: mdDataForMap?.count,
      };
    } else if (x.value === 'totalArticles') {
      return {
        ...x,
        count: mediaTypesFromRes.reduce((accumulator, currentValue) => {
          return accumulator + currentValue.count;
        }, 0),
      };
    }
    return {
      ...x,
      count: 0,
    };
  });
  return updatedMediaCountData;
};

export const getMediaTypeChartData = async (payload) => {
  let { data: response } = await get(
    `${API}/search-visuals/media-type-average?${objectToQueryString(payload)}`,
    {}
  );

  if (!response) {
    response = {};
  }
  const mediaTypeChartMapData = JSON.parse(
    JSON.stringify(TempMediaTypeChartMapData)
  );

  const totalArticlesMapData = mediaTypeChartMapData.data.summary;
  totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
  mediaTypeChartMapData.data.summary = totalArticlesMapData;

  const mediaData = mediaTypeChartMapData?.data?.data;

  const mediaTypesFromRes = response?.media_types;
  const updatedMediaCountData = mediaData?.map((x) => {
    const mdDataForMap = mediaTypesFromRes.find((mdData) =>
      mdData?.type?.toLowerCase().includes(x.label?.toLowerCase())
    );
    if (mdDataForMap) {
      return {
        ...x,
        value: mdDataForMap?.count,
        labelColor: x?.color,
      };
    }
    return {
      ...x,
      value: 0,
      labelColor: x?.color,
    };
  });
  mediaTypeChartMapData.data.data = updatedMediaCountData || [];
  mediaTypeChartMapData.shouldShowGraph = response?.total_count > 0;
  return mediaTypeChartMapData;
};

export const useMediaTypeChartData = (filters, payload, editMode) => {
  return useQuery({
    queryKey: ['medai-type-charts', filters, payload],
    queryFn: () => getMediaTypeChartData(payload),
    refetchOnWindowFocus: false,
    enabled: !editMode,
  });
};

export const getWordCloudChartData = async (payload) => {
  let { data: response } = await get(
    `${API}/search-visuals/wordcloud-count?${objectToQueryString(payload)}`,
    {}
  );

  if (!response) {
    response = {};
  }

  const wordCloudChartMapData = JSON.parse(
    JSON.stringify(TempWordCloudChartMapData)
  );

  const totalArticlesMapData = wordCloudChartMapData.data.summary;
  totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
  wordCloudChartMapData.data.summary = totalArticlesMapData;

  const worldCloudFromRes = response?.data;
  const counts = {
    PERSONS: 0,
    COMPANIES: 0,
    LOCATIONS: 0,
    HASHTAGS: 0,
    OTHERS: 0,
  };
  const legendsConfig = {
    PERSONS: { label: 'People', color: magentaColorGradients.magenta60 },
    COMPANIES: { label: 'Organizations', color: purpleColorGradients.purple60 },
    LOCATIONS: { label: 'Locations', color: orangeColorGradients.orange60 },
    HASHTAGS: { label: 'Hashtags', color: cyanColorGradients.cyan50 },
    OTHERS: { label: 'Others', color: tealColorGradients.teal50 },
  };
  const updatedWordCloudData = worldCloudFromRes
    ?.filter((x) => {
      return x?.type !== 'LOCATIONS';
    })
    ?.map((x, i) => {
      if (x?.type in counts) {
        counts[x.type] += 1;
      }
      return {
        value: x?.article_count,
        thresholdValue: x?.count,
        label: x?.label,
        labelColor: WordCloudColors[x?.type],
        color: WordCloudColors[x?.type],
        type: x?.type,
      };
    });
  const legends = Object.keys(counts)
    .filter((key) => counts[key] > 0)
    .map((key) => legendsConfig[key]);
  wordCloudChartMapData.data.legends = legends;
  wordCloudChartMapData.data.data = updatedWordCloudData || [];
  wordCloudChartMapData.shouldShowGraph = response?.data?.length > 0;
  return wordCloudChartMapData;
};

export const useWordCloudChartData = (filters, payload, editMode) => {
  return useQuery({
    queryKey: ['word-cloud-charts', filters, payload],
    queryFn: () => getWordCloudChartData(payload),
    refetchOnWindowFocus: false,
    enabled: !editMode,
  });
};

export const getTopSourceChartData = async (payload) => {
  const { mediaTypes } = getSelectedTypesForTraditionalGraph(payload);
  const queryString = {
    ...payload,
    media_types: mediaTypes,
  };
  let { data: response } = await get(
    `${API}/search-visuals/top-source-count?${objectToQueryString(
      queryString
    )}`,
    {}
  );

  if (!response) {
    response = {};
  }

  const topSourceChartMapData = JSON.parse(
    JSON.stringify(TempTopSourceChartMapData)
  );

  const totalArticlesMapData = topSourceChartMapData.data.summary;
  totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
  topSourceChartMapData.data.summary = totalArticlesMapData;

  const topSourceRes = response?.sources;

  const updatedTopSourceData = topSourceRes?.slice(0, 10)?.map((x, i) => {
    return {
      value: x?.count,
      label: x?.source,
      color: '#675EF2',
      labelColor: blueColorGradients.blue60,
    };
  });

  topSourceChartMapData.data.data =
    colorCodeObjects(updatedTopSourceData) || [];
  topSourceChartMapData.shouldShowGraph = topSourceRes?.length > 0;
  return topSourceChartMapData;
};

export const useTopSourceChartData = (filters, payload, editMode) => {
  return useQuery({
    queryKey: ['top-source-charts', filters, payload],
    queryFn: () => getTopSourceChartData(payload),
    refetchOnWindowFocus: false,
    enabled: !editMode,
  });
};

export const getTopSourceSocialChartData = async (payload) => {
  const { mediaTypes } = getSelectedTypesForSocialGraph(payload);
  const queryString = {
    ...payload,
    media_types: mediaTypes,
  };
  let { data: response } = await get(
    `${API}/search-visuals/top-source-count?${objectToQueryString(
      queryString
    )}`,
    {}
  );

  if (!response) {
    response = {};
  }

  const topSourceChartMapData = JSON.parse(
    JSON.stringify(topSourceSocialChartMapData)
  );

  const totalArticlesMapData = topSourceChartMapData.data.summary;
  totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
  topSourceChartMapData.data.summary = totalArticlesMapData;

  const topSourceRes = response?.sources;

  const updatedTopSourceData = topSourceRes?.slice(0, 10)?.map((x, i) => {
    return {
      value: x?.count,
      label: x?.source,
      color: '#675EF2',
      labelColor: blueColorGradients.blue60,
    };
  });

  topSourceChartMapData.data.data =
    colorCodeObjects(updatedTopSourceData) || [];
  topSourceChartMapData.shouldShowGraph = topSourceRes?.length > 0;
  return topSourceChartMapData;
};

export const useTopSourceSocialChartData = (filters, payload, editMode) => {
  return useQuery({
    queryKey: ['top-source-charts-social', filters, payload],
    queryFn: () => getTopSourceSocialChartData(payload),
    refetchOnWindowFocus: false,
    enabled: !editMode,
  });
};

export const getTopThemeChartData = async (payload) => {
  let { data: response } = await get(
    `${API}/search-visuals/top-themes-count?${objectToQueryString(payload)}`,
    {}
  );

  if (!response) {
    response = {};
  }
  const topThemeChartMapData = JSON.parse(
    JSON.stringify(TempTopThemeChartMapData)
  );

  topThemeChartMapData.title = 'Popular Topics';

  const totalArticlesMapData = topThemeChartMapData.data.summary;
  totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
  topThemeChartMapData.data.summary = totalArticlesMapData;

  const topThemeRes = response?.data;

  const updatedTopSourcedata = topThemeRes?.slice(0, 5)?.map((x, i) => {
    const colorIndex = i % topThemeColors.length;
    return {
      ...topThemeColors[colorIndex],
      value: x?.article_count === 0 ? 1 : x?.article_count,
      secondaryValue: x.secondary_theme,
      label: x?.primary_theme,
      thresholdValue: x?.article_count === 0 ? 1 : x?.article_count,
      keyword: x?.keyword,
    };
  });
  topThemeChartMapData.data.data = updatedTopSourcedata || [];
  topThemeChartMapData.shouldShowGraph = topThemeRes?.length > 0;
  topThemeChartMapData.originalData = response;
  // topThemeChartMapData.shouldShowGraph = true;
  return topThemeChartMapData;
};

export const useTopThemeChartData = (filters, payload, editMode) => {
  return useQuery({
    queryKey: ['top-theme-charts', filters, payload],
    queryFn: () => getTopThemeChartData(payload),
    refetchOnWindowFocus: false,
    enabled: !editMode,
  });
};

const getTopAuthorChartData = async (payload) => {
  let { data: response } = await get(
    `${API}/search-visuals/top-author-count?${objectToQueryString(payload)}`,
    {}
  );

  if (!response) {
    response = {};
  }

  const topAuthorChartMapData = JSON.parse(
    JSON.stringify(tempTopAuthorChartMapData)
  );

  topAuthorChartMapData.component = 'top_author';

  const totalArticlesMapData = topAuthorChartMapData.data.summary;
  totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
  topAuthorChartMapData.data.summary = totalArticlesMapData;

  const topAuthorRes = response?.authors;

  const updatedTopSourcedata = topAuthorRes
    ?.slice(0, 10)
    ?.filter((x) => Number(x?.count) !== 0)
    ?.map((x, i) => {
      return {
        author_id: x?.author_id,
        value: x?.count,
        label: x?.author,
        thresholdValue: x?.count,
        color: '#22B9FF',
        labelColor: blueColorGradients.blue60,
      };
    });

  topAuthorChartMapData.data.data = updatedTopSourcedata || [];
  topAuthorChartMapData.shouldShowGraph = topAuthorRes?.length > 0;
  return topAuthorChartMapData;
};

export const useTopAuthorChartData = (filters, payload, editMode) => {
  return useQuery({
    queryKey: ['top-author-charts', filters, payload],
    queryFn: () => getTopAuthorChartData(payload),
    refetchOnWindowFocus: false,
    enabled: !editMode,
  });
};

const getOverviewDetails = async (payload) => {
  const { data: response } = await get(
    `${API}/search-visuals/overview-details?${objectToQueryString(payload)}`,
    {}
  );

  const totalOverviewDetailsMap = JSON.parse(
    JSON.stringify(tempOverviewDetailsMap)
  );

  if (!response) {
    return totalOverviewDetailsMap;
  }

  const mappedData = response?.map((x) => {
    return {
      title: x?.label,
      data:
        x?.label === 'Traditional Reach'
          ? parseInt(x?.current_count)
          : x?.current_count,
      change: x?.previous_count !== 0 ? parseInt(x?.changes_percent) : 100,
      isIncreased: x?.changes_percent >= 0,
      previousCount: x?.previous_count,
    };
  });

  return mappedData || totalOverviewDetailsMap;
};

export const useOverviewDetails = (filters, payload, editMode) => {
  return useQuery({
    queryKey: ['top-overview-details', filters, payload],
    queryFn: () => getOverviewDetails(payload),
    refetchOnWindowFocus: false,
    enabled: !editMode,
  });
};

export function identifyDateFormat(dateString) {
  if (dateString === undefined) {
    return;
  }
  if (dateString?.includes('AM') || dateString?.includes('PM')) {
    return 'Hour';
  }

  if (/\d{4}-\d{2}-\d{2}/.test(dateString)) {
    return 'Day';
  }

  if (/\d{4}-\d{2}/.test(dateString)) {
    return 'Month';
  }
  throw Error('Unkown format');
}

export const getFormattedDate = (date) => {
  const dateFormat = identifyDateFormat(date);
  if (dateFormat === 'Hour') {
    const parseDate = parseISO(date?.slice(0, 11));
    const hoursDate = date;
    const hoursTime = hoursDate?.slice(11);
    const updatedDate = format(parseDate, 'dd MMM yy') + ' ' + hoursTime;
    return updatedDate;
  } else if (dateFormat === 'Month' || dateFormat === 'Day') {
    const parseDate = parseISO(date);
    const updatedDate = format(
      parseDate,
      dateFormat === 'Month' ? 'MMM yyyy' : 'dd MMM yy'
    );
    return updatedDate;
  }
  return '';
};

export const getDateRangeLabel = (startDate, endDate) => {
  const startFormat = identifyDateFormat(startDate);
  const endFormat = identifyDateFormat(endDate);

  const formattedStart = getFormattedDate(startDate);
  const formattedEnd = getFormattedDate(endDate);

  // Return single date if start and end are same
  if (formattedStart === formattedEnd) {
    return formattedStart;
  }

  if (startFormat === 'Hour' && endFormat === 'Hour') {
    return `${formattedStart} to ${formattedEnd}`;
  }

  if (startFormat === 'Month' && endFormat === 'Month') {
    return `${formattedStart} to ${formattedEnd}`;
  }

  if (startFormat === 'Day' && endFormat === 'Day') {
    return `${formattedStart} to ${formattedEnd}`;
  }

  // Handle mixed formats if needed
  return `${formattedStart} to ${formattedEnd}`;
};

const getResultOverTimeData = async (payload) => {
  let { data: response } = await get(
    `${API}/search-visuals/datewise-count?${objectToQueryString(payload)}`,
    {}
  );

  const selectedMedia = getSelectedTypes(payload);

  if (!response) {
    response = {};
  }
  const resultOverTimeMapData = JSON.parse(
    JSON.stringify(TempResultOverTimeMapData)
  );
  // setting total count for area
  const totalArticlesAreaMapData = resultOverTimeMapData.area.data.summary;
  totalArticlesAreaMapData.value = String(
    addCountPrefix(response?.total_count)
  );
  resultOverTimeMapData.area.data.summary = totalArticlesAreaMapData;

  // setting total count for column
  const totalArticlesColumnMapData = resultOverTimeMapData.column.data.summary;
  totalArticlesColumnMapData.value = String(
    addCountPrefix(response?.total_count)
  );
  resultOverTimeMapData.column.data.summary = totalArticlesColumnMapData;

  const resultArea = response?.data;

  let totalOnlineCount = 0;
  let totalPrintCount = 0;
  let totalBlogsCount = 0;
  let totalForumsCount = 0;
  let totalReviewsCount = 0;
  let totalTwitterCount = 0;
  let totalRedditCount = 0;
  let totalYouTubeCount = 0;
  let totalSocialCount = 0;
  let totalTraditionCount = 0;

  resultArea?.forEach((x) => {
    const onlineMedia = x?.media_type?.find((media) => media.key === 'Online');
    const PrintMedia = x?.media_type?.find((media) => media.key === 'Print');
    const forumMedia = x?.media_type?.find((media) => media.key === 'Forums');
    const blogMedia = x?.media_type?.find((media) => media.key === 'Blogs');
    const reviewMedia = x?.media_type?.find((media) => media.key === 'Reviews');
    const twitterMedia = x?.media_type?.find(
      (media) => media.key === 'Twitter' || media.key === 'X (Twitter)'
    );
    const redditMedia = x?.media_type?.find((media) => media?.key === 'Reddit');
    const YouTubeMedia = x?.media_type?.find(
      (media) => media.key === 'YouTube'
    );
    const onlineDocCount = onlineMedia ? onlineMedia.doc_count : 0;
    const printDocCount = PrintMedia ? PrintMedia.doc_count : 0;
    const forumDocCount = forumMedia ? forumMedia.doc_count : 0;
    const blogDocCount = blogMedia ? blogMedia.doc_count : 0;
    const reviewDocCount = reviewMedia ? reviewMedia.doc_count : 0;
    const twitterDocCount = twitterMedia ? twitterMedia.doc_count : 0;
    const redditDocCount = redditMedia ? redditMedia.doc_count : 0;
    const YouTubeDocCount = YouTubeMedia ? YouTubeMedia.doc_count : 0;

    totalOnlineCount += onlineDocCount;
    totalPrintCount += printDocCount;
    totalBlogsCount += blogDocCount;
    totalReviewsCount += reviewDocCount;
    totalForumsCount += forumDocCount;
    totalTwitterCount += twitterDocCount;
    totalRedditCount += redditDocCount;
    totalYouTubeCount += YouTubeDocCount;
    totalSocialCount =
      totalSocialCount +
      totalTwitterCount +
      +totalForumsCount +
      totalReviewsCount +
      totalBlogsCount +
      totalRedditCount +
      totalYouTubeCount;
    totalTraditionCount =
      totalTraditionCount + totalOnlineCount + totalPrintCount;
  });
  // Setting Area Data
  const updatedAreaResults = resultArea?.map((x) => {
    const formattedDate = getFormattedDate(x?.date ?? x.label);
    const onlineMedia = x?.media_type?.find((media) => media.key === 'Online');
    const PrintMedia = x?.media_type?.find((media) => media.key === 'Print');
    const forumMedia = x?.media_type?.find((media) => media.key === 'Forums');
    const blogMedia = x?.media_type?.find((media) => media.key === 'Blogs');
    const reviewMedia = x?.media_type?.find((media) => media.key === 'Reviews');
    const twitterMedia = x?.media_type?.find((media) => {
      return media?.key?.toLowerCase()?.includes('twitter');
    });
    const redditMedia = x?.media_type?.find((media) => media?.key === 'Reddit');
    const YouTubeMedia = x?.media_type?.find(
      (media) => media?.key === 'YouTube'
    );

    const onlineDocCount = onlineMedia ? onlineMedia.doc_count : 0;
    const printDocCount = PrintMedia ? PrintMedia.doc_count : 0;
    const forumDocCount = forumMedia ? forumMedia.doc_count : 0;
    const blogDocCount = blogMedia ? blogMedia.doc_count : 0;
    const reviewDocCount = reviewMedia ? reviewMedia.doc_count : 0;
    const twitterDocCount = twitterMedia ? twitterMedia.doc_count : 0;
    const redditDocCount = redditMedia ? redditMedia.doc_count : 0;
    const YouTubeDocCount = YouTubeMedia ? YouTubeMedia.doc_count : 0;
    const socialDocCount =
      twitterDocCount +
      blogDocCount +
      forumDocCount +
      reviewDocCount +
      redditDocCount +
      YouTubeDocCount;
    const traditionDocCount = onlineDocCount + printDocCount;

    return {
      value: onlineDocCount,
      label: formattedDate,
      date: x?.date ?? x.label,
      printCount: printDocCount,
      onlineCount: onlineDocCount,
      blogCount: blogDocCount,
      forumCount: forumDocCount,
      reviewCount: reviewDocCount,
      twitterCount: twitterDocCount,
      redditCount: redditDocCount,
      YouTubeCount: YouTubeDocCount,
      traditionalCount:
        selectedMedia === 'all' || selectedMedia === 'traditional'
          ? traditionDocCount
          : 0,
      socialCount:
        selectedMedia === 'all' || selectedMedia === 'social'
          ? socialDocCount
          : 0,
    };
  });
  resultOverTimeMapData.area.data.data =
    updatedAreaResults?.length > 0 ? updatedAreaResults : [];

  const updatedColumnResults = resultArea?.map((x) => {
    const formattedDate = getFormattedDate(x?.date ?? x.label);
    const printType = x?.media_type?.find((media) => {
      return media?.key?.toLowerCase()?.includes('print');
    });
    const onlineType = x?.media_type?.find((media) => {
      return media?.key?.toLowerCase()?.includes('online');
    });
    const blogsType = x?.media_type?.find((media) => {
      return media?.key?.toLowerCase()?.includes('blogs');
    });
    const forumsType = x?.media_type?.find((media) => {
      return media?.key?.toLowerCase()?.includes('forums');
    });
    const reviewType = x?.media_type?.find((media) => {
      return media?.key?.toLowerCase()?.includes('reviews');
    });
    const twitterType = x?.media_type?.find((media) => {
      return media?.key?.toLowerCase()?.includes('twitter');
    });

    const redditType = x?.media_type?.find((media) => {
      return media?.key?.toLowerCase()?.includes('reddit');
    });

    const YouTubeType = x?.media_type?.find((media) => {
      return media?.key?.toLowerCase()?.includes('youtube');
    });

    const socialCount = String(
      selectedMedia === 'all' || selectedMedia === 'social'
        ? (twitterType?.doc_count || 0) +
            (forumsType?.doc_count || 0) +
            (blogsType?.doc_count || 0) +
            (reviewType?.doc_count || 0) +
            (redditType?.doc_count || 0) +
            (YouTubeType?.doc_count || 0)
        : 0
    );

    const traditionalCount = String(
      selectedMedia === 'all' || selectedMedia === 'traditional'
        ? (onlineType?.doc_count || 0) + (printType?.doc_count || 0)
        : 0
    );

    return {
      date: x?.date ?? x.label,
      label: formattedDate,
      online: onlineType ? String(onlineType?.doc_count) : '0',
      print: printType ? String(printType?.doc_count) : '0',
      forum: forumsType ? String(forumsType?.doc_count) : '0',
      blog: blogsType ? String(blogsType?.doc_count) : '0',
      review: reviewType ? String(reviewType?.doc_count) : '0',
      twitter: twitterType ? String(twitterType?.doc_count) : '0',
      reddit: redditType ? String(redditType?.doc_count) : '0',
      YouTube: YouTubeType ? String(YouTubeType?.doc_count) : '0',
      social: socialCount,
      traditional: traditionalCount,
      socialCount,
      traditionalCount,
    };
  });

  resultOverTimeMapData.column.data.data =
    updatedColumnResults?.length > 0 ? updatedColumnResults : [];
  resultOverTimeMapData.area.shouldShowGraph = updatedAreaResults?.length > 0;
  resultOverTimeMapData.column.shouldShowGraph =
    updatedColumnResults?.length > 0;

  resultOverTimeMapData.area.data.summary = {
    totalOnlineCount,
    totalPrintCount,
    totalBlogsCount,
    totalForumsCount,
    totalReviewsCount,
    totalTwitterCount,
    totalRedditCount,
    totalYouTubeCount,
    totalTraditionCount:
      selectedMedia === 'all' || selectedMedia === 'traditional'
        ? totalOnlineCount + totalPrintCount
        : 0,
    totalSocialCount:
      selectedMedia === 'all' || selectedMedia === 'social'
        ? totalBlogsCount +
          totalForumsCount +
          totalRedditCount +
          totalReviewsCount +
          totalTwitterCount +
          totalYouTubeCount
        : 0,
    ...resultOverTimeMapData.area.data.summary,
    mediaType: response?.media_filter ?? null,
  };

  resultOverTimeMapData.column.data.labels =
    selectedMedia === 'all'
      ? [
          {
            label: 'Social',
            value: 'social',
            color: magentaColorGradients.magenta50,
          },
          {
            label: 'Traditional',
            value: 'traditional',
            color: purpleColorGradients.purple60,
          },
        ]
      : selectedMedia === 'social'
      ? [
          {
            label: 'Social',
            value: 'social',
            color: magentaColorGradients.magenta50,
          },
        ]
      : [
          {
            label: 'Traditional',
            value: 'traditional',
            color: purpleColorGradients.purple60,
          },
        ];

  resultOverTimeMapData.column.data.summary = {
    totalOnlineCount,
    totalPrintCount,
    totalBlogsCount,
    totalForumsCount,
    totalReviewsCount,
    totalTwitterCount,
    totalRedditCount,
    totalYouTubeCount,
    totalTraditionCount:
      selectedMedia === 'all' || selectedMedia === 'traditional'
        ? totalOnlineCount + totalPrintCount
        : 0,
    totalSocialCount:
      selectedMedia === 'all' || selectedMedia === 'social'
        ? totalBlogsCount +
          totalForumsCount +
          totalRedditCount +
          totalReviewsCount +
          totalTwitterCount +
          totalYouTubeCount
        : 0,
    ...resultOverTimeMapData.column.data.summary,
    mediaType: response?.media_filter ?? null,
  };

  return resultOverTimeMapData;
};

export const useResultOverTimeData = (filters, payload, editMode) => {
  return useQuery({
    queryKey: ['top-date-wise-charts', filters, payload],
    queryFn: () => getResultOverTimeData(payload),
    refetchOnWindowFocus: false,
    enabled: !editMode,
  });
};

const getOutletBreakData = async (payload) => {
  let { data: response } = await get(
    `${API}/search-visuals/outlet-breakdown-media-type?${objectToQueryString(
      payload
    )}`,
    {}
  );

  if (!response) {
    response = {};
  }
  const outletBreakdownMapData = JSON.parse(
    JSON.stringify(TempOutletBreakdownMapData)
  );
  const selectedMedia = getSelectedTypes(payload);

  // const summaryData = outletBreakdownMapData?.data?.summary?.data;
  const mediaTypesSummary = response?.data;

  const totalCount = mediaTypesSummary?.reduce(
    (sum, obj) => sum + obj.doc_count,
    0
  );

  let results;

  if (selectedMedia === 'all') {
    const social = { label: 'Social' };
    const traditional = { label: 'Traditional' };

    mediaTypesSummary.forEach((item) => {
      if (item.media_type === 'Online' || item.media_type === 'Print') {
        // Sum the values for "Traditional"
        const totalDocCount = item.submedia_types.reduce(
          (sum, subItem) => sum + subItem.doc_count,
          0
        );
        traditional[item.media_type] =
          (traditional[item.media_type] || 0) + totalDocCount;
      } else {
        // Sum the values for "Social"
        const totalDocCount = item.submedia_types.reduce(
          (sum, subItem) => sum + subItem.doc_count,
          0
        );
        social[item.media_type] =
          (social[item.media_type] || 0) + totalDocCount;
      }
    });

    // Sort each group's properties by value from largest to smallest
    const sortedSocial = { label: 'Social' };
    const sortedTraditional = { label: 'Traditional' };

    Object.entries(social)
      .filter(([key]) => key !== 'label')
      .sort(([, a], [, b]) => b - a)
      .forEach(([key, value]) => {
        sortedSocial[key] = value;
      });

    Object.entries(traditional)
      .filter(([key]) => key !== 'label')
      .sort(([, a], [, b]) => b - a)
      .forEach(([key, value]) => {
        sortedTraditional[key] = value;
      });

    // Final result with sorted entries
    const finalResult = [sortedTraditional, sortedSocial];
    results = finalResult;
  } else {
    // For other cases, return the normal transformed data with submedia_types
    const transformedData = mediaTypesSummary.map((item) => {
      const transformedItem = { label: item.media_type };
      item.submedia_types.forEach((subItem) => {
        transformedItem[subItem.key] = subItem.doc_count;
      });
      return transformedItem;
    });

    results = transformedData;
  }
  outletBreakdownMapData.data.summary.value = String(
    addCountPrefix(totalCount || 0)
  );
  outletBreakdownMapData.data.data = results || [];
  outletBreakdownMapData.shouldShowGraph = mediaTypesSummary?.length > 0;

  return outletBreakdownMapData;
};

export const useMediaOutletBreakdown = (filters, payload, editMode) => {
  return useQuery({
    queryKey: ['outlet-breakdown-media-type', filters, payload],
    queryFn: () => getOutletBreakData(payload),
    refetchOnWindowFocus: false,
    enabled: !editMode,
  });
};

export const getGeographicalBreakDown = async (payload) => {
  let { data: response } = await get(
    `${API}/search-visuals/geographical-breakdown?${objectToQueryString(
      payload
    )}`,
    {}
  );

  if (!response) {
    response = {};
  }

  const geographicalMapData = JSON.parse(
    // JSON.stringify(TempGeoGraphicalMapData)
    JSON.stringify(geographicalWorldMapData)
  );

  const totalArticlesMapData = geographicalMapData.data.summary;
  totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
  geographicalMapData.data.summary = totalArticlesMapData;

  const geographicalRes = response?.data;

  const updatedGeographicalRes = geographicalRes?.map((state) => {
    return {
      label: state.country,
      value: state.current_count,
      labelColor:
        state.current_count > 0
          ? purpleColorGradients.purple60
          : coolGrayColorGradients.coolGray20,
    };
  });

  geographicalMapData.data.data = updatedGeographicalRes;
  geographicalMapData.shouldShowGraph = geographicalRes?.length > 0;
  return geographicalMapData;
};

export const useGeographicalBreakdownData = (filters, payload, editMode) => {
  return useQuery({
    queryKey: ['search-visuals-geographical-breakdown', filters, payload],
    queryFn: () => getGeographicalBreakDown(payload),
    refetchOnWindowFocus: false,
    enabled: !editMode,
  });
};

export const getTopInfluencers = async (payload) => {
  let { data: response } = await get(
    `${API}/search-visuals/top-influncers-count?${objectToQueryString(
      payload
    )}`,
    {}
  );

  if (!response) {
    response = {};
  }

  const topInfluencerChartMapData = JSON.parse(
    JSON.stringify(TempTopInfluencerChartMapData)
  );

  const totalArticlesMapData = topInfluencerChartMapData.data.summary;
  totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
  topInfluencerChartMapData.data.summary = totalArticlesMapData;

  const topAuthorRes = response?.authors;

  const updatedTopInfluencerData = topAuthorRes?.slice(0, 10)?.map((x, i) => {
    return {
      author_id: x?.author_id,
      value: x?.count,
      authorName: x?.author,
      mentions: x?.count,
      reach: x?.reach,
      source: x?.source,
      logoUrl:
        x?.source === 'Reddit'
          ? redditLogo
          : x?.source === 'X (Twitter)'
          ? twitterLogo
          : '',
    };
  });

  topInfluencerChartMapData.data.data = updatedTopInfluencerData || [];
  topInfluencerChartMapData.shouldShowGraph = topAuthorRes?.length > 0;
  return topInfluencerChartMapData;
};

export const useTopInfluencersData = (filters, payload, editMode) => {
  return useQuery({
    queryKey: ['top-influencer-charts', filters, payload],
    queryFn: () => getTopInfluencers(payload),
    refetchOnWindowFocus: false,
    enabled: !editMode,
  });
};

export const getTopHashtags = async (payload) => {
  let { data: response } = await get(
    `${API}/search-visuals/top-hashtags?${objectToQueryString(payload)}`,
    {}
  );

  if (!response) {
    response = {};
  }

  const topHashtagsChartMapData = JSON.parse(
    JSON.stringify(TempTopHashtagsChartMapData)
  );

  const totalArticlesMapData = topHashtagsChartMapData.data.summary;
  totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
  topHashtagsChartMapData.data.summary = totalArticlesMapData;

  const topAuthorRes = response?.hashtags;

  const updatedTopHashtagsData = topAuthorRes?.slice(0, 10)?.map((x, i) => {
    return {
      hashtag: '#' + x?.label,
      mentions: x?.mentions,
    };
  });

  topHashtagsChartMapData.data.data = updatedTopHashtagsData || [];
  topHashtagsChartMapData.shouldShowGraph = topAuthorRes?.length > 0;
  return topHashtagsChartMapData;
};

export const useTopHashtagsData = (filters, payload, editMode) => {
  return useQuery({
    queryKey: ['top-hashtags-charts', filters, payload],
    queryFn: () => getTopHashtags(payload),
    refetchOnWindowFocus: false,
    enabled: !editMode,
  });
};

export const getTopSubreddits = async (payload) => {
  let { data: response } = await get(
    `${API}/search-visuals/top-subreddit?${objectToQueryString(payload)}`,
    {}
  );

  if (!response) {
    response = {};
  }

  const topSubredditChartMapData = JSON.parse(
    JSON.stringify(TempTopSubredditChartMapData)
  );

  const totalArticlesMapData = topSubredditChartMapData.data.summary;
  totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
  topSubredditChartMapData.data.summary = totalArticlesMapData;

  const topAuthorRes = response?.data;

  const updatedTopSubredditData = topAuthorRes?.slice(0, 10)?.map((x, i) => {
    return {
      subredditName: x?.subreddit_name,
      totalVolume: x?.total_volume,
      totalPosts: x?.total_posts,
      totalComments: x?.total_comments,
      subscribers: x?.subscribers,
      totalScore: x?.total_score,
    };
  });

  topSubredditChartMapData.data.data = updatedTopSubredditData || [];
  topSubredditChartMapData.shouldShowGraph = topAuthorRes?.length > 0;
  return topSubredditChartMapData;
};

export const useTopSubredditData = (filters, payload, editMode) => {
  return useQuery({
    queryKey: ['top-subreddit-charts', filters, payload],
    queryFn: () => getTopSubreddits(payload),
    refetchOnWindowFocus: false,
    enabled: !editMode,
  });
};
