import React, { useState } from 'react';
import { NetworkMapContainer } from './index.sc';
import { HalfSlot, SlotWrp } from '../campaign-monitor-2/index.sc';
import PropTypes from 'prop-types';
import SlotDetails from '../../search-result/slot-details';
import { FullSlot } from '../author-impact/index.sc';
import NetworkClusterPopup from '../../network-cluster-popup';
import { useSelector } from 'react-redux';
import DashboardPopup from '../../dasboard-popup';
import { useNetworkKeyInfluencerData } from '../../../hooks/useAdvanceNetworkMap';
import {
  blueColorGradients,
  greenColorGradients,
  yellowColorGradients,
} from '../../../constants/graph-colors';

const NetworkMapDashboard = ({
  widget,
  dashboardType,
  handleOnClick = () => {},
  resetSelection,
  setSelectedComponent = () => {},
  selectedComponent = '',
  onDownloadChartData,
  downloadFunction,
  savedSelectedChart,
  handleGraphTitleUpdate,
  overviewDashboard,
}) => {
  const { networkPinData } = useSelector((state) => {
    if (dashboardType === 'networkMap' || overviewDashboard === 'networkMap') {
      return state.networkMap;
    }
  });

  const [networkPopup, setNetWorkPopup] = useState(false);
  const [networkDetails, setNetWorkDetails] = useState({});
  const [subClusterTitle, setSubClusterTitle] = useState('');
  const [selectedOrgOptions, setSelectedOrgOptions] = useState();
  const [selectedAuthorsOptions, setSelectedAuthorsOptions] = useState();
  const [clusterFilteredData, setClusterFilteredData] = useState([]);
  const [interestFilterNames, setInterestFilterNames] = useState([]);
  const [authorFilteredNames, setAuthorFilteredNames] = useState([]);

  const { mutateAsync: getNetworkUserDetails } = useNetworkKeyInfluencerData();

  const handleSelectedInterestDropdown = (
    selectAll,
    options,
    widgetNodeData
  ) => {
    if (Array?.isArray(options) && selectAll) {
      setSelectedOrgOptions(options);
      // filtering organization widget data
      const newNodeData = widgetNodeData?.nodes?.filter((item) =>
        options?.includes(item?.organization)
      );

      // updating links for the nodes
      const newLinkData = widgetNodeData?.links?.filter(
        (item) =>
          options?.includes(item?.source?.organization) &&
          options?.includes(item?.target?.organization)
      );

      // Updating authors selected filters
      const newAuthorNamesList = [];
      newNodeData.forEach((items) => {
        items.authors?.map((item) => newAuthorNamesList?.push(item?.author));
      });
      setSelectedAuthorsOptions([...new Set(newAuthorNamesList)]);

      // Updating nodes and links widget data
      widget.networkClusterDataWidgetDetails.data.data.data = {
        nodes: newNodeData,
        links: newLinkData,
        drillDownFlag: true,
      };
    } else {
      if (selectAll) {
        setSelectedOrgOptions([options]);
        // filtering organization widget data
        const newNodeData = widgetNodeData?.nodes?.filter(
          (item) => item?.organization === options
        );

        // updating links for the nodes
        const newLinkData = widgetNodeData?.links?.filter(
          (item) =>
            item?.source?.organization === options &&
            item?.target?.organization === options
        );
        // Updating authors selected filters
        setSelectedAuthorsOptions(
          newNodeData[0]?.authors?.map((item) => item?.author)
        );

        // Updating nodes and links widget data
        widget.networkClusterDataWidgetDetails.data.data.data = {
          nodes: newNodeData,
          links: newLinkData,
          drillDownFlag: true,
        };
      } else {
        setSelectedOrgOptions(options);
        // filtering organization widget data
        const newNodeData = widgetNodeData?.nodes?.filter((item) =>
          options?.includes(item?.organization)
        );

        // updating links for the nodes
        const newLinkData = widgetNodeData?.links?.filter(
          (item) =>
            options?.includes(item?.source?.organization) &&
            options?.includes(item?.target?.organization)
        );

        // Updating authors selected filters
        const newAuthorNamesList = [];
        newNodeData.forEach((items) => {
          items.authors?.map((item) => newAuthorNamesList?.push(item?.author));
        });
        setSelectedAuthorsOptions([...new Set(newAuthorNamesList)]);

        // Updating nodes and links widget data
        widget.networkClusterDataWidgetDetails.data.data.data = {
          nodes: newNodeData,
          links: newLinkData,
          drillDownFlag: true,
        };
      }
    }
  };

  const handleSelectedAuthorsDropdown = (
    selectAll,
    options,
    initialClusterData,
    widgetNodeData
  ) => {
    if (Array?.isArray(options) && selectAll) {
      setSelectedAuthorsOptions(options);
      // filtering author widget data
      const newNodeData = initialClusterData?.nodes?.filter(
        (item) =>
          options?.includes(item?.label) ||
          item?.authors?.some(
            (authItem) =>
              options?.includes(authItem?.author) &&
              (item?.author
                ? options?.includes(item?.author) &&
                  item?.label === item?.organization
                : item?.label === item?.organization)
          ) ||
          options?.includes(item?.author)
      );

      // updating links for the nodes
      const newLinkData = initialClusterData?.links?.filter(
        (item) =>
          (options?.includes(item?.source?.label) ||
            options?.includes(item?.target?.label)) &&
          (item?.source?.label === item?.source?.organization ||
            options?.includes(item?.target?.author))
      );

      // Updating organization selected filters
      setSelectedOrgOptions([
        ...new Set(
          initialClusterData?.nodes
            ?.filter(
              (item) =>
                item?.authors?.some(
                  (authItem) =>
                    options?.includes(authItem?.author) &&
                    (item?.author
                      ? options?.includes(item?.author) &&
                        item?.label === item?.organization
                      : item?.label === item?.organization)
                ) || options?.includes(item?.author)
            )
            ?.map((mapItem) => mapItem?.organization)
        ),
      ]);

      // Updating author widget data
      widget.networkClusterDataWidgetDetails.data.data.data = {
        nodes: newNodeData,
        links: newLinkData,
        drillDownFlag: true,
      };
    } else {
      if (selectAll) {
        setSelectedAuthorsOptions([options]);

        // filtering author widget data
        const newNodeData = initialClusterData?.nodes?.filter(
          (item) =>
            item?.label === options ||
            item?.authors?.some(
              (authItem) =>
                authItem?.author === options &&
                (item?.author
                  ? item?.author === options &&
                    item?.label === item?.organization
                  : item?.label === item?.organization)
            ) ||
            item?.author === options
        );

        // updating links for the nodes
        const newLinkData = initialClusterData?.links?.filter(
          (item) =>
            (item?.source?.label === options ||
              item?.target?.label === options) &&
            (item?.source?.label === item?.source?.organization ||
              item?.target?.author === options)
        );

        // Updating organization selected filters
        setSelectedOrgOptions([
          ...new Set(
            initialClusterData?.nodes
              ?.filter(
                (item) =>
                  item?.authors?.some(
                    (authItem) =>
                      authItem?.author === options &&
                      (item?.author
                        ? item?.author === options &&
                          item?.label === item?.organization
                        : item?.label === item?.organization)
                  ) || item?.author === options
              )
              ?.map((mapItem) => mapItem?.organization)
          ),
        ]);

        // Updating author widget data
        widget.networkClusterDataWidgetDetails.data.data.data = {
          nodes: newNodeData,
          links: newLinkData,
          drillDownFlag: true,
        };
      } else {
        setSelectedAuthorsOptions(options);
        // filtering author widget data
        const newNodeData = initialClusterData?.nodes?.filter(
          (item) =>
            options?.includes(item?.label) ||
            item?.authors?.some(
              (authItem) =>
                options?.includes(authItem?.author) &&
                (item?.author
                  ? options?.includes(item?.author) &&
                    item?.label === item?.organization
                  : item?.label === item?.organization)
            ) ||
            options?.includes(item?.author)
        );

        // updating links for the nodes
        const newLinkData = initialClusterData?.links?.filter(
          (item) =>
            (options?.includes(item?.source?.label) ||
              options?.includes(item?.target?.label)) &&
            (item?.source?.label === item?.source?.organization ||
              options?.includes(item?.target?.author))
        );

        // Updating organization selected filters
        setSelectedOrgOptions([
          ...new Set(
            initialClusterData?.nodes
              ?.filter(
                (item) =>
                  item?.authors?.some(
                    (authItem) =>
                      options?.includes(authItem?.author) &&
                      (item?.author
                        ? options?.includes(item?.author) &&
                          item?.label === item?.organization
                        : item?.label === item?.organization)
                  ) || options?.includes(item?.author)
              )
              ?.map((mapItem) => mapItem?.organization)
          ),
        ]);

        // Updating author widget data
        widget.networkClusterDataWidgetDetails.data.data.data = {
          nodes: newNodeData,
          links: newLinkData,
          drillDownFlag: true,
        };
      }
    }
    // setSelectedAuthorsOptions((prev) => {
    //   if (selectedOrgOptions?.includes(options)) {
    //     return selectedAuthorsOptions?.filter((item) => item !== options);
    //   } else {
    //     return [...prev, options];
    //   }
    // });
  };

  const clusterHandleClick = async (drillDownData) => {
    console.log({ drillDownData });
    if (drillDownData?.networkPopupFlag) {
      setNetWorkPopup(true);

      const networkResp = await getNetworkUserDetails({
        username: drillDownData?.label,
      });
      console.log({ networkResp });

      setNetWorkDetails(drillDownData);

      // setSubClusterTitle();
    } else {
      const groupData = drillDownData?.group;
      const newChildData = Object?.keys(
        widget.networkClusterDataWidgetDetails?.data?.data?.data
      )?.reduce((acc, item) => {
        const filteredData =
          widget.networkClusterDataWidgetDetails?.data?.data?.data[
            item
          ]?.filter((clusterData) => {
            return (
              (item === 'links' &&
                clusterData?.source?.group === groupData &&
                !clusterData?.source?.id?.includes('category') &&
                clusterData?.target?.group === groupData) ||
              (clusterData?.group === groupData &&
                !clusterData?.id?.includes('category'))
            );
          });

        if (filteredData.length > 0) {
          acc[item] = filteredData;
        }

        return acc;
      }, {});

      // Update state instead of pushing to array
      setClusterFilteredData(newChildData || []);

      // Filter Options for network map
      setInterestFilterNames([
        ...new Set(newChildData?.nodes?.map((item) => item?.organization)),
      ]);
      setSelectedOrgOptions([
        ...new Set(newChildData?.nodes?.map((item) => item?.organization)),
      ]);

      const authorFilterDetails = Object.values(
        newChildData?.nodes.reduce((acc, item) => {
          if (!acc[item.organization]) {
            acc[item.organization] = item;
          }
          return acc;
        }, {})
      );
      setAuthorFilteredNames([
        ...new Set(
          authorFilterDetails?.flatMap((item) =>
            item.authors ? item.authors.map((author) => author.author) : []
          )
        ),
      ]);
      setSelectedAuthorsOptions([
        ...new Set(
          authorFilterDetails?.flatMap((item) =>
            item.authors ? item.authors.map((author) => author.author) : []
          )
        ),
      ]);
      widget.networkClusterDataWidgetDetails.data.data.labels = [
        { label: 'Author', value: 'Author', color: blueColorGradients?.blue50 },
        {
          label: 'Tagged Entity',
          value: 'Tagged Entity',
          color: greenColorGradients?.green50,
        },
        {
          label: 'Industry',
          value: 'Industry',
          color: yellowColorGradients?.yellow40,
        },
      ];
      widget.networkClusterDataWidgetDetails.data.data.data = {
        ...newChildData,
        drillDownFlag: true,
      };
      setSubClusterTitle(newChildData?.nodes[0]?.category);
      widget.networkClusterDataWidgetDetails.data.data.subClusterTitle =
        newChildData?.nodes[0]?.label;
      // setDrillDownCluster({ ...newChildData, drillDownFlag: true });
    }
  };

  return (
    <NetworkMapContainer>
      <SlotWrp>
        {widget &&
          Object?.keys(widget)?.map((networkKey, id) => {
            const SlotComponent =
              (widget?.[networkKey]?.data?.slotType === 'half' &&
                dashboardType) ||
              (widget?.[networkKey]?.data?.isLoading &&
                (widget?.[networkKey]?.data?.customClassName !==
                  'networkMap_sentiment_breakdown' ||
                  widget?.[networkKey]?.data?.customClassName !==
                    'networkMap_topics_discussion'))
                ? HalfSlot
                : FullSlot;
            let savedChartConfig = {};
            const chartData = savedSelectedChart?.find(
              (chart) =>
                chart?.chartId === widget?.[networkKey]?.data?.customClassName
            );

            if (chartData) {
              savedChartConfig = {
                chartName: chartData?.chartName,
                chartType: chartData?.chartType
                  ? chartData?.chartType
                  : widget?.[networkKey]?.data?.graphType,
              };
            } else {
              savedChartConfig = {
                chartName: widget?.[networkKey]?.data?.title,
                chartType: widget?.[networkKey]?.data?.graphType,
              };
            }

            return widget?.[networkKey]?.data?.show ? (
              <SlotComponent
                className="graph-widget"
                key={`widget-${id}`}
                dashboardType={networkKey !== 'topEngagingPosts'}
                networkCluster={
                  networkKey === 'networkClusterDataWidgetDetails' &&
                  SlotComponent === FullSlot
                }
              >
                {/* {networkKey !== 'networkPinData' && ( */}
                <SlotDetails
                  widget={widget?.[networkKey]?.data}
                  loader={widget?.[networkKey]?.data?.isLoading}
                  dashboardType={dashboardType}
                  legend={
                    widget?.[networkKey]?.data?.customClassName ===
                      'networkMap_sentiment_breakdown' ||
                    widget?.[networkKey]?.data?.customClassName ===
                      'networkmap_cluster'
                  }
                  handleOnClick={handleOnClick}
                  resetSelection={resetSelection}
                  actionOption={true}
                  editOption={true}
                  setSelectedComponent={setSelectedComponent}
                  selectedComponent={selectedComponent}
                  onDownloadChartData={onDownloadChartData}
                  downloadFunction={downloadFunction}
                  widgetClassName={widget?.[networkKey]?.data?.customClassName}
                  editChart={savedChartConfig}
                  handleGraphTitleUpdate={handleGraphTitleUpdate}
                  helperText={widget?.[networkKey]?.data?.text}
                  subClusterTitle={subClusterTitle}
                  clusterHandleClick={clusterHandleClick}
                  setSubClusterTitle={setSubClusterTitle}
                  footerMarginTop={
                    networkKey === 'networkClusterDataWidgetDetails' && '-2rem'
                  }
                  authorFilterNames={authorFilteredNames}
                  interestFilterNames={interestFilterNames}
                  handleSelectedInterestDropdown={
                    handleSelectedInterestDropdown
                  }
                  handleSelectedAuthorsDropdown={handleSelectedAuthorsDropdown}
                  selectedOrgOptions={selectedOrgOptions}
                  setSelectedOrgOptions={setSelectedOrgOptions}
                  selectedAuthorsOptions={selectedAuthorsOptions}
                  setSelectedAuthorsOptions={setSelectedAuthorsOptions}
                  clusterFilteredData={clusterFilteredData}
                />
                {/* )} */}
              </SlotComponent>
            ) : (
              <></>
            );
          })}
        {networkPinData?.length > 0 &&
          networkPinData?.map((networkData, id) => (
            <FullSlot key={id} dashboardType={true} padding={false}>
              <NetworkClusterPopup
                popupData={networkData}
                widgetComponent={true}
              />
            </FullSlot>
          ))}
      </SlotWrp>
      <DashboardPopup
        popContent={<NetworkClusterPopup popupData={networkDetails} />}
        padding="0rem"
        toggler={() => {
          setNetWorkPopup(false);
        }}
        open={networkPopup}
        borderRadius="1rem"
        width={'45rem'}
      />
    </NetworkMapContainer>
  );
};

export default NetworkMapDashboard;

NetworkMapDashboard.propTypes = {
  widget: PropTypes.object,
  dashboardType: PropTypes?.string,
  overviewDashboard: PropTypes?.string,
  handleOnClick: PropTypes?.func,
  resetSelection: PropTypes?.bool,
  setSelectedComponent: PropTypes?.func,
  selectedComponent: PropTypes?.string,
  onDownloadChartData: PropTypes?.func,
  downloadFunction: PropTypes?.func,
  savedSelectedChart: PropTypes?.object,
  handleGraphTitleUpdate: PropTypes?.func,
};
