import { congruenceMapData } from '../../hooks/data/advancedDashboardData';
import { addCountPrefix } from '../../constants/utils';

export function message_congruence_mapping(response, graphType) {
  if (graphType === 'congruence') {
    const data = JSON.parse(JSON.stringify(congruenceMapData));

    data.title = 'Message Congruence';

    const totalArticlesMapData = data.summary;
    totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
    data.summary = totalArticlesMapData;

    const updatedData = response?.data?.map((dataPoint) => {
      //   const keywordsFilter = communicationData
      //     ?.filter((x) => x.name === dataPoint.brand)
      //     .map((x) => x.keywords);

      return {
        ...dataPoint,
        // keyword: keywordsFilter[0],
      };
    });

    let dataFromRes = updatedData;
    dataFromRes = dataFromRes?.map((obj) => ({
      ...obj,
      subLabel: '',
      Print:
        obj?.distinct_media_count?.find((x) => x.key === 'Print')
          ?.media_count_percent || 0,
      Online:
        obj?.distinct_media_count?.find((x) => x.key === 'Online')
          ?.media_count_percent || 0,
      Blogs:
        obj?.distinct_media_count?.find((x) => x.key === 'Blogs')
          ?.media_count_percent || 0,
      Forums:
        obj?.distinct_media_count?.find((x) => x.key === 'Forums')
          ?.media_count_percent || 0,
      Reviews:
        obj?.distinct_media_count?.find((x) => x.key === 'Reviews')
          ?.media_count_percent || 0,
      Reddit:
        obj?.distinct_media_count?.find((x) => x.key === 'Reddit')
          ?.media_count_percent || 0,
      Twitter:
        obj?.distinct_media_count?.find((x) => x.key === 'X (Twitter)')
          ?.media_count_percent || 0,
      YouTube:
        obj?.distinct_media_count?.find((x) => x.key === 'YouTube')
          ?.media_count_percent || 0,
      PrintDocCount:
        obj?.distinct_media_count?.find((x) => x.key === 'Print')
          ?.keyword_doc_count || 0,
      OnlineDocCount:
        obj?.distinct_media_count?.find((x) => x.key === 'Online')
          ?.keyword_doc_count || 0,
      BlogsDocCount:
        obj?.distinct_media_count?.find((x) => x.key === 'Blogs')
          ?.keyword_doc_count || 0,
      ForumsDocCount:
        obj?.distinct_media_count?.find((x) => x.key === 'Forums')
          ?.keyword_doc_count || 0,
      ReviewsDocCount:
        obj?.distinct_media_count?.find((x) => x.key === 'Reviews')
          ?.keyword_doc_count || 0,
      RedditDocCount:
        obj?.distinct_media_count?.find((x) => x.key === 'Reddit')
          ?.keyword_doc_count || 0,
      TwitterDocCount:
        obj?.distinct_media_count?.find((x) => x.key === 'X (Twitter)')
          ?.keyword_doc_count || 0,
      YouTubeDocCount:
        obj?.distinct_media_count?.find((x) => x.key === 'YouTube')
          ?.keyword_doc_count || 0,
    }));

    // Sorting the labels based on the key values from the response.
    data.data.labels.sort((a, b) => {
      const valueA = dataFromRes?.[0][a.label];
      const valueB = dataFromRes?.[0][b.label];
      return valueA - valueB;
    });

    data.data.data = dataFromRes || [];
    data.shouldShowGraph = dataFromRes?.every(
      (item, i) =>
        item?.message_congruence === 0 &&
        item?.Online === 0 &&
        item?.Print === 0 &&
        item?.Broadcast === 0
    );

    return data;
  }
}
