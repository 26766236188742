import {
  SOVMapData,
  articleSentimentMapData,
  coverageOverTimeCompetitionMapData,
  journalistMapBreakdown,
  mediaBreakDownMapData,
  reachOverTimeCompMapData,
  sourcesMapData,
  competitionCompaniesMentioned,
  compMediaContributors,
  compPopularWebsites,
} from '../../hooks/data/dashboardData';
import { addCountPrefix } from '../../constants/utils';
import { getFormattedDate } from '../../hooks/useCharts';
import { socialMediaTypes, traditionalMediaTypes } from '../../constants';
import { outletBreakDown, sovColorBox } from '../../graphs/utils/graphConst';
import {
  fillMissingProperties,
  transformData,
  convertEmptyObjectToArray,
  mapSampleDataToData,
} from '../../hooks/useDashboardCharts';
import { theme } from '../../constants/theme';
import { colors } from '../../hooks/data/colors';

const sovProperties = {
  volume: 'doc_count',
  impression: 'impressions',
  traditional_reach: 'reach',
  social_reach: 'reach',
  engagement: 'engagement',
};

export function competition_mapping(
  response,
  graphType,
  filter,
  brand,
  keywords = []
) {
  if (graphType === 'sov') {
    const payloadBrandKeys = response?.brands?.map((brand) =>
      Object.keys(brand).find((key) => key !== 'recent_search_id')
    );

    const mapData = JSON.parse(JSON.stringify(SOVMapData));

    const totalArticlesMapData = mapData.data.summary;

    // const totalCount = response?.total_count
    //   ? response?.total_count
    //   : response && response.data
    //   ? response.data.reduce(
    //       (accumulator, currentValue) =>
    //         accumulator + (currentValue?.doc_count || 0),

    //       0
    //     )
    //   : 0;

    const totalCount = response?.overall_total_count;

    totalArticlesMapData.value = String(addCountPrefix(totalCount));

    mapData.data.summary = totalArticlesMapData;

    // mapData.data.data =
    //   response?.data?.map((x, i) => {
    //     return {
    //       ...x,

    //       value: x?.[sovProperties[filter?.value || 'volume']],

    //       label: x.label.replace(/^"(.*)"$/, '$1'),
    //       filter: filter?.value,
    //       graph: 'sov',
    //     };
    //   }) || []; // old version code

    mapData.originalCount = totalCount;
    mapData.data.data =
      payloadBrandKeys
        ?.map((keyName) => {
          const sovData = response?.brands
            ?.map((brandLabel, i) => {
              const brandKeyData = brandLabel?.[keyName]?.data?.[0];

              if (!brandKeyData) return null;

              return {
                ...brandKeyData,

                value: brandKeyData?.[sovProperties[filter?.value ?? 'volume']],

                label: brandKeyData?.label.replace(/^"(.*)"$/, '$1'),
                filter: 'volume' || filter?.value,
                graph: 'sov',
              };
            })
            ?.filter(Boolean);

          return sovData;
        })
        ?.flat() || [];

    mapData.data.legends = [
      // ...brand.map((k) => k.toLowerCase()),
      // ...comp.map((k) => k.toLowerCase()),
      // ...brand.map((k) => k),
      // ...comp.map((k) => k),
      ...payloadBrandKeys,
    ]?.map((x) => {
      return {
        label: x,
        // value: String(x).toLowerCase(),
        value: String(x).toLowerCase(),
      };
    });

    // Create a map to store the index of each legend

    const legendIndexMap = {};

    mapData?.data?.legends?.forEach((legend, index) => {
      legendIndexMap[legend?.label] = index;
    });

    // Sort the list data using the custom comparison

    mapData?.data?.data?.sort(
      (a, b) =>
        (legendIndexMap[a?.label] || 0) - (legendIndexMap[b?.label] || 0)
    );

    mapData.data.data = mapData.data.data.map((item, i) => {
      return {
        ...item,
        color: sovColorBox[i],
      };
    });

    // mapData.shouldShowGraph = response?.total_count;
    mapData.shouldShowGraph =
      response?.data?.some((item) => item?.doc_count !== 0) ||
      (response?.overall_total_count && response?.overall_total_count > 0);
    if (response) {
      mapData.originalData = response;
    }
    return mapData;
  } else if (graphType === 'article_sentiment') {
    const payloadBrandKeys = response?.brands?.map((brand) =>
      Object.keys(brand).find((key) => key !== 'recent_search_id')
    );
    const mapData = JSON.parse(JSON.stringify(articleSentimentMapData));
    // const totalKeywords = [...brand, ...keywords];
    const totalArticlesMapData = mapData.data.summary;
    // const totalCount = response?.total_count ? response?.total_count : 0;
    const totalCount = response?.overall_total_count
      ? response?.overall_total_count
      : 0;
    totalArticlesMapData.value = String(addCountPrefix(totalCount));
    mapData.data.summary = totalArticlesMapData;
    mapData.originalCount = totalCount;
    const labels = mapData?.data?.labels;
    mapData.data.data = fillMissingProperties(
      response,
      labels,
      payloadBrandKeys
    );
    // mapData?.data?.data?.sort(
    //   (a, b) =>
    //     (totalKeywords?.indexOf(a?.label) || 0) -
    //     (totalKeywords?.indexOf(b?.label) || 0)
    // );

    mapData.data.data = mapData.data.data.map((item, i) => {
      return {
        ...item,
        color: sovColorBox[i],
      };
    });
    const noDataCheck =
      response?.overall_total_count && response?.overall_total_count > 0;
    // response.data.some((item) => {
    //   return Object.keys(item).some(
    //     (key) => key !== 'label' && item[key] !== '0'
    //   );
    // }) ||
    // mapData.shouldShowGraph = response?.total_count;
    mapData.shouldShowGraph = noDataCheck;
    if (response) {
      mapData.originalData = response;
    }
    return mapData;
  } else if (graphType === 'competitive_coverage_over_time') {
    const { totalCounts, dateWiseData } = transformData(response?.brands);

    const labels = Object.keys(totalCounts)?.map((key, index) => ({
      label: key,
      value: key?.split(' ').join('_'),
      // color: peopleReachOverTimeColors[index],
    }));

    const mapData = JSON.parse(
      JSON.stringify(coverageOverTimeCompetitionMapData)
    );

    // const selectedMedia = getSelectedTypes(payload);
    // if (selectedMedia === 'all') {
    mapData.title = 'Results Over Time - Traditional';
    // }

    mapData.mediaTypes = [...traditionalMediaTypes];

    const totalArticlesMapData = mapData.data.summary;
    // const totalCount = response?.total_count ? response?.total_count : 0;
    const totalCount = response?.overall_total_count
      ? response?.overall_total_count
      : 0;
    totalArticlesMapData.value = String(addCountPrefix(totalCount));
    mapData.data.summary = totalArticlesMapData;
    // mapData.data.data =
    //   convertValuesToInt(response?.data, ['label', 'date'])?.map((x) => {
    //     const formattedDate = getFormattedDate(String(x?.date ?? x.label));
    //     const refinedXData = removeQuotesFromObjectKeys(x);
    //     return {
    //       ...refinedXData,
    //       ...x,
    //       label: formattedDate,
    //       date: x?.date ?? x.label,
    //     };
    //   }) || []; // The following code is for the chip keywords flow.

    mapData.data.data = dateWiseData?.map((x) => {
      const formattedDate = getFormattedDate(x?.date);
      function arrayToObject(arr) {
        return arr.reduce((acc, { label, value }) => {
          const formattedLabel = label.split(' ').join('_'); // Replace spaces with underscores
          acc[formattedLabel] = value;
          return acc;
        }, {});
      }
      return {
        ...x,
        label: formattedDate,
        date: x?.date,
        ...arrayToObject(x?.values),
      };
    });
    mapData.originalCount = totalCount;

    mapData.data.labels = labels;
    // mapData.data.labels = [
    //   // ...comp.map((k) => k.toLowerCase()),
    //   // ...brand.map((k) => k.toLowerCase()),
    //   // ...brand.map((k) => k),
    //   // ...comp.map((k) => k),
    //   payloadBrandKeys,
    // ]?.map((x) => {
    //   return {
    //     label: x,
    //     // value: String(x).toLowerCase(),
    //     value: String(x),
    //   };
    // });
    const noDataCheck =
      response?.overall_total_count && response?.overall_total_count > 0;
    // const noDataCheck = response.data.some((item) => {
    //   return Object.keys(item).some(
    //     (key) => key !== 'label' && item[key] !== '0'
    //   );
    // });
    // mapData.shouldShowGraph = response?.total_count;
    mapData.shouldShowGraph = noDataCheck;
    return mapData;
  } else if (graphType === 'competitive_coverage_over_time_social') {
    const { totalCounts, dateWiseData } = transformData(response?.brands);

    const labels = Object.keys(totalCounts)?.map((key, index) => ({
      label: key,
      value: key?.split(' ').join('_'),
      // color: peopleReachOverTimeColors[index],
    }));

    const mapData = JSON.parse(
      JSON.stringify(coverageOverTimeCompetitionMapData)
    );

    // const selectedMedia = getSelectedTypes(payload);
    // if (selectedMedia === 'all') {
    mapData.title = 'Results Over Time - Social';
    // }

    mapData.mediaTypes = [...socialMediaTypes];

    const totalArticlesMapData = mapData.data.summary;
    // const totalCount = response?.total_count ? response?.total_count : 0;
    const totalCount = response?.overall_total_count
      ? response?.overall_total_count
      : 0;
    totalArticlesMapData.value = String(addCountPrefix(totalCount));
    mapData.data.summary = totalArticlesMapData;
    // mapData.data.data =
    //   convertValuesToInt(response?.data, ['label', 'date'])?.map((x) => {
    //     const formattedDate = getFormattedDate(String(x?.date ?? x.label));
    //     const refinedXData = removeQuotesFromObjectKeys(x);
    //     return {
    //       ...refinedXData,
    //       ...x,
    //       label: formattedDate,
    //       date: x?.date ?? x.label,
    //     };
    //   }) || []; // The following code is for the chip keywords flow.

    mapData.data.data = dateWiseData?.map((x) => {
      const formattedDate = getFormattedDate(x?.date);
      function arrayToObject(arr) {
        return arr.reduce((acc, { label, value }) => {
          const formattedLabel = label.split(' ').join('_'); // Replace spaces with underscores
          acc[formattedLabel] = value;
          return acc;
        }, {});
      }
      return {
        ...x,
        label: formattedDate,
        date: x?.date,
        ...arrayToObject(x?.values),
      };
    });
    mapData.originalCount = totalCount;

    mapData.data.labels = labels;
    // mapData.data.labels = [
    //   // ...comp.map((k) => k.toLowerCase()),
    //   // ...brand.map((k) => k.toLowerCase()),
    //   // ...brand.map((k) => k),
    //   // ...comp.map((k) => k),
    // ]?.map((x) => {
    //   return {
    //     label: x,
    //     // value: String(x).toLowerCase(),
    //     value: String(x),
    //   };
    // });
    const noDataCheck =
      response?.overall_total_count && response?.overall_total_count > 0;
    // const noDataCheck = response.data.some((item) => {
    //   return Object.keys(item).some(
    //     (key) => key !== 'label' && item[key] !== '0'
    //   );
    // });
    // mapData.shouldShowGraph = response?.total_count;
    mapData.shouldShowGraph = noDataCheck;
    return mapData;
  } else if (graphType === 'competitive_reach_over_time') {
    const { totalCounts, dateWiseData } = transformData(response?.brands);
    const labels = Object.keys(totalCounts)?.map((key, index) => ({
      label: key,
      value: key?.split(' ').join('_'),
      // color: peopleReachOverTimeColors[index],
    }));

    const mapData = JSON.parse(JSON.stringify(reachOverTimeCompMapData));

    // const selectedMedia = getSelectedTypes(payload);
    // if (selectedMedia === 'all') {
    mapData.title = 'Reach Over Time - Traditional';
    // }

    mapData.mediaTypes = [...traditionalMediaTypes];

    // setting total count
    const totalArticlesMapData = mapData.data.summary;
    // const totalCount = response?.total_count ? response?.total_count : 0;
    const totalCount = response?.overall_total_count
      ? response?.overall_total_count
      : 0;
    totalArticlesMapData.value = String(addCountPrefix(totalCount));
    mapData.data.summary = totalArticlesMapData;
    mapData.originalCount = totalCount;
    // setting data
    // mapData.data.data =
    //   response?.data?.map((x) => {
    //     const formattedDate = getFormattedDate(x?.date ?? x.label);
    //     const refinedXData = removeQuotesFromObjectKeys(x);
    //     return {
    //       ...refinedXData,
    //       ...x,
    //       label: formattedDate,
    //       date: x?.date ?? x.label,
    //     };
    //   }) || []; The following code is for the chip keywords flow.

    mapData.data.data = dateWiseData?.map((x) => {
      const formattedDate = getFormattedDate(x?.date);
      function arrayToObject(arr) {
        return arr.reduce((acc, { label, value }) => {
          const formattedLabel = label.split(' ').join('_'); // Replace spaces with underscores
          acc[formattedLabel] = value;
          return acc;
        }, {});
      }
      return {
        ...x,
        label: formattedDate,
        date: x?.date,
        ...arrayToObject(x?.values),
      };
    });
    mapData.data.labels = labels;

    // setting labels for graph
    // mapData.data.labels = [
    //   // ...comp.map((k) => k.toLowerCase()),
    //   // ...brand.map((k) => k.toLowerCase()),
    //   // ...brand.map((k) => k),
    //   // ...comp.map((k) => k),
    // ]?.map((x) => {
    //   return {
    //     label: x,
    //     // value: String(x).toLowerCase(),
    //     value: String(x),
    //   };
    // });
    const noDataCheck =
      response?.overall_total_count && response?.overall_total_count > 0;
    // const noDataCheck = response.data.some((item) => {
    //   return Object.keys(item).some(
    //     (key) => key !== 'label' && item[key] !== '0.0' && item[key] !== '0'
    //   );
    // });
    // mapData.shouldShowGraph = response?.total_count;
    mapData.shouldShowGraph = noDataCheck;
    return mapData;
  } else if (graphType === 'competitive_reach_over_time_social') {
    const { totalCounts, dateWiseData } = transformData(response?.brands);
    const labels = Object.keys(totalCounts)?.map((key, index) => ({
      label: key,
      value: key?.split(' ').join('_'),
      // color: peopleReachOverTimeColors[index],
    }));

    const mapData = JSON.parse(JSON.stringify(reachOverTimeCompMapData));

    // const selectedMedia = getSelectedTypes(payload);
    // if (selectedMedia === 'all') {
    mapData.title = 'Reach Over Time - Social';
    // }

    mapData.mediaTypes = [...socialMediaTypes];

    // setting total count
    const totalArticlesMapData = mapData.data.summary;
    // const totalCount = response?.total_count ? response?.total_count : 0;
    const totalCount = response?.overall_total_count
      ? response?.overall_total_count
      : 0;
    totalArticlesMapData.value = String(addCountPrefix(totalCount));
    mapData.data.summary = totalArticlesMapData;
    mapData.originalCount = totalCount;
    // setting data
    // mapData.data.data =
    //   response?.data?.map((x) => {
    //     const formattedDate = getFormattedDate(x?.date ?? x.label);
    //     const refinedXData = removeQuotesFromObjectKeys(x);
    //     return {
    //       ...refinedXData,
    //       ...x,
    //       label: formattedDate,
    //       date: x?.date ?? x.label,
    //     };
    // }) || []; // This following code is for the chip keywords flow.
    // setting labels for graph

    mapData.data.data = dateWiseData?.map((x) => {
      const formattedDate = getFormattedDate(x?.date);
      function arrayToObject(arr) {
        return arr.reduce((acc, { label, value }) => {
          const formattedLabel = label.split(' ').join('_'); // Replace spaces with underscores
          acc[formattedLabel] = value;
          return acc;
        }, {});
      }
      return {
        ...x,
        label: formattedDate,
        date: x?.date,
        ...arrayToObject(x?.values),
      };
    });
    mapData.data.labels = labels;

    // mapData.data.labels = [
    //   // ...comp.map((k) => k.toLowerCase()),
    //   // ...brand.map((k) => k.toLowerCase()),
    //   ...brand.map((k) => k),
    //   ...comp.map((k) => k),
    // ]?.map((x) => {
    //   return {
    //     label: x,
    //     // value: String(x).toLowerCase(),
    //     value: String(x),
    //   };
    // });
    const noDataCheck =
      response?.overall_total_count && response?.overall_total_count > 0;
    // const noDataCheck = response.data.some((item) => {
    //   return Object.keys(item).some(
    //     (key) => key !== 'label' && item[key] !== '0.0' && item[key] !== '0'
    //   );
    // });
    // mapData.shouldShowGraph = response?.total_count;
    mapData.shouldShowGraph = noDataCheck;
    return mapData;
  } else if (graphType === 'coverage_by_journalist') {
    const payloadBrandKeys = response?.brands?.map((brand) =>
      Object.keys(brand).find((key) => key !== 'recent_search_id')
    );

    const mapData = JSON.parse(JSON.stringify(journalistMapBreakdown));

    // const totalArticlesMapData = mapData.summary;
    // totalArticlesMapData.value = String(addCountPrefix(response?.total_count));
    // mapData.summary = totalArticlesMapData;

    const totalArticlesMapData = mapData.data.summary;
    // const totalCount = response?.total_count ? response?.total_count : 0;
    const totalCount = response?.overall_total_count
      ? response?.overall_total_count
      : 0;
    totalArticlesMapData.value = String(addCountPrefix(totalCount));
    mapData.data.summary = totalArticlesMapData;
    mapData.originalCount = totalCount;
    const updatedData = [];

    const comparisonData = {};

    response?.brands?.map((brandData) => {
      const brandKey =
        Object?.keys(brandData)?.[0] !== 'recent_search_id'
          ? Object?.keys(brandData)?.[0]
          : Object?.keys(brandData)?.[1];

      comparisonData[brandKey] = brandData?.[brandKey]?.data?.[brandKey];
    });

    // Object.keys(response?.data)?.forEach((keyword, index) => {
    Object.keys(comparisonData)?.forEach((keyword, index) => {
      // const regBrandKeyword = brand[0]?.replace(/["]+/g, '');
      // const regCompKeywords = keywords?.map((comptKey) =>
      //   comptKey?.replace(/["]+/g, '')
      // );

      if (keyword && index === 0) {
        const combinedObject = {};

        const sortedData = comparisonData[keyword]
          ?.slice(0, 10)
          ?.sort((a, b) => b.count - a.count)
          ?.filter((item) => item?.count > 0);

        if (sortedData.length === 0) {
          combinedObject.noData = 0;
        } else {
          for (const item of sortedData) {
            const authorName = item.label;
            const count = item.count;
            combinedObject[
              `${authorName?.replace(
                /[\s:;,'"\\`!@#$%^&*()_+=\[\]{}|<>?/`~]/g,
                ''
              )}_author_id`
            ] = item.author_id;
            combinedObject[authorName] = count;
          }
        }
        updatedData.push({
          label: keyword,
          color: theme?.light?.graphColors?.purple60,
          lightColor: theme?.light?.graphColors?.purple40,
          ...combinedObject,

          // key: data?.
        });
      } else if (keyword && index === 1) {
        const combinedObject = {};

        const sortedData = comparisonData[keyword]
          ?.slice(0, 10)
          ?.sort((a, b) => b.count - a.count);
        if (sortedData.length === 0) {
          combinedObject.noData = 0;
        } else {
          for (const item of sortedData) {
            const authorName = item.label;
            const count = item.count;
            combinedObject[
              `${authorName?.replace(
                /[\s:;,'"\\`!@#$%^&*()_+=\[\]{}|<>?/`~]/g,
                ''
              )}_author_id`
            ] = item.author_id;
            combinedObject[authorName] = count;
          }
        }
        updatedData.push({
          label: keyword,
          color: theme?.light?.graphColors?.magenta60,
          lightColor: theme?.light?.graphColors?.magenta40,
          ...combinedObject,

          // key: data?.
        });
      } else if (
        // JSON.stringify(keyword)?.toLowerCase() ===
        // JSON.stringify(regCompKeywords[1])?.toLowerCase()
        keyword &&
        index === 2
      ) {
        const combinedObject = {};

        const sortedData = comparisonData[keyword]
          ?.slice(0, 10)
          ?.sort((a, b) => b.count - a.count)
          ?.filter((item) => item?.count > 0);
        if (sortedData.length === 0) {
          combinedObject.noData = 0;
        } else {
          for (const item of sortedData) {
            const authorName = item.label;
            const count = item.count;
            combinedObject[
              `${authorName?.replace(
                /[\s:;,'"\\`!@#$%^&*()_+=\[\]{}|<>?/`~]/g,
                ''
              )}_author_id`
            ] = item.author_id;
            combinedObject[authorName] = count;
          }
        }
        updatedData.push({
          label: keyword,
          color: theme?.light?.graphColors?.cyan50,
          lightColor: theme?.light?.graphColors?.cyan30,
          ...combinedObject,

          // key: data?.
        });
      } else if (
        // JSON.stringify(keyword)?.toLowerCase() ===
        // JSON.stringify(regCompKeywords[2])?.toLowerCase()
        keyword &&
        index === 3
      ) {
        const combinedObject = {};

        const sortedData = comparisonData[keyword]
          ?.slice(0, 10)
          ?.sort((a, b) => b.count - a.count);
        if (sortedData.length === 0) {
          combinedObject.noData = 0;
        } else {
          for (const item of sortedData) {
            const authorName = item.label;
            const count = item.count;
            combinedObject[
              `${authorName?.replace(
                /[\s:;,'"\\`!@#$%^&*()_+=\[\]{}|<>?/`~]/g,
                ''
              )}_author_id`
            ] = item.author_id;
            combinedObject[authorName] = count;
          }
        }
        updatedData.push({
          label: keyword,
          color: theme?.light?.graphColors?.orange50,
          lightColor: theme?.light?.graphColors?.orange30,
          ...combinedObject,

          // key: data?.
        });
      } else if (
        // JSON.stringify(keyword)?.toLowerCase() ===
        // JSON.stringify(regCompKeywords[3])?.toLowerCase()
        keyword &&
        index === 4
      ) {
        const combinedObject = {};

        const sortedData = comparisonData[keyword]
          ?.slice(0, 10)
          ?.sort((a, b) => b.count - a.count);
        if (sortedData.length === 0) {
          combinedObject.noData = 0;
        } else {
          for (const item of sortedData) {
            const authorName = item.label;
            const count = item.count;
            combinedObject[
              `${authorName?.replace(
                /[\s:;,'"\\`!@#$%^&*()_+=\[\]{}|<>?/`~]/g,
                ''
              )}_author_id`
            ] = item.author_id;
            combinedObject[authorName] = count;
          }
        }
        updatedData.push({
          label: keyword,
          color: theme?.light?.graphColors?.teal50,
          lightColor: theme?.light?.graphColors?.teal30,
          ...combinedObject,

          // key: data?.
        });
      } else if (
        // JSON.stringify(keyword)?.toLowerCase() ===
        // JSON.stringify(regCompKeywords[4])?.toLowerCase()
        keyword &&
        index === 5
      ) {
        const combinedObject = {};

        const sortedData = comparisonData[keyword]
          ?.slice(0, 10)
          ?.sort((a, b) => b.count - a.count);
        if (sortedData.length === 0) {
          combinedObject.noData = 0;
        } else {
          for (const item of sortedData) {
            const authorName = item.label;
            const count = item.count;
            combinedObject[
              `${authorName?.replace(
                /[\s:;,'"\\`!@#$%^&*()_+=\[\]{}|<>?/`~]/g,
                ''
              )}_author_id`
            ] = item.author_id;
            combinedObject[authorName] = count;
          }
        }
        updatedData.push({
          label: keyword,
          color: theme?.light?.graphColors?.yellow40,
          lightColor: theme?.light?.graphColors?.yellow30,
          ...combinedObject,

          // key: data?.
        });
      }
    });
    mapData.data.data = updatedData;

    const flatMappedData = updatedData.map((dataItem) => {
      return Object.keys(dataItem)
        .filter(
          (key) =>
            key !== 'label' &&
            key !== 'color' &&
            key !== 'lightColor' &&
            key !== 'author_id'
        )
        .map((key) => {
          return {
            label: key,
            value: String(key),
          };
        });
    });

    // const totalKeywords = [...brand, ...comp];
    // Create a Set to track unique entries
    const uniqueEntriesSet = new Set();
    const uniqueEntries = flatMappedData.filter((entry) => {
      const entryKey = `${entry.label}-${entry.value}`;
      if (uniqueEntriesSet.has(entryKey)) {
        return false;
      }
      uniqueEntriesSet.add(entryKey);
      return true;
    });

    mapData.data.legends = [
      // ...brand.map((k) => k.toLowerCase()),
      // ...comp.map((k) => k.toLowerCase()),
      // ...brand.map((k) => k),
      // ...comp.map((k) => k),
      ...payloadBrandKeys,
    ]?.map((x) => {
      return {
        label: x,
        // value: String(x).toLowerCase(),
        value: String(x).toLowerCase(),
      };
    });

    mapData.data.labels = uniqueEntries;
    mapData?.data?.data?.sort(
      (a, b) =>
        (payloadBrandKeys?.indexOf(a?.label) || 0) -
        (payloadBrandKeys?.indexOf(b?.label) || 0)
    );
    // mapData.shouldShowGraph =
    //   response?.total_count ||
    //   response?.data[keywords[0]].some((item) => item.value?.length > 0);
    const noDataCheck =
      response?.overall_total_count && response?.overall_total_count > 0;
    // const noDataCheck = Object.keys(response.data).some(
    //   (key) => response?.data[key]?.length > 0
    // );
    mapData.shouldShowGraph = noDataCheck;

    return mapData;
  } else if (graphType === 'coverage_by_source') {
    const payloadBrandKeys = response?.brands?.map((brand) =>
      Object.keys(brand).find((key) => key !== 'recent_search_id')
    );

    const mapData = JSON.parse(JSON.stringify(sourcesMapData));
    // setting total count
    const totalArticlesMapData = mapData.data.summary;
    // const totalCount = response?.total_count ? response?.total_count : 0;
    const totalCount = response?.overall_total_count
      ? response?.overall_total_count
      : 0;
    totalArticlesMapData.value = String(addCountPrefix(totalCount));
    mapData.data.summary = totalArticlesMapData;
    // const categories = Object.keys(response?.data)?.map((key) => key);
    mapData.originalCount = totalCount;
    response.data = convertEmptyObjectToArray(response?.data, [
      brand,
      ...keywords,
    ]);
    const updatedData = [];

    const comparisonData = {};

    response?.brands?.map((brandData) => {
      const brandKey =
        Object?.keys(brandData)?.[0] !== 'recent_search_id'
          ? Object?.keys(brandData)?.[0]
          : Object?.keys(brandData)?.[1];

      comparisonData[brandKey] = brandData?.[brandKey]?.data?.[brandKey];
    });

    Object.keys(comparisonData)?.forEach((keyword, index) => {
      if (
        // JSON.stringify(keyword).toLowerCase() ===
        // JSON.stringify(brand?.toString()).toLowerCase()
        keyword &&
        index === 0
      ) {
        const combinedObject = {};
        const sortedData = comparisonData[keyword]
          ?.slice(0, 10)
          ?.sort((a, b) => b.count - a.count);
        if (sortedData.length === 0) {
          combinedObject.noData = 0;
        } else {
          for (const item of sortedData) {
            const source = item.source;
            const count = item.count;
            combinedObject[source] = count;
          }
        }
        updatedData.push({
          label: keyword,
          color: theme?.light?.graphColors?.purple60,
          lightColor: theme?.light?.graphColors?.purple40,
          ...combinedObject,

          // key: data?.
        });
      } else if (
        // JSON.stringify(keyword).toLowerCase() ===
        // JSON.stringify(keywords[0]).toLowerCase()
        keyword &&
        index === 1
      ) {
        const combinedObject = {};

        const sortedData = comparisonData[keyword]
          ?.slice(0, 10)
          ?.sort((a, b) => b.count - a.count);
        if (sortedData.length === 0) {
          combinedObject.noData = 0;
        } else {
          for (const item of sortedData) {
            const source = item.source;
            const count = item.count;

            combinedObject[source] = count;
          }
        }
        updatedData.push({
          label: keyword,
          color: theme?.light?.graphColors?.magenta60,
          lightColor: theme?.light?.graphColors?.magenta40,
          ...combinedObject,

          // key: data?.
        });
      } else if (
        // JSON.stringify(keyword).toLowerCase() ===
        // JSON.stringify(keywords[1]).toLowerCase()
        keyword &&
        index === 2
      ) {
        const combinedObject = {};

        const sortedData = comparisonData[keyword]
          ?.slice(0, 10)
          ?.sort((a, b) => b.count - a.count);
        if (sortedData.length === 0) {
          combinedObject.noData = 0;
        } else {
          for (const item of sortedData) {
            const source = item.source;
            const count = item.count;

            combinedObject[source] = count;
          }
        }
        updatedData.push({
          label: keyword,
          color: theme?.light?.graphColors?.cyan50,
          lightColor: theme?.light?.graphColors?.cyan30,
          ...combinedObject,

          // key: data?.
        });
      } else if (
        // JSON.stringify(keyword).toLowerCase() ===
        // JSON.stringify(keywords[2]).toLowerCase()
        keyword &&
        index === 3
      ) {
        const combinedObject = {};

        const sortedData = comparisonData[keyword]
          ?.slice(0, 10)
          ?.sort((a, b) => b.count - a.count);
        if (sortedData.length === 0) {
          combinedObject.noData = 0;
        } else {
          for (const item of sortedData) {
            const source = item.source;
            const count = item.count;

            combinedObject[source] = count;
          }
        }
        updatedData.push({
          label: keyword,
          color: theme?.light?.graphColors?.orange50,
          lightColor: theme?.light?.graphColors?.orange30,
          ...combinedObject,

          // key: data?.
        });
      } else if (
        // JSON.stringify(keyword).toLowerCase() ===
        // JSON.stringify(keywords[3]).toLowerCase()
        keyword &&
        index === 4
      ) {
        const combinedObject = {};

        const sortedData = comparisonData[keyword]
          ?.slice(0, 10)
          ?.sort((a, b) => b.count - a.count);
        if (sortedData.length === 0) {
          combinedObject.noData = 0;
        } else {
          for (const item of sortedData) {
            const source = item.source;
            const count = item.count;

            combinedObject[source] = count;
          }
        }
        updatedData.push({
          label: keyword,
          color: theme?.light?.graphColors?.teal50,
          lightColor: theme?.light?.graphColors?.teal30,
          ...combinedObject,

          // key: data?.
        });
      } else if (
        // JSON.stringify(keyword).toLowerCase() ===
        // JSON.stringify(keywords[4]).toLowerCase()
        keyword &&
        index === 5
      ) {
        const combinedObject = {};

        const sortedData = comparisonData[keyword]
          ?.slice(0, 10)
          ?.sort((a, b) => b.count - a.count);
        if (sortedData.length === 0) {
          combinedObject.noData = 0;
        } else {
          for (const item of sortedData) {
            const source = item.source;
            const count = item.count;

            combinedObject[source] = count;
          }
        }
        updatedData.push({
          label: keyword,
          color: theme?.light?.graphColors?.yellow40,
          lightColor: theme?.light?.graphColors?.yellow30,
          ...combinedObject,

          // key: data?.
        });
      }
    });
    mapData.data.data = updatedData;
    // setting labels for graph
    // mapData.data.labels = [
    //   ...comp.map((k) => k.toLowerCase()),
    //   ...brand.map((k) => k.toLowerCase()),
    // ]?.map((x) => {
    //   return {
    //     label: x,
    //     value: String(x).toLowerCase(),
    //   };
    // });
    // const totalKeywords = [...brand, ...comp];

    mapData.data.legends = [
      // ...brand.map((k) => k.toLowerCase()),
      // ...comp.map((k) => k.toLowerCase()),
      // ...brand.map((k) => k),
      // ...comp.map((k) => k),
      ...payloadBrandKeys,
    ]?.map((x) => {
      return {
        label: x,
        // value: String(x).toLowerCase(),
        value: String(x).toLowerCase(),
      };
    });

    const flatMappedData = updatedData.flatMap((dataItem) => {
      return Object.keys(dataItem)
        .filter(
          (key) => key !== 'label' && key !== 'color' && key !== 'lightColor'
        )
        .map((key) => ({
          label: key,
          value: String(key),
        }));
    });
    // Create a Set to track unique entries
    const uniqueEntriesSet = new Set();
    const uniqueEntries = flatMappedData.filter((entry) => {
      const entryKey = `${entry.label}-${entry.value}`;
      if (uniqueEntriesSet.has(entryKey)) {
        return false;
      }
      uniqueEntriesSet.add(entryKey);
      return true;
    });
    mapData?.data?.data?.sort(
      (a, b) =>
        // (totalKeywords?.indexOf(a?.label) || 0) -
        // (totalKeywords?.indexOf(b?.label) || 0)
        (payloadBrandKeys?.indexOf(a?.label) || 0) -
        (payloadBrandKeys?.indexOf(b?.label) || 0)
    );
    mapData.data.labels = uniqueEntries;
    // mapData.shouldShowGraph =
    //   response?.total_count ||
    //   response?.data[keywords[0]].some((item) => item.value?.length > 0);
    const noDataCheck =
      response?.overall_total_count && response?.overall_total_count > 0;
    // const noDataCheck = Object.keys(response.data).some(
    //   (key) => response?.data[key]?.length > 0
    // );
    mapData.shouldShowGraph = noDataCheck;

    return mapData;
  } else if (graphType === 'breakdown_by_media_type') {
    const payloadBrandKeys = response?.brands?.map((brand) =>
      Object.keys(brand).find((key) => key !== 'recent_search_id')
    );

    const mapData = JSON.parse(JSON.stringify(mediaBreakDownMapData));
    // setting total count
    const totalArticlesMapData = mapData.data.summary;
    const totalCount = response?.overall_total_count
      ? response?.overall_total_count
      : 0;
    totalArticlesMapData.value = String(addCountPrefix(totalCount));
    mapData.data.summary = totalArticlesMapData;
    mapData.originalCount = totalCount;
    const categories = [
      'Online',
      'Print',
      'Forums',
      'Reviews',
      'Blogs',
      // 'Broadcast',
      'X (Twitter)',
      'Reddit',
      'YouTube',
    ];

    const comparisonData = [];
    response?.brands?.map((brandData) => {
      const brandKey =
        Object?.keys(brandData)[0] !== 'recent_search_d'
          ? Object?.keys(brandData)[0]
          : Object?.keys(brandData)[1];
      comparisonData.push(brandData?.[brandKey]?.data?.[0]);
    });

    // setting data
    mapData.data.data = mapSampleDataToData(comparisonData, categories);
    // setting labels for graph
    mapData.data.labels = [
      // ...comp.map((k) => k.toLowerCase()),
      // ...brand.map((k) => k.toLowerCase()),
      // ...brand.map((k) => k),
      // ...comp.map((k) => k),
      ...payloadBrandKeys,
    ]?.map((x) => {
      const valueStr = x.replace(/["]+/g, '');

      return {
        label: x,
        // value: String(x).toLowerCase(),
        value: String(valueStr),
      };
    });

    mapData.shouldShowGraph =
      response?.overall_total_count && response?.overall_total_count > 0;
    // mapData.shouldShowGraph = response?.data.some((item, i) => {
    //   return item?.value?.length > 0 && !Array.isArray(item?.value[i]?.value);
    // });
    return mapData;
  } else if (graphType === 'competition_companies_mentioned') {
    const companies = JSON.parse(JSON.stringify(competitionCompaniesMentioned));

    const comparisonData = response?.brands
      ?.map((brandData) => {
        const brandKey =
          Object?.keys(brandData)[0] !== 'recent_search_d'
            ? Object?.keys(brandData)[0]
            : Object?.keys(brandData)[1];
        return brandData?.[brandKey]?.data;
      })
      ?.flat();

    companies.data.data = comparisonData.map((item, i) => {
      const colorIndex = i % colors?.length;
      return {
        ...colors[colorIndex],
        label: item.label?.toUpperCase(),
        value: item.article_count,
        labelColor: colors[colorIndex]?.color,
      };
    });
    companies.data.summary.value = String(
      addCountPrefix(response?.overall_total_count)
    );
    companies.originalCount = response?.overall_total_count;
    companies.shouldShowGraph = comparisonData?.length > 0;
    return companies;
  } else if (graphType === 'competition_media_contributors') {
    const data = JSON.parse(JSON.stringify(compMediaContributors));
    const payloadBrandKeys = response?.brands?.map((brand) =>
      Object.keys(brand).find((key) => key !== 'recent_search_id')
    );
    // const queryKeywords = [...brand, ...comp];
    const dynamicLabels = [
      ...new Set(payloadBrandKeys?.map((label) => label)),
      // ...new Set(queryKeywords?.map((label) => label?.toLowerCase())),
    ];

    // Generate labels with colors
    const labels = dynamicLabels.map((label, index) => {
      return {
        label,
        value: label,
        color: outletBreakDown[index % outletBreakDown.length],
      };
    });

    const comparisonData = response?.brands
      ?.map((brandData) => {
        const brandKey =
          Object?.keys(brandData)[0] !== 'recent_search_d'
            ? Object?.keys(brandData)[0]
            : Object?.keys(brandData)[1];
        return brandData?.[brandKey]?.data;
      })
      ?.flat();

    // Map the data dynamically for each author
    data.data.data = comparisonData?.slice(0, 10)?.map((author) => {
      // Initialize keyword values to 0 for each keyword in dynamicLabels
      const keywordCounts = dynamicLabels.reduce((acc, keyword) => {
        acc[keyword] = 0;
        return acc;
      }, {});

      // Update counts based on the author's keywords_breakdown
      author.keywords_breakdown.forEach((keywordData) => {
        keywordCounts[keywordData.keyword] = keywordData.articles_count;
      });

      return {
        author_id: author.author_id,
        label: author.name,
        total_articles: author.total_articles,
        keyword_breakdown: author.keywords_breakdown,
        ...keywordCounts,
      };
    });

    // generated dynamic labels
    data.data.labels = labels;

    // Update summary
    data.data.summary.value = String(
      addCountPrefix(response?.overall_total_count)
    );
    data.originalCount = response?.overall_total_count;

    data.shouldShowGraph =
      response?.overall_total_count && response?.overall_total_count > 0;
    // data.shouldShowGraph = response?.data?.length > 0;
    return data;
  } else if (graphType === 'competition_coverage_by_websites') {
    const data = JSON.parse(JSON.stringify(compPopularWebsites));

    // const queryKeywords = [...brand, ...comp];
    const payloadBrandKeys = response?.brands?.map((brand) =>
      Object.keys(brand).find((key) => key !== 'recent_search_id')
    );

    const dynamicLabels = [
      ...new Set(payloadBrandKeys.map((label) => label)),
      // ...new Set(queryKeywords.map((label) => label.toLowerCase())),
    ];

    // Generate labels with colors
    const labels = dynamicLabels.map((label, index) => {
      return {
        label,
        value: label,
        color: outletBreakDown[index % outletBreakDown.length],
      };
    });

    const comparisonData = response?.brands
      ?.map((brandData) => {
        const brandKey =
          Object?.keys(brandData)[0] !== 'recent_search_d'
            ? Object?.keys(brandData)[0]
            : Object?.keys(brandData)[1];
        return brandData?.[brandKey]?.data;
      })
      ?.flat();

    // Map the data dynamically for each source
    data.data.data = comparisonData?.slice(0, 10).map((author) => {
      // Initialize keyword values to 0 for each keyword in dynamicLabels
      const keywordCounts = dynamicLabels.reduce((acc, keyword) => {
        acc[keyword] = 0;
        return acc;
      }, {});

      // Update counts based on the author's keywords_breakdown
      author.keywords_breakdown.forEach((keywordData) => {
        keywordCounts[keywordData.keyword] = keywordData.articles_count;
      });

      return {
        source: author.source,
        label: author.source,
        total_articles: author.count,
        keyword_breakdown: author.keywords_breakdown,
        ...keywordCounts,
      };
    });

    // generated dynamic labels
    data.data.labels = labels;
    // Update summary
    data.data.summary.value = String(
      addCountPrefix(response?.overall_total_count)
    );
    data.shouldShowGraph = response?.data?.length > 0;
    data.originalCount = response?.overall_total_count;
    data.shouldShowGraph =
      response?.overall_total_count && response?.overall_total_count > 0;

    return data;
  }
}
