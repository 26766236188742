import React from 'react';
import {
  NetworkChartIconWrp,
  NetworkChartResults,
  NetworkChartTitle,
  NetworkClusterHeaderWrp,
  NetworkClusterWrp,
  NetworkContentWrp,
  NetworkDiscussionChartWrp,
  NetworkDiscussionContentWrp,
  NetworkDiscussionTitleWrp,
  NetWorkHeaderWrp,
  NetworkImg,
  NetworkMedia,
  NetworkMediaDetailsWrp,
  NetworkMediaHandle,
  NetworkMediaImage,
  NetworkMediaTitle,
  NetworkMediaWrap,
  NetworkProfileDetailsWrp,
  NetworkProfileIconWrp,
  NetworkProfileWrp,
  NetworkResultWrp,
  NetworkSentiment,
  NetworkSentimentChartWrp,
  NetworkSentimentLegends,
  NetworkSentimentTitle,
  NetworkSentimentWrp,
  NetWorkSubDetails,
  NetworkTabs,
  NetworkTabsWrap,
  NetWorkTitle,
  NetWorkTitleIconWrp,
  NetWorkTitleWrp,
  NetworkWidgetComponent,
  WrapIcon,
} from './index.sc';
import ResultsInFigure from '../results-in-figure';
import PropTypes from 'prop-types';
import networkProfile from '../../assets/img/networkProfile.png';
import backgroundImage from '../../assets/img/NetWorkBackground.png';
import PinWidget from '../../assets/icons/PinWidget';
import { VerticleDots } from '../../assets/icons/VerticleDots';
// import { BestBar } from '../../graphs';
// import { theme } from '../../constants/theme';
import GraphLegend from '../graph-legend';
import twitterLogo from '../../assets/img/twitterLogo.png';
import RadialTreeChart from '../../graphs/RadialTree/radialTreeChart';
import HelpIcon from '../../assets/icons/HelpIcon';
import {
  updateNetworkPinData,
  removeNetworkPinData,
} from '../../redux/slices/networkMapDashboardSlice';
import { useDispatch } from 'react-redux';

const NetworkClusterPopup = ({ popupData, widgetComponent = false }) => {
  const dispatch = useDispatch();
  const resultData = [
    {
      change: -75,
      data: 130,
      isIncreased: false,
      previousCount: 525,
      title: 'Total Posts',
    },
    {
      change: -75,
      data: 58302891,
      isIncreased: false,
      previousCount: 525,
      title: 'Total Engagement',
    },
  ];

  const sentimentData = {
    data: [
      {
        label: 'screenrant.com',
        pos: 1,
        neg: 3,
        neu: 6,
      },
    ],
    totalSentiment: 10,
    labels: [
      {
        label: 'Positive',
        value: 1,

        color: '#3FBB62',
      },
      {
        label: 'Negative',
        value: 3,
        color: '#FE757B',
      },
      {
        label: 'Neutral',
        value: 6,
        color: '#BDC3CB',
      },
    ],
  };

  const mediaData = [
    {
      mediaImage: twitterLogo,
      title: '302.5K Followers',
      account: '@thetiarawills',
    },
    {
      mediaImage: twitterLogo,
      title: '302.5K Followers',
      account: '@thetiarawills',
    },
    {
      mediaImage: twitterLogo,
      title: '302.5K Followers',
      account: '@thetiarawills',
    },
  ];

  const sentimentValue = (data) => {
    return (data?.value / sentimentData?.totalSentiment) * 100;
  };

  return (
    <NetworkClusterWrp widgetComponent={widgetComponent}>
      <NetworkWidgetComponent widgetComponent={widgetComponent}>
        <NetworkClusterHeaderWrp
          bgImg={backgroundImage}
          widgetComponent={widgetComponent}
        >
          <NetWorkHeaderWrp>
            <NetworkProfileWrp>
              <NetworkImg src={networkProfile} />
            </NetworkProfileWrp>
            <NetworkProfileDetailsWrp>
              <NetWorkTitleIconWrp>
                <NetWorkTitleWrp>
                  <NetWorkTitle>{popupData?.label}</NetWorkTitle>
                  <NetWorkSubDetails>
                    Designation - Beauty Influencer/Esthetician
                  </NetWorkSubDetails>
                </NetWorkTitleWrp>
                <NetworkProfileIconWrp>
                  {!widgetComponent && (
                    <WrapIcon
                      onClick={() => dispatch(updateNetworkPinData(popupData))}
                    >
                      <PinWidget />
                    </WrapIcon>
                  )}
                  <WrapIcon>
                    <VerticleDots />
                  </WrapIcon>
                </NetworkProfileIconWrp>
              </NetWorkTitleIconWrp>
              <NetWorkTitleWrp>
                <NetWorkSubDetails>Focus/Interests</NetWorkSubDetails>
                <NetworkTabsWrap>
                  {['Beauty', 'Cosmetic and Personal Care']?.map(
                    (interestItem) => (
                      <NetworkTabs key={interestItem}>
                        {interestItem}
                      </NetworkTabs>
                    )
                  )}
                </NetworkTabsWrap>
              </NetWorkTitleWrp>
              <NetWorkTitleWrp>
                <NetWorkSubDetails>Focus/Interests</NetWorkSubDetails>
                <NetworkTabsWrap>
                  {['Beauty', 'Cosmetic and Personal Care']?.map(
                    (interestItem) => (
                      <NetworkTabs key={interestItem}>
                        {interestItem}
                      </NetworkTabs>
                    )
                  )}
                </NetworkTabsWrap>
              </NetWorkTitleWrp>
            </NetworkProfileDetailsWrp>
          </NetWorkHeaderWrp>
        </NetworkClusterHeaderWrp>
        <NetworkResultWrp>
          <ResultsInFigure tileDetails={resultData} selectedMedia={'social'} />
        </NetworkResultWrp>
        <NetworkSentimentWrp>
          {/* <BestBar
          data={sentimentData}
          config={{
            yLabelAlignment: 130,
            yAxisTicksFormat: false,
            yAxisType: 'text',
            xAxisType: 'number',
            xAxisTicksFormat: false,
            wrapLength: 20,
            gridLineXStroke: '#d9dbde',
            gridLineStrokeWidth: 1,
            enableGridXLine: false,
            enableGridYLine: false,
            graphXYLabelFontSize: 11,
            fontFamily: 'Inter',
            fontWeight: 500,
            greenGradientLight: theme?.light?.graphColors?.green40,
            greenGradientDark: theme?.light?.graphColors?.green50,
            redGradientLight: theme?.light?.graphColors?.red40,
            redGradientDark: theme?.light?.graphColors?.red50,
            grayGradientLight: theme?.light?.graphColors?.coolGray30,
            grayGradientDark: theme?.light?.graphColors?.coolGray40,
            greenDropShadowColor: theme?.light?.graphColors?.green50,
            redDropShadowColor: theme?.light?.graphColors?.red50,
            grayDropShadowColor: '#A8ADBD',
            barHeight: 24,
            articleSentiment: true,
            showAllLabels: false,
            hideXAxis: true,
            hideYAxis: true,
            graphTopPadding: 0,
            authorDataLength: 10,
            // ...defaultConfig(topAuthors.customClassName),
          }}
          resetSelection={{}}
          rerender={{}}
          handleOnClick={() => {}}
        /> */}
          <NetworkSentimentTitle>Sentiment</NetworkSentimentTitle>
          <NetworkSentimentChartWrp>
            {sentimentData?.labels?.map((barData, id) => (
              <NetworkSentiment
                key={id}
                barWidth={sentimentValue(barData)}
                color={barData?.color}
              ></NetworkSentiment>
            ))}
          </NetworkSentimentChartWrp>
          <NetworkSentimentLegends>
            <GraphLegend legendData={sentimentData?.labels} />
          </NetworkSentimentLegends>
        </NetworkSentimentWrp>
        <NetworkMediaWrap>
          {mediaData?.map((item, id) => (
            <NetworkMedia key={id}>
              <NetworkMediaImage src={item?.mediaImage} alt={item?.account} />
              <NetworkMediaDetailsWrp>
                <NetworkMediaTitle>{item?.title}</NetworkMediaTitle>
                <NetworkMediaHandle>{item?.account}</NetworkMediaHandle>
              </NetworkMediaDetailsWrp>
            </NetworkMedia>
          ))}
        </NetworkMediaWrap>
        <NetworkContentWrp>
          <NetworkMediaTitle>Biographical Information</NetworkMediaTitle>
          <NetworkMediaHandle>
            Tiara Willis is a renowned New York esthetician and beauty
            influencer, empowering diverse women of color with professional
            skincare and makeup advice. Starting her career at 14, Tiara now
            leads a community of nearly half a million, advocating for diversity
            and economic transparency, and working with top beauty brands and
            media outlets. Tiara mostly shares about Charlotte Tilbury and
            Cerave
          </NetworkMediaHandle>
        </NetworkContentWrp>
      </NetworkWidgetComponent>
      <NetworkWidgetComponent widgetComponent={widgetComponent}>
        <NetworkDiscussionChartWrp widgetComponent={widgetComponent}>
          <NetworkDiscussionContentWrp>
            <NetworkDiscussionTitleWrp>
              <NetworkChartTitle>
                Discussion & Connections
                <span
                  style={{
                    marginLeft: '1rem',
                  }}
                >
                  <HelpIcon />
                </span>
              </NetworkChartTitle>
              <NetworkChartResults>800 Connections</NetworkChartResults>
            </NetworkDiscussionTitleWrp>
            <NetworkChartIconWrp>
              <VerticleDots />
            </NetworkChartIconWrp>
          </NetworkDiscussionContentWrp>
          <RadialTreeChart />
        </NetworkDiscussionChartWrp>
      </NetworkWidgetComponent>
    </NetworkClusterWrp>
  );
};

export default NetworkClusterPopup;

NetworkClusterPopup.propTypes = {
  popupData: PropTypes.object,
  widgetComponent: PropTypes.bool,
};
