import PropTypes, { bool } from 'prop-types';
import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { networkClusterColors } from '../utils/graphConst';

const NetworkCluster = ({ data, handleOnClick, config }) => {
  const svgRef = useRef();

  useEffect(() => {
    const width =
      config?.dashboardType !== 'overview'
        ? window?.innerWidth
        : window.innerWidth - window.innerWidth * 0.3;
    const height = window?.innerHeight - 250;

    const svg = d3
      .select(svgRef.current)
      .attr('width', width)
      .attr('height', height);
    // .style('background', '#f9f9f9')
    const simulation = d3
      .forceSimulation(data?.nodes)
      .force(
        'link',
        d3
          .forceLink(data.links)
          .id((d) => d.id)
          .distance(30) // Reduced link distance for tighter clustering
          .strength(1)
      )
      .force('charge', d3.forceManyBody().strength(-50)) // Weakened repulsion for denser layout
      .force('center', d3.forceCenter(width / 2, height / 2))
      .force(
        'collision',
        d3
          .forceCollide()
          .radius((d) => (data?.drillDownFlag ? d.size + 25 : d.size + 5)) // Reduced padding to make nodes closer
      )
      .force('x', d3.forceX(width / 2).strength(0.5)) // Stronger pull towards center (horizontal)
      .force('y', d3.forceY(height / 2).strength(0.5)); // Stronger pull towards center (vertical)

    // Function to generate curved path between nodes
    function linkPath(d) {
      const dx = d.target.x - d.source.x;
      const dy = d.target.y - d.source.y;
      const dr = Math.sqrt(dx * dx + dy * dy);

      // Calculate control point offset
      const offset = dr * 0.35; // Adjust this value to control the curve amount

      // Calculate control point coordinates
      const midX = (d.source.x + d.target.x) / 2;
      const midY = (d.source.y + d.target.y) / 2;

      // Perpendicular offset for control point
      const nx = (-dy / dr) * offset;
      const ny = (dx / dr) * offset;

      return `M${d.source.x},${d.source.y}
          Q${midX + nx},${midY + ny}
          ${d.target.x},${d.target.y}`;
    }

    // Draw links
    const link = svg
      .append('g')
      .attr('stroke', '#ccc')
      .attr('stroke-width', 0.75)
      .attr('fill', 'none')
      .selectAll('path') // Changed from 'line' to 'path'
      .data(data.links)
      .join('path') // Changed from 'line' to 'path'
      .attr('stroke', (d) => {
        const sourceColor = networkClusterColors[d.source.group % 10];
        const targetColor = networkClusterColors[d.target.group % 10];
        return sourceColor === targetColor
          ? sourceColor
          : networkClusterColors[d.source.group % 10];
      });

    // Draw nodes
    const node = svg
      .append('g')
      .selectAll('circle')
      .data(data.nodes)
      .join('circle')
      .attr('r', (d) =>
        data?.drillDownFlag
          ? d?.orgColor
            ? 12
            : d?.authorColor
            ? 10
            : 8
          : d.id === d.organization
          ? d.size + 5
          : d.size - 5
      ) // Larger size for main nodes, smaller for connected bubbles
      .attr('fill', (d) => {
        return data?.drillDownFlag
          ? d.orgColor || d.authorColor || d.mentionColor
          : networkClusterColors[d.group % 10];
      })
      .attr('stroke', (d) =>
        data?.drillDownFlag
          ? d.orgColor || d.authorColor || d.mentionColor
          : networkClusterColors[d.group % 10]
      )
      .style('cursor', 'pointer')
      .attr('stroke-width', 1.5);
    // .call(
    //   d3
    //     .drag()
    //     .on('start', (event, d) => {
    //       if (!event.active) simulation.alphaTarget(0.3).restart();
    //       d.fx = d.x;
    //       d.fy = d.y;
    //     })
    //     .on('drag', (event, d) => {
    //       d.fx = event.x;
    //       d.fy = event.y;
    //     })
    //     .on('end', (event, d) => {
    //       if (!event.active) simulation.alphaTarget(0);
    //       d.fx = null;
    //       d.fy = null;
    //     })
    // );

    const textNode =
      data?.drillDownFlag &&
      svg
        .append('g')
        .selectAll('text')
        .data(data.nodes)
        .join('text')
        .attr('class', 'label')
        .attr('text-anchor', 'middle')
        .attr('font-size', 11)
        .attr('fill', 'black')
        .text((d) => d?.label);

    // Highlight clusters on click
    node.on('click', function (event, d) {
      event.stopPropagation(); // Prevent other event listeners from interfering
      config?.dashboardType !== 'overview' &&
        handleOnClick({ ...d, networkPopupFlag: data?.drillDownFlag });

      const clickedGroup = d.group;
      node.style('opacity', (nodeData) =>
        nodeData.group === clickedGroup ? 1 : 0.2
      );
    });
    node.on('mouseover', function (event, d) {
      const clickedGroup = d.group;
      node.style('opacity', (nodeData) =>
        nodeData.group === clickedGroup ? 1 : 0.2
      );
      config.handleMouseEnter(event, d, true);
    });
    node.on('mouseout', function (event, d) {
      //
      config.handleMouseLeave(event, d, true);
      const clickedGroup = d.group;

      node.style('opacity', (nodeData) =>
        nodeData.group !== clickedGroup ? 1 : 1
      );
    });

    simulation.on('tick', () => {
      // link
      //   .attr('x1', (d) => Math.max(0, Math.min(width, d?.source?.x)))
      //   .attr('y1', (d) => Math.max(0, Math.min(height, d?.source?.y)))
      //   .attr('x2', (d) => Math.max(0, Math.min(width, d?.target?.x)))
      //   .attr('y2', (d) => Math.max(0, Math.min(height, d?.target?.y)));
      link.attr('d', linkPath); // Use path generator function
      node
        .attr(
          'cx',
          (d) => (d.x = Math.max(d?.size, Math.min(width - d?.size, d?.x)))
        )
        .attr(
          'cy',
          (d) => (d.y = Math.max(d?.size, Math.min(height - d?.size, d?.y)))
        );
      data?.drillDownFlag &&
        textNode
          .attr(
            'x',
            (d) => (d.x = Math.max(d.size, Math.min(width - d.size, d.x)))
          )
          .attr(
            'y',
            (d) => (d.y = Math.max(d.size, Math.min(height - d.size, d.y)) + 24)
          );
    });

    // Cleanup
    return () => {
      simulation.stop(); // Cleanup simulation
      svg.selectAll('*').remove();
    };
  }, [data]);

  return <svg ref={svgRef}></svg>;
};

NetworkCluster.propTypes = {
  data: PropTypes.shape({
    nodes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        group: PropTypes.number.isRequired,
        size: PropTypes.number.isRequired,
      })
    ).isRequired,
    links: PropTypes.arrayOf(
      PropTypes.shape({
        source: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
          .isRequired,
        target: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
          .isRequired,
      })
    ).isRequired,
    drillDownFlag: bool,
  }).isRequired,
  handleOnClick: PropTypes.func,
  config: PropTypes.object,
};

export default NetworkCluster;
